import React from "react";
import "./css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";
import ReactDOM from "react-dom";
import {ChakraProvider} from "@chakra-ui/react";
import {Fonts, zordiTheme} from "./ZordiChakraTheme";

import {store} from './utils/store';
import {Provider, } from 'react-redux'; 

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <ChakraProvider theme={zordiTheme}>
                    <Fonts/>
                    <App/>
                </ChakraProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
