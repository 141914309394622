import pages from '../lib/labels/pages';
import cities from '../lib/labels/cities';

const {SITE_OVERVIEW, SYSTEM_VS_GROWER, REMOTE_VS_INPERSON, LABOR_REPORT} = pages;
const {OAKFIELD} = cities;

export const findCityById = (id: string) => {
    switch (id) {
        case 'oak1':
            return OAKFIELD
        default:
            return OAKFIELD
    }
}

export const findReportPage = (page: string) => {
    if (!page) {
        return SITE_OVERVIEW
    }
    switch (page) {
        case SYSTEM_VS_GROWER.page:
            return SYSTEM_VS_GROWER
        case REMOTE_VS_INPERSON.page:
            return REMOTE_VS_INPERSON
        case LABOR_REPORT.page:
            return LABOR_REPORT
        default:
            return SYSTEM_VS_GROWER
    }
}