import {Button, Spinner} from "@chakra-ui/react";
import React from "react";

export const ZordiButtonSpinner = () => {
    return (
        <Button variant='zordi-basic'
                disabled={true}
        ><Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='sm'
        />
        </Button>)
}
