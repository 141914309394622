import moment from "moment";

export const dateToSnapshot = (date: Date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString()
        .padStart(2, "0")}`
}

/**
 * returns date object from a string in local time
 * For example, if the browser is opened from EST with date=2022-09-05,
 * return date object with 2022-09-05 03:00:00 if offset is 3 hours.
 */
export const localDateFromSnapshot = (date: string) => {
    const localDate = moment(date)
    return localDate.toDate()
}

export const sortDescending = (dates: string[]) => {
    return dates.sort((a, b) => {
        const aD = new Date(a);
        const bD = new Date(b);
        return +bD - +aD;
    });
}

export const isDateInRange = (startDate: Date | null, endDate: Date | null, targetDate: Date) => {
    const s = startDate === null ? null : dateToSnapshot(startDate)
    const e = endDate === null ? null : dateToSnapshot(endDate)
    const t = targetDate === null ? null : dateToSnapshot(targetDate)

    if (t == null) {
        return false
    }

    if (!!s && !!e) {
        return (s <= t && t <= e)
    } else if (!!s) {
        return (s <= t)
    } else if (!!e) {
        return (t <= e)
    } else {
        return true
    }
}