export const setUrlParam = (paramName: string, paramValue: string,
                            searchParams: URLSearchParams,
                            setSearchParams: (params: string) => void) => {
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set(paramName, paramValue);
    setSearchParams(updatedSearchParams.toString());
}

// 1 -> 1R
// 4 -> 2L
// 5 -> 3R
export const fromRawRowToDisplayRow = (rawRow: number): string => {
    const row: string = (Math.ceil(rawRow / 2)).toString()
    const side: string = rawRow % 2 === 1 ? "R" : "L"
    return row + side
}

// 1R -> 1
// 2L -> 4
// 3R -> 5
export const fromDisplayRowToRawRow = (displayRow: string): number | null => {
    if (displayRow == null) {
        return null
    }

    const row = parseInt(displayRow.slice(0, -1))

    if (isNaN(row)) {
        return null
    }

    const side = displayRow.slice(-1)

    const sideOffset = (side === "R") ? 0 : 1
    return row * 2 + sideOffset - 1
}
