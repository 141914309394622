import {Datum, ResponsiveLine, Serie} from "@nivo/line";
import React, {useEffect, useState} from "react";
import {Spinner} from "@chakra-ui/react";
import {Metrics, MetricsByValue} from "../../../lib/labels/SensorMetrics";

const {PPFD} = Metrics

type ZordiLineChartProps = {
    timeseriesData: Serie[] | null,
}

export const ZordiLineChart = ({timeseriesData}: ZordiLineChartProps) => {
    const [minMax, setMinMax] = useState<number[]>([-10, 100])

    useEffect(() => {
        let min = -10
        let max = 0
        timeseriesData?.forEach(data => {
            data.data.forEach((xy) => {
                let y = !!xy.y ? parseFloat(xy.y as string) : 0
                if (y > max) {
                    max = y
                } else if (y < min) {
                    min = y
                }
            })
        })
        max = (Math.round((max / 10) + 1)) * 10

        setMinMax([min, max])
    }, [timeseriesData])

    if (!timeseriesData) {
        return (<Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
        />)
    }

    const tickValues = (series: Serie[]) => {
        let dataList: Datum[] = []

        for (const serie of series) {
            if (serie.data.length > dataList.length) {
                dataList = serie.data
            }
        }

        let c = 4;
        if (dataList.length > 49 && dataList.length <= 73) {
            c = 6
        }
        if (dataList.length > 73) {
            c = 14
        }
        const xAxis: string[] = []
        for (const [i, data] of dataList.entries()) {
            if (i % c === 0 && !!data.x) {
                xAxis.push(data.x.toString())
            }
        }
        return xAxis
    }

    return (
        <div
            style={{
                minWidth: 0,
                width: "100%",
                height: "500px",
            }}
        >
            <ResponsiveLine
                margin={{top: 60, right: 60, bottom: 60, left: 60}}
                colors={{datum: "color"}}
                enablePoints={false}
                enableSlices="x"

                data={timeseriesData}
                yScale={{
                    type: "linear",
                    min: minMax[0],
                    max: minMax[1],
                    reverse: false,
                }}
                axisLeft={{
                    tickValues: 10,
                    legendOffset: 12,
                }}
                axisBottom={{
                    legendOffset: 12,
                    tickRotation: -45,
                    tickValues: tickValues(timeseriesData)
                }}

                enablePointLabel={false}
                pointSize={5}
                pointBorderWidth={1}
                pointBorderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.3]],
                }}
                pointLabelYOffset={-12}
                useMesh={true}

                legends={[
                    {
                        anchor: 'top-left',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -25,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 128,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}

                sliceTooltip={({slice}) => {
                    return (
                        <div
                            style={{
                                background: '#FFFFFFD9',
                                padding: '9px 12px',
                                border: '1px solid #ccc',
                            }}
                        >
                            <div style={{color: "#696463"}}><strong>{`x: ${slice.points[0].data.x}`}</strong></div>
                            {slice.points.map(point => {
                                const [label, id] = point.serieId.toString().split("-")
                                const metric = MetricsByValue(label)[0]
                                let data = parseFloat(point.data.yFormatted.toString())
                                if (label === PPFD.label) {
                                    data = data * 10
                                }
                                let bay = ""

                                switch (id) {
                                    case "889532":
                                        bay = "2"
                                        break
                                    case "889541":
                                        bay = "5"
                                        break
                                    case "890233":
                                        bay = "2"
                                        break
                                    case "890225":
                                        bay = "5"
                                        break
                                    case "886484":
                                        bay = "5"
                                        break
                                    case "919143":
                                        bay = "2"
                                        break
                                }

                                return (
                                    <div
                                        key={point.id}
                                        style={{
                                            color: point.serieColor,
                                            padding: '3px 0',
                                        }}
                                    >
                                        <strong>{label}-bay {bay} {id}</strong> [{data.toFixed(2)} {metric.unit}]
                                    </div>
                                )

                            })}
                        </div>
                    )
                }}
            />
        </div>
    )
}
