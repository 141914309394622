import React, {useState} from "react";
import {AdminProvider, useAdminContext} from "../../contexts/AdminProvider";
import {Button, Flex, Spinner, Table, Tbody, Td, Thead, Tr, useColorModeValue, VStack} from "@chakra-ui/react";
import {Title} from "../typography/title";

export const AdminActions = ({snapshotDate, siteId}: { snapshotDate: string, siteId: string }) => {
    return (
        <AdminProvider>
            <AdminSection snapshotDate={snapshotDate} siteId={siteId}></AdminSection>
        </AdminProvider>
    )
}

const AdminSection = ({snapshotDate, siteId}: { snapshotDate: string, siteId: string }) => {
    const adminContext = useAdminContext();
    const [loadingButton, setLoadingButton] = useState<string | undefined>(undefined);

    const callAdminFunction = (buttonName: string, adminFunction: () => Promise<any>) => {
        setLoadingButton(buttonName)
        adminFunction().then(() => {
            setLoadingButton(undefined)
        }).catch(error => {
            alert(`Failing to generate work order: ${JSON.stringify(error)}`)
        })
    }

    const buttons = [
        {
            name: "generateWorkOrders",
            title: "Generate Work Orders",
            description: "Overwrites existing work orders",
            adminFunction: () => {
                return adminContext.createWorkOrderProcessing(snapshotDate, siteId)
            }
        }
    ]


    return (
        <Flex
            w='full'
            py={5}
            bg={useColorModeValue("white", "gray.900")}
        >
            <VStack w='full' ms={0}>
                <Title text={"Admin Actions"}></Title>
                <Table>
                    <Thead>
                        <Tr>
                            <Td>
                                Action
                            </Td>
                            <Td>
                                Description
                            </Td>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {
                            buttons.map(button => (<Tr key={button.name}>
                                    <Td> {button.title}</Td>
                                    <Td> {button.description}</Td>
                                    <Td>
                                        {
                                            loadingButton === button.name ?
                                                (<Button variant='zordi-basic'
                                                         disabled={true}
                                                ><Spinner
                                                    thickness='4px'
                                                    speed='0.65s'
                                                    emptyColor='gray.200'
                                                    color='blue.500'
                                                    size='sm'
                                                /></Button>) :
                                                (<Button
                                                    disabled={loadingButton !== undefined}
                                                    variant='zordi-basic' onClick={() => {
                                                    return callAdminFunction(button.name, button.adminFunction)
                                                }}>Run</Button>)
                                        }
                                    </Td>
                                </Tr>)
                            )
                        }

                    </Tbody>
                </Table>

            </VStack>
        </Flex>
    )
}
