import React from "react";

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    Divider,
    Flex,
} from "@chakra-ui/react";

type AlertProps = {
    cancelRef: React.RefObject<any>
    isOpen: boolean
    onClose: () => void
    onCloseAll?: () => void
    header?: string
    body?: string | React.ReactNode | null
    status?: any
}

export const Alert = ({cancelRef, isOpen, onClose, onCloseAll, status, header, body}: AlertProps) => {
    return (
        <div className={"alert"}>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {header}
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            {status ? `Status: ${status}` : null}
                            <Divider/>
                            {body}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Flex gap={"10px"}>
                                {onCloseAll ? (<Button variant={"outline"} ref={cancelRef} onClick={onClose}>
                                    Back
                                </Button>) : null}
                                <Button variant={"outline"} colorScheme={"green"} ref={cancelRef}
                                        onClick={onCloseAll ? onCloseAll : onClose}>
                                    Confirm
                                </Button>
                            </Flex>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </div>

    )
}