import React, {Dispatch, ReactNode, SetStateAction} from "react";
import {useDispatch} from "react-redux"
import {
    Box,
    BoxProps,
    Center,
    Flex,
    FlexProps,
    Icon,
    Image,
    Link,
    useColorModeValue,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import {FiCalendar, FiGrid, FiRepeat} from "react-icons/fi";
import {GiStrawberry} from "react-icons/gi";
import {GrUserWorker} from "react-icons/gr";
import {BsPencilSquare} from "react-icons/bs";
import {IconType} from "react-icons";

import {updateCity, updatePage} from "../redux/pageSlice";
import cities from "../lib/labels/cities";
import {PageData} from "../lib/datatypes/PageData";
import {useNavigate} from 'react-router-dom';


interface LinkItemProps {
    name: string;
    icon: IconType;
    onclick?: any;
}

export default function Sidebar({
                                    header,
                                    children,
                                    selectedIcon,
                                    setSidebar,
                                }: {
    header?: ReactNode;
    children: ReactNode;
    selectedIcon: string;
    setSidebar: Dispatch<SetStateAction<boolean>>;
}) {
    const {onOpen, onClose} = useDisclosure();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClick = (page: PageData) => {
        dispatch(updateCity(cities.OAKFIELD))
        dispatch(updatePage(page))
    }

    const LinkItems: Array<LinkItemProps> = [
        {
            name: "Overview", icon: FiGrid,
            onclick: () => {
                navigate("/site_overview/oak1");
                handleClick({
                    pageType: "site_overview",
                    pageTitle: "Site Overview",
                    menuLabel: "Site Overview",
                })
            }
        },
        {
            name: "Work Order Overview", icon: FiCalendar,
            onclick: () => {
                navigate("/work_order_overview");
                handleClick({
                    pageType: "work_order_overview",
                    pageTitle: "Work Order Overview",
                    menuLabel: "Work Order Overview",
                })
            }
        },
        {
            name: "New Work Order", icon: BsPencilSquare,
            onclick: () => {
                setSidebar(true)
            }
        },
        {
            name: "Work Order Routines", icon: FiRepeat,
            onclick: () => {
                navigate("/work_order_routines")
                handleClick({
                    pageType: "work_order_routines",
                    pageTitle: "Work Order Routines",
                    menuLabel: "Work Order Routines",
                })
            }
        },
        {
            name: "Labor Tracking", icon: GrUserWorker,
            onclick: () => {
                navigate("/labor-log")
                handleClick({
                    pageType: "labor-log",
                    pageTitle: "Labor Tracking",
                    menuLabel: "Labor Tracking",
                })
            }
        },
        {name: "Inventory", icon: GiStrawberry},
    ];

    return (
        <Flex width="100%" bg={"white"}>
            <VStack width="120px">
                <SidebarContent
                    selectedIcon={selectedIcon}
                    onClose={() => onClose}
                    display={{base: "none", md: "block"}}
                    LinkItems={LinkItems}
                />
            </VStack>
            <VStack width="100%">
                <MobileNav
                    onOpen={onOpen}
                    header={header}
                />
                {children}
            </VStack>
        </Flex>
    );
}

interface SidebarProps extends BoxProps {
    selectedIcon: string;
    onClose: () => void;
    LinkItems: LinkItemProps[]
}

const SidebarContent = ({onClose, selectedIcon, LinkItems, ...rest}: SidebarProps) => {
    return (
        <Box
            bg="white"
            shadow="lg"
            pos="fixed"
            h="calc(100vh)"
            {...rest}
        >
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Link href={'/'}>
                    <Image src="/zordi_logo.png" width={72}/>
                </Link>
                {/*<CloseButton display={{base: "flex", md: "none"}} onClick={onClose}/>*/}
            </Flex>
            {LinkItems.map((link) => (
                <NavItem key={link.name} icon={link.icon}
                         iconSelected={selectedIcon === link.name}
                         onclick={link.onclick}>
                </NavItem>
            ))}
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    iconSelected: boolean;
    icon: IconType;
    onclick?: any;
}

const NavItem = ({icon, children, iconSelected, onclick, ...rest}: NavItemProps) => {
    return (
        <Center onClick={onclick !== undefined ? onclick : () => {
        }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: "darkGray",
                    color: "white",
                }}
                {...rest}
            >
                {icon && (
                    <Icon
                        margin={4}
                        fontSize="24"
                        opacity={iconSelected ? 1 : 0.2}
                        color={"black"}
                        _groupHover={{
                            color: "black",
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Center>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
    header: ReactNode;
}

const MobileNav = ({onOpen, header, ...rest}: MobileProps) => {
    return (
        <Flex
            width={"100%"}
            p={0}
            height="20"
            alignItems="center"
            bg={useColorModeValue("white", "gray.900")}
            justify={{base: "space-between", md: "flex-end"}}
            {...rest}
        >

            {header}
        </Flex>
    );
};
