import React from "react"; // we need this to make JSX compile
import firebase from "firebase/compat/app";
import "firebase/compat/auth";


type SalesPageProps = {
    auth: firebase.auth.Auth;
};

export const SalesPage = ({auth}: SalesPageProps) => {
    if (auth.currentUser == null) {
        return <></>;
    }


    return (
        <><h1>Sales Placeholder</h1></>
    );
};