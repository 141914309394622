import {Category} from "@zordi/zordi_object_schema";

const {
    IPM,
    CROP_MONITORING,
    SANITATION,
    GREENHOUSE_CONTROL,
    PRUNING_AND_SHAPING,
    HARVESTING,
    REVIEWER_TASKS,
    HEAD_GROWER_TASKS
} = Category

const mapToOptions = (options: string[]) => {
    return options.map(category => ({
        label: category,
        value: category
    }))
}

export const categoryOptions = mapToOptions(
    [IPM, CROP_MONITORING, SANITATION, GREENHOUSE_CONTROL, PRUNING_AND_SHAPING, HARVESTING, REVIEWER_TASKS,
        HEAD_GROWER_TASKS])

export const problemsOptions = {
    [IPM]: mapToOptions(["Powdery Mildew", "Spider Mites", "Fruit Flies", "Multiple Pests: X", "Routine"]),
    [CROP_MONITORING]: mapToOptions(
        ["Some plant yellowing", "General check", "Acute plant health issue: X", "Routine"]),
    [SANITATION]: mapToOptions(["Work area is dirty/disorganized", "Standing water", "Routine"]),
    [GREENHOUSE_CONTROL]: mapToOptions(["Plant DLI is too low", "Nutrient mixture is low", "Unpollinated flowers",
        "Pest X not responding to typical treatments (unhealthy plants)", "Lighting schedule needs update (for team)",
        "Irrigation line cut", "Improper settings/update to default set points", "Cold night time temperatures",
        "Routine"]),
    [PRUNING_AND_SHAPING]: mapToOptions(
        ["Plants throwing unwanted runners", "Plants throwing unwanted flowers", "Plants have too many trifoliates",
            "Plants are not growing upwards", "Brown/senescing leaves", "Weeds", "First flush, first flower",
            "Routine"]),
    [HARVESTING]: mapToOptions(["Routine"]),
    [REVIEWER_TASKS]: mapToOptions(["Routine"]),
    [HEAD_GROWER_TASKS]: mapToOptions(["Routine"]),
}

export const dataUsedOptions = mapToOptions(
    ["Remote Data Only", "In Person Data Used", "Automated recommendation", "N/A",])

export const priorityOptions = mapToOptions(["P1", "P2", "P3"])

export const regionOptions = mapToOptions(
    ["Remote Experiment", "In Person Experiment", "All Experimental Sections", "Hon 1", "Hon 2", "Hon 3", "Hon 6",
        "Toc 1", "Toc 2", "Toc 3", "Toc 4", "Monitoring Robot Operations", "Not Applicable"])

export const assigneeOptions = mapToOptions(
    ["Christian", "Danyang", "Sarah Ann", "Casey", "Henry", "My T Acres", "All Team Members"])

export const actionOptions = {
    [IPM]: mapToOptions(["Release beneficials (type: X)", "replace sticky traps", "Spray pesticide: X",
        "Remove diseased/infested leaves, but leave at least: X trifoliates", "Spray pesticide: X (spot spray)",
        "Dose tank with: X", "Spray fungicide: X"]),
    [CROP_MONITORING]: mapToOptions(["Check the Brix level of the fruits",
        "Disease inspect indicator bags - crown pictures, underside of mature leaves",
        "Leachate sample, send to the lab", "Leaf tissue analysis", "Substrate sample, send to fungal ID",
        "Report irrigation EC", "Report irrigation PH", "Report irrigation rate from previous day",
        "Run monitoring robot", "Sample irrigation feed water"]),
    [SANITATION]: mapToOptions(
        ["Sweep the floor", "Organize area and remove trash", "Clean area", "Clean standing water"]),
    [GREENHOUSE_CONTROL]: mapToOptions(
        ["Optimize the heater threshold temperature", "Optimize the high pressure fogging threshold temperature",
            "Optimize the irrigation frequency or EC", "Sensor calibration", "Start/stop night-interrupting lights",
            "Start/stop supplemental lights", "Use colder water feed to control the peak temperature",
            "Increase light intensity to: X%", "Mix additional nutrients", "Pollinate plants", "Increase airflow",
            "Change irrigation schedule to X", "Change lighting schedule to X", "Close blackout curtains overnight",
            "Check RO System status", "Flush irrigation system", "Mix fertilizer: X recipe"]),
    [PRUNING_AND_SHAPING]: mapToOptions(["For mature plants, keep it under: X fruits per plant, make it flat",
        "Prune all dead/browning leaves, but leave at least: X trifoliates", "Prune all flowers",
        "Prune all flowers but the largest: X per cluster", "Prune leaves under the band", "Prune runners"]),
    [HARVESTING]: mapToOptions(["Harvest"]),
    [REVIEWER_TASKS]: mapToOptions(["[Experiment] Daily Logging (Concerns)", "[Experiment] Weekly Flower Counts",
        "[Experiment] Concern Audit Logging", "[Experiment] Work Order Accuracy Review",
        "[Experiment] Concern Accuracy Review", "[Experiment] Weekly Logging (Disease + Pest Counts)"]),
    [HEAD_GROWER_TASKS]: mapToOptions(
        ["[Head Grower] Scouting (Remote)", "[Head Grower] Scouting (In Person)", "[Head Grower] Task Verification",
            "[Head Grower] Work Order Generation", "[Head Grower] Yield Metrics Logging",
            "[Head Grower - Experiment] Concern Audit Logging (Remote)",
            "[Head Grower - Experiment] Concern Audit Logging (In Person)",
            "[Head Grower - Experiment] Work Order Accuracy Review",
            "[Head Grower - Experiment] Concern Accuracy Review]"]),
}

export const verificationMethodOptions = mapToOptions(
    ["Observe during daily review", "None specified", "Remote sensor", "Direct observation"])

export type EntryCategory = {
    label: string
    inputType: string
    options?: string[]
    required: boolean
}

export const FormEntries = {
    CATEGORY: {
        label: "Category",
        options: categoryOptions,
        inputType: "select",
        required: true
    },
    PROBLEM: {
        label: "Problem",
        options: problemsOptions,
        inputType: "select",
        required: true
    },
    PROBLEM_PARAMS: {
        label: "Problem Params",
        inputType: "input",
        required: false
    },
    DATA_USED: {
        label: "Data Used",
        options: dataUsedOptions,
        inputType: "select",
        required: true
    },
    PRIORITY: {
        label: "Priority",
        options: priorityOptions,
        inputType: "select",
        required: true
    },
    REGION: {
        label: "Application Region",
        options: regionOptions,
        inputType: "select",
        required: true
    },
    ASSIGNEE: {
        label: "Assignee",
        options: assigneeOptions,
        inputType: "select",
        required: true
    },
    ACTION: {
        label: "Action",
        options: actionOptions,
        inputType: "select",
        required: true
    },
    ACTION_PARAMS: {
        label: "Action Params",
        inputType: "input",
        required: false
    },
    VERIFICATION_METHOD: {
        label: "Verification Method",
        options: verificationMethodOptions,
        inputType: "select",
        required: true
    },
    NOTES: {
        label: "Notes",
        inputType: "textarea",
        required: false
    }
}
