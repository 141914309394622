import React, {useEffect, useState} from "react"; // we need this to make JSX compile
import "firebase/compat/auth";
import {Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import '../../css/labor_report.css';
import {useSelector} from "react-redux";
import {useOrderContext} from "../../contexts/OrderProvider";
import {RootState} from "../../utils/store";
import {WorkOrder} from "@zordi/zordi_object_schema";
import {dateToSnapshot} from '../../utils/date';
import {sortWorkOrderHelper, TableData} from '../../utils/labor_report';
import {LaborReportData, WorkOrderCount, workOrderCountToRate} from "../../lib/labels/LaborReport";

export type SortedWorkOrders = {
    [date: string]: {
        [category: string]: {
            hours: number,
            workOrderCount: WorkOrderCount
        },
        total: {
            hours: number,
            workOrderCount: WorkOrderCount
        }
    }
};

export const LaborReport = () => {
    const orderContext = useOrderContext();
    const siteId = useSelector((state: RootState) => state.page.siteId);
    const [workOrderMapByDate, setWorkOrderMapByDate] = useState<SortedWorkOrders>({});
    const [workDates, setWorkDates] = useState<string[]>([]);
    const todayFullDate = new Date();
    const today = dateToSnapshot(todayFullDate);
    const [tableData, setTableData] = useState<any | null>(null);

    const sortWorkOrders = (workOrders: WorkOrder[]) => {
        const sorted = sortWorkOrderHelper(workOrders, today);
        setWorkOrderMapByDate(sorted.tempWorkMap);
        setWorkDates(sorted.workDates);
        setTableData(sorted.tempTableData);
    }

    useEffect(() => {
        if (siteId) {
            orderContext.getAllWorkOrders({
                siteId: siteId.toUpperCase()
            }).then((workOrders) => {
                sortWorkOrders(workOrders.growerWorkOrders);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderContext, siteId]);

    const workCompletedComponent = (workOrderCount: WorkOrderCount) => {
        return (<>{workOrderCountToRate(workOrderCount)}</>)
    }


    const headRow = () => {
        const latestDate = workDates[0] === today ? workDates[1] : workDates[0];
        return (
            <>
                <Tr>
                    <th rowSpan={2} className="align-center chakra-th">Category</th>
                    <th colSpan={4} className="align-center chakra-th">Labor Hours</th>
                    <th rowSpan={2} className="align-center chakra-th">Target<br/> Work Hours <br/><br/>hrs/wk/bay</th>
                    <th rowSpan={2} className="align-center chakra-th">Actual Hours<br/><br/>hrs/wk/bay</th>
                </Tr>
                <Tr>
                    <Th className="align-center">Today -<br/> {today}</Th>
                    <Th className="align-center">Last Working Day-<br/> {latestDate}</Th>
                    <Th className="align-center">Daily <br/>Average</Th>
                    <Th className="align-center">This Week's <br/>Total Hours</Th>
                </Tr>
            </>
        )
    }

    const bodyRow = (tableData: { tableData: TableData, total: { [totalType: string]: any } }) => {
        const data = tableData.tableData;
        const total = tableData.total

        return Object.keys(data).map((key, idx) => {
            const {
                latestWork,
                latestWorkCompleted,
                todaysWork,
                todaysWorkCompleted,
                averageHours,
                historicalWorkCompleted,
                thisWeekTotal,
                thisWeekWorkCompleted,
                targetHours,
                checkTargetMet,
                unit,
                actualAverage
            } = data[key];
            let exclude = false;
            let crop = false;
            if (key === LaborReportData.PRUNING_AND_SHAPING.workType
                || key === LaborReportData.IPM.workType
                || key === LaborReportData.GREENHOUSE_CONTROL.workType) {
                exclude = true;
            }
            if (key === LaborReportData.CROP_MONITORING.workType) {
                crop = true;
                exclude = true;
            }

            return (
                <Tr key={`row-${idx}-${key}`}>
                    <Th>
                        <Text>{key}</Text>
                    </Th>
                    <Td className="align-center">
                        {todaysWork.toFixed(2)} {todaysWorkCompleted}

                    </Td>
                    <Td className="align-center">
                        {latestWork.toFixed(2)} {latestWorkCompleted}
                    </Td>
                    <Td className="align-center">
                        {averageHours.toFixed(2)} {historicalWorkCompleted}
                    </Td>
                    <Td className="align-center">
                        {thisWeekTotal.toFixed(2)} {thisWeekWorkCompleted}
                    </Td>
                    {crop ? (
                        <td className={`chakra-td align-center`} rowSpan={4}>
                            {targetHours} &nbsp; {unit}
                        </td>
                    ) : null}
                    {exclude ? null : (<Td className="align-center">
                        {targetHours} &nbsp; {targetHours !== "N/A" ? unit : null}
                    </Td>)}
                    {crop ? (
                        <td className={`chakra-td align-center ${total.combinedTasksTotal > targetHours ? 'red' : 'green'}`}
                            rowSpan={4}>
                            {total.combinedTasksTotal.toFixed(2)}
                            {workOrderCountToRate(total.combinedWorkCount)}
                        </td>
                    ) : null}
                    {exclude ? null : (
                        <Td className="align-center"><span className={`${checkTargetMet}`}>
                            {actualAverage.toFixed(2)}
                            {historicalWorkCompleted}
                        </span></Td>)}
                </Tr>
            )
        });
    }

    const totalRow = (total: {
        dailyTotal: number, weeklyTotal: number, actualWeekTotal: number,
        historicalWorkCount: WorkOrderCount,
        thisWeekWorkCount: WorkOrderCount
    }) => {
        const latestDate = workDates[0] === today ? workDates[1] : workDates[0];

        return (
            <Tr>
                <Th>
                    <Text>Total</Text>
                </Th>
                <Td className="align-center">
                    {workOrderMapByDate[today].total.hours.toFixed(2)}
                    {workCompletedComponent(workOrderMapByDate[today].total.workOrderCount)}
                </Td>
                <Td className="align-center">
                    {workOrderMapByDate[latestDate].total.hours.toFixed(2)}
                    {workCompletedComponent(workOrderMapByDate[latestDate].total.workOrderCount)}
                </Td>
                <Td className="align-center">
                    {total.dailyTotal.toFixed(2)}
                    {workCompletedComponent(total.historicalWorkCount)}
                </Td>
                <Td className="align-center">
                    {total.weeklyTotal.toFixed(2)}
                    {workCompletedComponent(total.thisWeekWorkCount)}
                </Td>
                <Td>
                </Td>
                <Td className="align-center">
                    {total.actualWeekTotal.toFixed(2)}
                    {workCompletedComponent(total.historicalWorkCount)}
                </Td>
            </Tr>
        )
    }

    return (
        <>
            <TableContainer className="labor-report-container report-table">
                <Table variant={"striped"} className="report-table">
                    <Thead>
                        {headRow()}
                    </Thead>
                    <Tbody>
                        {!!tableData ? bodyRow(tableData) : null}
                        {!!tableData ? totalRow(tableData.total) : null}
                    </Tbody>
                </Table>

            </TableContainer>
        </>
    );
}
