import React, {useEffect, useState} from "react";

import {
    Button,
    Flex,
    HStack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    VStack,
} from '@chakra-ui/react'
import {Column, useSortBy, useTable} from 'react-table'
import {Order} from "../../types/Order";
import {OrderProvider, useOrderContext} from "../../contexts/OrderProvider";
import EditOrderModal from "./EditOrderModal";
import {OrderQuery} from "../../types/OrderQuery";

export const PlanningTable = () => {
    const {isOpen, onOpen, onClose} = useDisclosure()

    // Planning Table always deal with today's orders
    const startMidnight: Date = new Date();
    startMidnight.setHours(0, 0, 0, 0);
    const tomorrow: Date = new Date(startMidnight)
    tomorrow.setDate(startMidnight.getDate() + 1)

    const orderQuery: OrderQuery = {
        startTime: startMidnight,
        endTime: tomorrow
    }

    const onSave = (newOrder: any) => {
        console.log("save newOrder")
        console.log(newOrder)
    }

    return (
        <>
            <Flex
                w='full'
                bg={useColorModeValue("white", "gray.900")}
            >
                <VStack w='full' ms={0}>
                    <HStack w={'full'} justify={"space-between"} shouldWrapChildren={true}>

                        <Text w='full' align="left" fontSize='xl'
                              fontWeight={700}
                              fontFamily={'Open Sans, sans-serif'}>
                            {"Orders to be distributed"}</Text>
                        <Button variant="ghost" onClick={onOpen}>
                            + Create a new order
                        </Button>
                    </HStack>
                    <OrderProvider>
                        <OrderTableWithData orderQuery={orderQuery}/>
                    </OrderProvider>
                </VStack>
            </Flex>
            <EditOrderModal isOpen={isOpen} onClose={onClose} onSave={onSave}></EditOrderModal>
        </>
    )
}

type OrderTableProps = {
    orderQuery: OrderQuery
}
const OrderTableWithData = ({orderQuery}: OrderTableProps) => {
    const orderContext = useOrderContext();
    const [data, setData] = useState<Order[]>([]);

    useEffect(() => {
        orderContext.getOrders(orderQuery).then((orders) => setData(orders))
    }, [orderContext, orderQuery]);

    const columns = React.useMemo(
        (): Column<Order>[] => [
            {
                Header: 'Type',
                id: 'orderType',
                accessor: (row) => row.orderType.name,
            },
            {
                Header: 'Priority',
                id: 'priority',
                accessor: (row) => row.priority,
            },
            {
                Header: 'Due Date',
                id: 'dueDate',
                accessor: 'dueDate',
            },
            {
                Header: 'Assigned to',
                id: 'assignedTo',
                accessor: 'assignedTo',
            },
            {
                Header: "",
                id: 'Duplicate',
                Cell: (props: any) =>
                    <Button variant="ghost" onClick={() => console.log(props)}>
                        Duplicate
                    </Button>
            },
            {
                Header: "",
                id: 'Edit',
                Cell: (props: any) =>
                    <Button variant="ghost" onClick={() => console.log(props)}>
                        Edit
                    </Button>
            },
            {
                Header: "",
                id: 'Delete',
                Cell: (props: any) =>
                    <Button variant="ghost" onClick={() => console.log(props)}>
                        Delete
                    </Button>
            }

        ],
        [],
    )

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
        useTable({columns, data}, useSortBy)


    return (
        <Table bgColor={"#EDEDED"} borderRadius={"12px"} {...getTableProps()}>
            <Thead>
                {headerGroups.map((headerGroup) => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <Th key={column.id}>
                                {column.render('Header')}
                            </Th>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row)
                    return (
                        <Tr borderTop={"1pt solid #C4C4C4"} {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                                <Td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </Td>
                            ))}
                        </Tr>
                    )
                })}
            </Tbody>
        </Table>
    )
}

export default PlanningTable;