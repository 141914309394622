import React from "react"; // we need this to make JSX compile
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import PlanningTable from "../common/planning/PlanningTable";


type PlanningPageProps = {
    auth: firebase.auth.Auth;
};

export const PlanningPage = ({auth}: PlanningPageProps) => {
    if (auth.currentUser == null) {
        return <></>;
    }

    const distributed = () => {
        return (
            <h1>Distributed Order</h1>
        )
    }


    return (
        <>
            <PlanningTable/>
            {distributed()}
        </>
    );
};