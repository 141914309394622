import {createAsyncThunk} from '@reduxjs/toolkit'
import {get, post} from "../../api/baseRequests";
import {
    MDCreateWorkOrderRoutineRequest,
    MDUpdateWorkOrderRoutineRequest
} from "@zordi/zordi_object_schema";

export const createWorkOrderRoutine = createAsyncThunk('/workorder/routine/create', async (routineData: MDCreateWorkOrderRoutineRequest) => {
    return post('workorder/routine/create', {routineData});
})
export const getWorkOrderRoutinesByStatus = createAsyncThunk('/workorder/routine/getByStatus', async () => {
    const param = new URLSearchParams()
    param.append("status", "active")
    return get('workorder/routine/getByStatus', param);
})
export const updateWorkOrderRoutine = createAsyncThunk('/workorder/routine/update', async (routineData: MDUpdateWorkOrderRoutineRequest) => {
    return post('workorder/routine/update', {routineData});
})
export const deleteWorkOrderRoutine = createAsyncThunk('/workorder/routine/delete', async (id: string) => {
    return post('workorder/routine/delete', {id});
})
