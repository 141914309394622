import React, {useEffect, useState} from "react";
import {Flex, HStack, Select, VStack} from '@chakra-ui/react'
import {ZordiHeatMapWithGutterSide} from "./heatmap/legacy/ZordiHeatMapWithGutterSide";
import {UpperGreenhouseLayout} from "../data/GreenhouseLayout";
import {Title} from "./typography/title";
import {HeatmapProvider, useHeatmapContext} from "../contexts/HeatmapProvider";
import {HeatmapMap} from "../types/legacy/HeatmapResult";
import {GreenhouseSide} from "../types/legacy/HeatmapLayout";

export const GreenHouseSummary = () => {
    const [selectedMetric, setSelectedMetric] = useState<string>("red_fruit");
    const selectList: [string, string][] = [
        ["flower", "Flower"],
        ["white_fruit", "White fruit"],
        ["red_fruit", "Red fruit"],
        ["pink_fruit", "Pink fruit"],
        ["baby_fruit", "Young fruit"],
    ]

    return (
        <Flex
            w='full'
            bg="white"
            px={"30px"}
        >
            <VStack w='full'>
                <Flex w={'full'} justify={{base: "space-between"}}>
                    <Title w='full' align="left" fontSize='xl'
                           fontWeight={700}
                           fontFamily={"Poppins"} text="Greenhouse Heatmap"></Title>
                    <HStack w='300px'>
                        <Select
                            value={selectedMetric}
                            onChange={e => setSelectedMetric(e.target.value)}
                            fontFamily={"Poppins"}
                        >
                            {
                                selectList.map((button: [string, string]) => {
                                    return (
                                        <option key={button[0]} value={button[0]}>{button[1]}</option>
                                    )
                                })
                            }
                        </Select>

                    </HStack>
                </Flex>
                <HStack w='full'>
                    <HeatmapProvider>
                        <Heatmap selectedMetric={selectedMetric}></Heatmap>
                    </HeatmapProvider>
                </HStack>
            </VStack>
        </Flex>
    )
}
type HeatmapOption = {
    selectedMetric: string
}

const Heatmap = ({selectedMetric}: HeatmapOption) => {
    const heatmapContext = useHeatmapContext();
    const [heatmapData, setHeatmapData] = useState<HeatmapMap | null>(null);
    const [greenhouseLayout, setGreenhouseLayout] = useState<(GreenhouseSide | null)[][]>(UpperGreenhouseLayout)
    const [overviewValue, setOverviewValue] = useState<GreenhouseSide | null>(null)

    useEffect(() => {
        heatmapContext.getHeatmap().then((heatmapResult) => {
            setHeatmapData(heatmapResult)
        });
    }, [heatmapContext]);

    useEffect(() => {
        const newLayout = greenhouseLayout.map((row) => {
            return row.map(side => {
                    if (side) {
                        const zordiId = side.zordiId;
                        const newCell = {...side}
                        if (heatmapData && heatmapData[zordiId]) {
                            newCell["values"] = heatmapData[zordiId]
                        } else {
                            newCell["values"] = {
                                "baby_fruit": 0,
                                "pink_fruit": 0,
                                "red_fruit": 0,
                                "white_fruit": 0,
                                "flower": 0
                            }
                        }

                        return newCell
                    }
                    return null;
                }
            )
        })
        setGreenhouseLayout(newLayout)
        if (heatmapData) {
            setOverviewValue({
                bagId: 0, greenhouseId: 0, gutterId: 0, sideId: 0, zordiId: "",
                "values" : heatmapData["overview"]
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [heatmapData])
    return (
        <ZordiHeatMapWithGutterSide heatmapLayout={greenhouseLayout} selectedMetric={selectedMetric} overviewValue={overviewValue}/>
    );
};

export default GreenHouseSummary;
