import {WorkOrder} from "@zordi/zordi_object_schema";
import {Divider, Grid, GridItem, Stack, Text} from "@chakra-ui/react";
import React from "react";
import '../../css/site_report.css';

type WorkOrderCellProps = {
    workOrder: WorkOrder | undefined
    editable: boolean
    update: (workOrderUpdates: {
        workOrder: WorkOrder,
        fieldsToUpdate: { [key: string]: any }
    }[]) => Promise<void>
}


export const WorkOrderCell = ({workOrder}: WorkOrderCellProps) => {
    if (workOrder === undefined) {
        return (<span></span>)
    }

    return (
        <Grid
            w={"full"}
            templateColumns='repeat(6, 1fr)'
        >
            <GridItem colSpan={1}>
                <Stack direction='row'>
                    <Text><b>Parameter:</b></Text>
                </Stack>
            </GridItem>
            <GridItem colSpan={5}>
                <Text>{workOrder.action.actionParameter ? workOrder.action.actionParameter : "N/A"}</Text>
            </GridItem>
            <GridItem colSpan={6}>
                <Divider/>
            </GridItem>
            <GridItem colSpan={1}>
                <Stack direction='row'>
                    <Text><b>Region:</b></Text>
                </Stack>
            </GridItem>
            <GridItem colSpan={5}>
                <Text>{workOrder.targetRegion.regionId}</Text>
            </GridItem>
            <GridItem colSpan={1}>
            </GridItem>
        </Grid>
    )

}
