import {ComputedCell, ResponsiveHeatMapCanvas} from "@nivo/heatmap";
import React, {useEffect, useState} from "react";
import {Box, Grid, GridItem, Spinner, Text} from "@chakra-ui/react";
import {CellDetailView} from "./CellDetailView";
import {HeatMapCell, HeatMapCellRow, HeatMapCellType, Plant, SiteData} from "../../data/GreenhouseDataTypes";
import {getCustomCellRenderer} from "./customCell";
import {metricValueRetriever, valueToFixedDigits} from "./activeMetric";
import {MetricSensor} from "../../pages/SiteOverview";
import {setUrlParam} from "../../utils/url";
import {useSearchParams} from "react-router-dom";
import {plantIdToGrowerId} from '../../utils/labels';

type ZordiHeatMapProps = {
    layout: HeatMapCellRow[]
    selectedMetric: string
    siteData: SiteData
    loading: boolean
    scrollToMetricSensor: MetricSensor | undefined,
    sensorToLocationMap: { [key: string]: string }
}

export const ZordiHeatMap = ({
                                 layout,
                                 selectedMetric,
                                 siteData,
                                 loading,
                                 scrollToMetricSensor,
                                 sensorToLocationMap
                             }: ZordiHeatMapProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const getLocationParam = () => {
        // TODO: validate input
        if (searchParams.has("location")) {
            return searchParams.get("location")!
        } else {
            return ""
        }
    }

    const [selectedLocation, setSelectedLocation] = useState<string>(getLocationParam());
    const [selectedPlant, setSelectedPlant] = useState<Plant | null>(null);

    const setLocation = (location: string) => {
        setSelectedLocation(location)
        setUrlParam("location", location, searchParams, setSearchParams)
    }

    useEffect(() => {
        const plant: Plant = siteData.plants[selectedLocation];
        setSelectedPlant(plant)
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLocation, siteData])

    useEffect(() => {
        if (scrollToMetricSensor && sensorToLocationMap[scrollToMetricSensor.sensorId]) {
            const sensorLocation = sensorToLocationMap[scrollToMetricSensor.sensorId]
            if (siteData.plants[sensorLocation]) {
                setSelectedLocation(sensorLocation)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollToMetricSensor])

    return (
        <Grid w={"100%"} templateColumns='repeat(4, 1fr)' paddingBottom={"100px"}>
            <GridItem h={"1000px"} colSpan={3} borderRight={"2px solid #70AD47"}>
                {loading ? <Spinner marginLeft={"25px"} marginTop={"100px"} size='xl'></Spinner> :
                    <GreenhouseHeatMap data={layout} updateSelectedLocation={setLocation} siteData={siteData}
                                       selectedMetric={selectedMetric}
                                       selectedLocation={selectedLocation}
                    ></GreenhouseHeatMap>}

            </GridItem>
            <GridItem h={"1000px"} colSpan={1}>
                <CellDetailView selectedPlant={selectedPlant}
                                siteData={siteData}
                ></CellDetailView>
            </GridItem>
        </Grid>
    )
}

type GreenhouseHeatMapProps = {
    data: HeatMapCellRow[],
    updateSelectedLocation: (location: string) => void,
    selectedMetric: string,
    siteData: SiteData,
    selectedLocation: string
}

const GreenhouseHeatMap = ({
                               data,
                               updateSelectedLocation,
                               selectedMetric,
                               siteData,
                               selectedLocation
                           }: GreenhouseHeatMapProps) => {
    const onClick = (cell: ComputedCell<HeatMapCell>) => {
        updateSelectedLocation(cell.data["location"])
    }

    const getMinMaxValue = (data: HeatMapCellRow[]) => {
        let min = 100000 // big number
        let max = -100000 // small number
        for (const cellRow of data) {
            for (const cell of cellRow.data) {
                if (cell.cellType === HeatMapCellType.HEAT_MAP_DATA_CELL) {
                    min = Math.min(min, cell.y!)
                    max = Math.max(max, cell.y!)
                }
            }
        }

        // Let's try to make sure we don't use extreme colors
        const diff = max - min
        min = min - diff / 10
        max = max + diff / 10

        return {
            min, max
        }
    }

    const CustomTooltip = ({cell}: { cell: ComputedCell<any> }) => {
        const heatMapCell: HeatMapCell = cell.data as HeatMapCell
        const plant: Plant = siteData.plants[heatMapCell.unitId]

        if (plant === undefined) {
            return (<span></span>)
        }
        switch (heatMapCell.cellType) {
            case HeatMapCellType.HEAT_MAP_NO_DATA_CELL:
                return (<Box backgroundColor={"#FFFFFF"}
                             padding={'6px 9px'}
                             borderRadius={'2px'}
                             minWidth={'160px'}
                             boxShadow={'0 3px 5px rgba(0, 0, 0, .25)'}
                             color={'black'}
                             alignContent={"flex-start"}
                >
                    <Text>
                        {'id   '}&nbsp;<strong>{plantIdToGrowerId(plant.plantId)}</strong>
                        <br/>
                        {selectedMetric}&nbsp;<strong>{"N/A"}</strong>
                    </Text>
                </Box>)
            case HeatMapCellType.HEAT_MAP_DATA_CELL:
                return (
                    <Box backgroundColor={"#FFFFFF"}
                         padding={'6px 9px'}
                         borderRadius={'2px'}
                         minWidth={'160px'}
                         boxShadow={'0 3px 5px rgba(0, 0, 0, .25)'}
                         color={'black'}
                         alignContent={"flex-start"}
                    >
                        <Text>
                            {'id   '}&nbsp;<strong>{plantIdToGrowerId(plant.plantId)}</strong>
                            <br/>
                            {selectedMetric}&nbsp;
                            <strong>{valueToFixedDigits(metricValueRetriever[selectedMetric](plant))}</strong>
                        </Text>
                    </Box>
                )
            case HeatMapCellType.EMPTY_SPACE:
            case HeatMapCellType.ROW_ID_HOLDER:
            default:
                return (<span></span>)
        }
        //
        // if (!heatMapCell.isPlaceholder) {
        //     if (isPlantMetric(selectedMetric)) {
        //         const plant: Plant = siteData.plants[heatMapCell.unitId]
        //
        //         if (plant) {
        //             let valueToShow;
        //             if (metricValueRetriever[selectedMetric] !== undefined) {
        //                 valueToShow = valueToFixedDigits(metricValueRetriever[selectedMetric](plant))
        //             } else {
        //                 valueToShow = plant[selectedMetric]
        //             }
        //             return (
        //                 <Box backgroundColor={"#FFFFFF"}
        //                      padding={'6px 9px'}
        //                      borderRadius={'2px'}
        //                      minWidth={'160px'}
        //                      boxShadow={'0 3px 5px rgba(0, 0, 0, .25)'}
        //                      color={'black'}
        //                      alignContent={"flex-start"}
        //                 >
        //                     <Text>
        //                         {'id   '}&nbsp;<strong>{plantIdToGrowerId(plant.plantId)}</strong>
        //                         <br/>
        //                         {selectedMetric}&nbsp;<strong>{valueToShow}</strong>
        //                     </Text>
        //                 </Box>
        //             )
        //         }
        //
        //     } else {
        //         const region: Region = siteData.regions[heatMapCell.unitId]
        //
        //         if (region) {
        //             return (
        //                 <Box backgroundColor={"#FFFFFF"}
        //                      padding={'6px 9px'}
        //                      borderRadius={'2px'}
        //                      minWidth={'160px'}
        //                      boxShadow={'0 3px 5px rgba(0, 0, 0, .25)'}
        //                      color={'black'}
        //                      alignContent={"flex-start"}
        //                 >
        //                     <Text>
        //                         {'id'}&nbsp;<strong>{region.regionId}</strong>
        //                         <br/>
        //                         {selectedMetric}&nbsp;<strong>{
        //                             valueToFixedDigits(metricValueRetriever[selectedMetric](region))
        //                         }
        //                         {
        //                             region.sensorId ? (
        //                                 <>
        //                                     <br/>
        //                                     {"sensorId:"}&nbsp;{region.sensorId}
        //                                 </>
        //
        //                             ) : (<></>)
        //                         }</strong>
        //                     </Text>
        //                 </Box>
        //             )
        //         }
        //     }
        // }
        // if (cell && cell.data && cell.data.plant && !cell.data.plant.isPlaceholder) {
        //     return (
        //         <Box backgroundColor={"#FFFFFF"}
        //              padding={'6px 9px'}
        //              borderRadius={'2px'}
        //              minWidth={'160px'}
        //              boxShadow={'0 3px 5px rgba(0, 0, 0, .25)'}
        //              color={'black'}
        //              alignContent={"flex-start"}
        //         >
        //             <Text>
        //                 {'id   '}&nbsp;<strong>{cell.data.plant.zordiId}</strong>
        //                 <br/>
        //                 {selectedMetric}&nbsp;<strong>{
        //                 cell.data.plant.values ?
        //                     cell.data.plant.values[selectedMetric] : "AA"
        //             }</strong>
        //             </Text>
        //         </Box>
        //     )
        // } else {
        //     return (<span></span>)
        // }

    }


    return (<ResponsiveHeatMapCanvas
        data={data}
        margin={{top: 70, right: 60, bottom: 60, left: 60}}
        valueFormat=">-.2s"
        axisTop={null}
        axisBottom={null}
        axisRight={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Plant',
            legendPosition: 'middle',
            legendOffset: 40
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Plant',
            legendPosition: 'middle',
            legendOffset: -42
        }}
        colors={{
            type: 'diverging',
            scheme: "red_yellow_green",
            minValue: getMinMaxValue(data)["min"],
            maxValue: getMinMaxValue(data)["max"]
        }}


        hoverTarget={"cell"}
        emptyColor="#F6F6F4"
        inactiveOpacity={1}
        enableLabels={false}
        onClick={onClick}
        tooltip={CustomTooltip}
        // borderWidth={0.3}
        renderCell={getCustomCellRenderer(selectedMetric, selectedLocation, siteData)}

        legends={[
            {
                anchor: 'top',
                translateX: 0,
                translateY: -40,
                length: 400,
                thickness: 8,
                direction: 'row',
                tickPosition: 'after',
                tickSize: 3,
                tickSpacing: 4,
                tickOverlap: false,
                tickFormat: '>-.2s',
                title: 'Value →',
                titleAlign: 'start',
                titleOffset: 4
            }
        ]}

    />)
}
