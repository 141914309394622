import React, {useEffect, useState} from 'react';
import {
    Button,
    ChakraProvider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    extendTheme,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    Textarea,
    useDisclosure,
} from '@chakra-ui/react'
import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createWorkOrder, getWorkOrderOptions} from "../../redux/asyncThunk/workOrder";
import {resetWorkOrder, setIdle, WorkOrderFormOptions} from "../../redux/workOrderSlice";
import '../../css/work_order_sidebar.css';

import {FormEntries} from "../../lib/labels/WorkOrder";
import {localDateFromSnapshot} from "../../utils/date";
import {AppDispatch, RootState} from "../../utils/store";
import {FloatingSelect} from "../common/FloatingSelect";
import {Alert} from "../common/Alert";
import DatePicker from "react-datepicker";
import {MDCreateWorkOrderRequest, MDReadWorkOrderResponse} from "@zordi/zordi_object_schema";
import {WorkOrderStatus} from "@zordi/zordi_object_schema/src/to/References"
import {convertEnum} from "../../utils/transferObject";

const {
    CATEGORY,
    PROBLEM,
    PROBLEM_PARAMS,
    DATA_USED,
    PRIORITY,
    REGION,
    ASSIGNEE,
    ACTION,
    ACTION_PARAMS,
    VERIFICATION_METHOD,
    NOTES
} = FormEntries

type NewWorkOrderSidebarProps = {
    isOpen: boolean
    onClose: any
}

export const NewWorkOrderSidebar = ({isOpen, onClose}: NewWorkOrderSidebarProps) => {
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch<AppDispatch>()

    const [fetchRequested, setFetchRequested] = useState<boolean>(false)
    const fetchStatus = useSelector((state: RootState) => state.workOrder.status)
    const newWorkOrder = useSelector((state: RootState) => state.workOrder.newWorkOrder)
    const {isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose} = useDisclosure()
    const cancelRef = React.useRef()

    const options: WorkOrderFormOptions = useSelector((state: RootState) => state.workOrder.options)

    const [warning, setWarning] = useState<boolean>(false)
    const [date, setDate] = useState<Date>(
        searchParams.has("date") ? localDateFromSnapshot(searchParams.get("date") as string) : new Date())
    const [category, setCategory] = useState<string | null>(null)
    const [problem, setProblem] = useState<any>(null)
    const [problemParams, setProblemParams] = useState<any>("")
    const [dataUsed, setDataUsed] = useState<any>(null)
    const [priority, setPriority] = useState<any>(null)
    const [site, setSite] = useState<string | null>(null)
    const [region, setRegion] = useState<any>(null)
    const [assignee, setAssignee] = useState<any>(null)
    const [action, setAction] = useState<any>(null)
    const [actionParams, setActionParams] = useState<any>("")
    const [verificationMethod, setVerificationMethod] = useState<any>(null)
    const [notes, setNotes] = useState<any>("")


    useEffect(() => {
        // Set helpful initial values
        if (options.site && options.site.length > 0) {
            setSite(options.site[0].value)
        }

        if (options.category && options.category.length > 0) {
            setCategory(options.category[0].value)
        }
    }, [options])


    useEffect(() => {
        dispatch(getWorkOrderOptions())
    }, [dispatch])

    useEffect(() => {
        if ((fetchStatus === "succeeded" && newWorkOrder !== null && fetchRequested)) {
            onAlertOpen()
        } else if (fetchStatus === "failed" && fetchRequested) {
            onAlertOpen()
        }
    }, [fetchStatus, newWorkOrder, onAlertOpen, fetchRequested])

    const handleSubmit = () => {
        const required = [category, problem, priority, region, assignee, action, verificationMethod]
        if (required.includes(null)) {
            setWarning(true)
        } else {
            setWarning(false)
            if (!!category && !!problem && !!dataUsed && !!priority && !!region && !!assignee && !!action && !!verificationMethod) {
                const data: { [key: string]: any } = {
                    startDate: date,
                    status: WorkOrderStatus.SCHEDULED,
                    categoryId: category,
                    problemId: problem,
                    actionId: action,
                    priority,
                    regionId: region,
                    assignee,
                    dataUsed,
                    verificationMethod,
                }
                if (actionParams.length > 0) {
                    data.actionParameter = actionParams
                }
                if (problemParams.length > 0) {
                    data.problemParameter = problemParams
                }
                if (notes.length > 0) {
                    data.notes = notes
                }

                dispatch(createWorkOrder(data as MDCreateWorkOrderRequest))
                setFetchRequested(true)
            }
        }
    }

    const handleReset = () => {
        setWarning(false)
        setDate(searchParams.has("date") ? localDateFromSnapshot(searchParams.get("date") as string) : new Date())
        setCategory(null)
        setProblem(null)
        setProblemParams("")
        setDataUsed(null)
        setPriority(null)
        setSite(null)
        setRegion(null)
        setAssignee(null)
        setAction(null)
        setActionParams("")
        setVerificationMethod(null)
        setNotes("")
    }

    const renderWarning = (placeholder: string) => {
        return (
            <FormErrorMessage>{placeholder} is required for a new work order.</FormErrorMessage>
        )
    }

    const handleAlertCloseAll = () => {
        onAlertClose()
        dispatch(resetWorkOrder())
        dispatch(setIdle())
        setFetchRequested(false)

        onClose()
        handleReset()
    }

    const handleAlertClose = () => {
        onAlertClose()
        dispatch(resetWorkOrder())
        dispatch(setIdle())
        setFetchRequested(false)
    }

    const alertBody = (newWorkOrder: MDReadWorkOrderResponse) => {
        return (
            <p>
                <br/>
                <span className={"label"}>New Work order ID</span><br/>
                {newWorkOrder.workOrderId}
                <br/> <br/>
                <span className={"label"}>Summary</span><br/>
                Category: {convertEnum(newWorkOrder.category.label)} <br/>
                Action: {convertEnum(newWorkOrder.action.label)} <br/>
                Assignee: {newWorkOrder.assignee.name} <br/>
            </p>
        )
    }

    return (
        <Drawer
            isOpen={isOpen}
            placement='left'
            onClose={onClose}
            size={"md"}
        >
            <Alert status={fetchStatus} isOpen={isAlertOpen} onClose={handleAlertClose} onCloseAll={handleAlertCloseAll}
                   body={newWorkOrder ? alertBody(newWorkOrder) : null} cancelRef={cancelRef}
                   header={"New Work Order Submission"}/>
            <DrawerOverlay/>
            <DrawerContent>
                <DrawerCloseButton/>
                <DrawerHeader>
                    <Heading as={"h1"} size={"md"} color={"blackAlpha.700"}>New Work Order Form</Heading>
                </DrawerHeader>

                <DrawerBody>
                    <ChakraProvider theme={theme}>
                        <Flex direction={"column"} gap={"22px"}>
                            <FormControl variant="floating" isRequired>
                                <DatePicker
                                    selected={date}
                                    onChange={(date: Date) => {
                                        setDate(date);
                                    }}
                                    dateFormat="P"
                                />
                            </FormControl>
                            <FormControl variant="floating" isRequired isInvalid={warning && !site}>
                                <FloatingSelect selectLabel={"Site"}

                                                onChange={setSite} options={options.site}
                                                state={site}/>
                                <FormHelperText>Selecting site will generate region options.</FormHelperText>
                                {warning && !site ? renderWarning(REGION.label) : null}
                            </FormControl>
                            <FormControl variant="floating" isRequired isInvalid={warning && !region}>
                                <FloatingSelect selectLabel={"Regions"} onChange={setRegion}
                                                options={!!site ? options.region[site] : []}
                                                state={region}/>
                                {warning && !region ? renderWarning(REGION.label) : null}
                            </FormControl>
                            <FormControl variant="floating" isRequired isInvalid={warning && !category}>
                                <FloatingSelect selectLabel={"Category"} onChange={setCategory} state={category}
                                                options={options.category}/>
                                <FormHelperText>Selecting category will generate action and problem
                                    options.</FormHelperText>
                                {warning && !category ? renderWarning(CATEGORY.label) : null}
                            </FormControl>
                            <FormControl variant="floating" isRequired isInvalid={warning && !problem}>
                                <FloatingSelect selectLabel={"Problem"} onChange={setProblem}
                                                options={!!category ? options.problem[category] : []}
                                                state={problem}/>
                                {warning && !problem ? renderWarning(PROBLEM.label) : null}
                            </FormControl>
                            <FormControl variant="floating">
                                <Input placeholder={PROBLEM_PARAMS.label}
                                       onChange={(e) => setProblemParams(e.target.value)} value={problemParams}/>
                                <FormLabel>{PROBLEM_PARAMS.label}</FormLabel>
                            </FormControl>
                            <FormControl variant="floating" isRequired isInvalid={warning && !action}>
                                <FloatingSelect selectLabel={"Actions"} onChange={setAction}
                                                options={!!category ? options.action[category] : []}
                                                state={action}/>
                                {warning && !action ? renderWarning(ACTION.label) : null}
                            </FormControl>
                            <FormControl variant="floating">
                                <Input placeholder={ACTION_PARAMS.label}
                                       onChange={(e) => setActionParams(e.target.value)} value={actionParams}/>
                                <FormLabel>{ACTION_PARAMS.label}</FormLabel>
                            </FormControl>
                            <FormControl variant="floating" isRequired isInvalid={warning && !dataUsed}>
                                <FloatingSelect selectLabel={DATA_USED.label} onChange={setDataUsed}
                                                options={options.dataUsed} state={dataUsed}/>
                                {warning && !dataUsed ? renderWarning(DATA_USED.label) : null}
                            </FormControl>
                            <FormControl variant="floating" isRequired isInvalid={warning && !priority}>
                                <FloatingSelect selectLabel={PRIORITY.label} onChange={setPriority}
                                                options={PRIORITY.options} state={priority}/>
                                {warning && !priority ? renderWarning(PRIORITY.label) : null}
                            </FormControl>

                            <FormControl variant="floating" isRequired isInvalid={warning && !assignee}>
                                <FloatingSelect selectLabel={"Workers"} onChange={setAssignee}
                                                options={options.worker} state={assignee}/>
                                {warning && !assignee ? renderWarning(ASSIGNEE.label) : null}
                            </FormControl>
                            <FormControl variant="floating" isRequired isInvalid={warning && !verificationMethod}>
                                <FloatingSelect selectLabel={VERIFICATION_METHOD.label} onChange={setVerificationMethod}
                                                options={options.verificationMethod}
                                                state={verificationMethod}/>
                                {warning && !verificationMethod ? renderWarning(VERIFICATION_METHOD.label) : null}
                            </FormControl>
                            <FormControl variant="floating">
                                <Textarea placeholder={NOTES.label} onChange={(e) => setNotes(e.target.value)}
                                          value={notes}/>
                                <FormLabel>{NOTES.label}</FormLabel>
                            </FormControl>
                        </Flex>
                    </ChakraProvider>
                </DrawerBody>
                <DrawerFooter>
                    <Button variant='outline' mr={3} onClick={handleReset}>
                        Reset
                    </Button>
                    <Button colorScheme='blue' onClick={handleSubmit}>Save</Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

const activeLabelStyles = {
    transform: "scale(0.85) translateY(-24px)",
};

export const theme = extendTheme({
    components: {
        Form: {
            variants: {
                floating: {
                    container: {
                        _focusWithin: {
                            label: {
                                ...activeLabelStyles
                            }
                        },
                        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
                            ...activeLabelStyles
                        },
                        label: {
                            top: 0,
                            left: 0,
                            zIndex: 2,
                            position: "absolute",
                            backgroundColor: "white",
                            pointerEvents: "none",
                            mx: 3,
                            px: 1,
                            my: 2,
                            transformOrigin: "left top"
                        }
                    }
                }
            }
        }
    }
});
