import React, { ChangeEvent, useState } from "react";
import "./DateTimePicker.css";
import {Button, HStack, Select, Stack, StyleProps, Text, useMediaQuery} from '@chakra-ui/react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { titleStyle } from "../ZordiChakraTheme";

type DateTimePickerProps = {
    startTime: Date
    endTime: Date
    setStartTime: (newTime: Date) => void
    setEndTime: (newTime: Date) => void
}

export const DateTimePicker = ({
    startTime,
    endTime,
    setStartTime,
    setEndTime,
}: DateTimePickerProps) => {
    const [newStartTime, setNewStartTime] = useState(startTime);
    const [newEndTime, setNewEndTime] = useState(endTime);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
    const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)')

    const onQuickTimeSelect = (e: ChangeEvent<HTMLSelectElement>): void => {
        const index = Number(e.target.value);
        setSelectedOptionIndex(index);
        setNewStartTime(quickTimeSelect[index][1](newEndTime));
    }

    const quickTimeSelect: [string, (endTime:Date) => Date][] = [
        ["Last 24 hours", (endTime) => new Date(moment(endTime).subtract(1, 'day').unix() * 1000)],
        ["Last 1 week", (endTime) => new Date(moment(endTime).subtract(1, 'week').unix() * 1000)],
        ["Last 1 month", (endTime) => new Date(moment(endTime).subtract(1, 'month').unix() * 1000)],
        ["Last 3 months", (endTime) => new Date(moment(endTime).subtract(3, 'months').unix() * 1000)],
        ["Last 1 year", (endTime) => new Date(moment(endTime).subtract(1, 'year').unix() * 1000)],
    ];

    const quickTimeSelectStyle: StyleProps = {
        w: "180px",
        bg: "#F6F6F4",
        borderColor: "#CDD0D9",
        borderRadius: "4px",
        borderWidth: "1px"
    }


    return (
        <HStack w={"100%"} justify={"space-between"} py={5}>
            <Text {...titleStyle} hidden={!isLargerThan1280}>Date & Time Range</Text>
            <Stack direction={{ base: "column", "lg": "row" }} fontFamily={"Poppins"}>
                <Select
                    {...quickTimeSelectStyle}
                    onChange={onQuickTimeSelect}
                    fontFamily={"Poppins"}
                >
                    {
                        quickTimeSelect.map((option, index) => {
                            return (
                                <option key={index}
                                    value={index}>{option[0]}</option>
                            )
                        })
                    }
                </Select>
                <HStack>
                    <Text>of</Text>
                    <DatePicker
                        selected={newEndTime}
                        onChange={(date: Date) => {
                            setNewEndTime(date);
                            setNewStartTime(quickTimeSelect[selectedOptionIndex][1](date));
                        }} //only when value has changed
                        dateFormat="P"
                    />
                </HStack>

                <Button variant='zordi-basic'
                    onClick={() => {
                        setStartTime(newStartTime);
                        setEndTime(newEndTime);
                     }}>Apply</Button>
            </Stack>
        </HStack>
    )
}

export default DateTimePicker;
