import React, {useEffect, useState} from "react";
import {Box, Button, HStack, VStack} from '@chakra-ui/react'
import {TimeSeriesProvider, useTimeseriesContext} from "../contexts/TimeSeriesProvider";
import {Title} from "./typography/title";
import {ZordiChartJSLineChart} from "./ZordiChartJSLineChart";
import {TimeseriesData} from "../types/TimeseriesData";

type HighLevelMetricProps = {
    startTime: Date,
    endTime: Date,
    metricName: string[],
    graphName: string
}

enum RangeOption {
    ONE_DAY,
    THREE_DAYS,
    ONE_WEEK
}

export const HighLevelLegacyMetric = ({endTime, metricName, graphName}: HighLevelMetricProps) => {
    const [range, setRange] = useState<RangeOption>(RangeOption.ONE_DAY);

    const intervalOptionToNumberOfMilliseconds = {
        [RangeOption.ONE_DAY]: 86400 * 1000,
        [RangeOption.THREE_DAYS]: 86400 * 3 * 1000,
        [RangeOption.ONE_WEEK]: 86400 * 7 * 1000,
    }

    const calculateStartTime = () => {
        return new Date(endTime.getTime() - intervalOptionToNumberOfMilliseconds[range])
    }

    return (
        <VStack w='full' p={"10px"}>
            <HStack w={"100%"} justify={"space-between"}>
                <Title text={graphName}></Title>
                <Button isActive={range === RangeOption.ONE_DAY} onClick={() => setRange(RangeOption.ONE_DAY)}>24 hrs</Button>
                <Button isActive={range === RangeOption.THREE_DAYS} onClick={() => setRange(RangeOption.THREE_DAYS)}>3 days</Button>
                <Button isActive={range === RangeOption.ONE_WEEK} onClick={() => setRange(RangeOption.ONE_WEEK)}>1 week</Button>
            </HStack>

            <Box w='100%' p={4} color='blue'>
                <TimeSeriesProvider startTime={calculateStartTime()} endTime={endTime}
                                    siteId={"BOS1"}
                                    metricNames={metricName}>
                    <Graph></Graph>
                </TimeSeriesProvider>
            </Box>
        </VStack>
    )
}

const Graph = () => {
    const timeseriesContext = useTimeseriesContext();
    const [timeseriesChartJs, setTimeseriesChartJs] = useState<TimeseriesData | null>(null);

    useEffect(() => {

        timeseriesContext.getTimeseriesChartJs('BOS1')
            .then((timeseriesData) => {
                setTimeseriesChartJs(timeseriesData)
            });
    }, [timeseriesContext]);
    return (
        <ZordiChartJSLineChart timeseriesData={timeseriesChartJs} sensorSelected={() => {}}></ZordiChartJSLineChart>
    );
};
export default HighLevelLegacyMetric;
