// List of Possible Cells
// Plant:
// cell with data
// cell with no data
// placeholder(aisle)
// rowId number holder
// containerId holder
export enum HeatMapCellType {
    HEAT_MAP_DATA_CELL,
    HEAT_MAP_NO_DATA_CELL,
    EMPTY_SPACE,
    ROW_ID_HOLDER,
    CONTAINER_ID_HOLDER
}

export type HeatMapCell = {
    x: number, // columnId
    y: number | undefined // value
    location: string
    isPlaceholder: boolean
    unitId: string
    cellType: HeatMapCellType
    [key: string]: any
}

export type HeatMapCellRow = {
    id: string, // rowId
    data: HeatMapCell[]
}

export type UnitLocation = {
    site_id: string
    section_id: number
    plant_id: number
}

export const isEqualUnitLocation = (location1: UnitLocation, location2: UnitLocation): boolean => {
    return location1.site_id === location2.site_id
        && location1.section_id === location2.section_id
        && location1.plant_id === location2.plant_id
}

export const unitLocationToLocationId = (unitLocation: UnitLocation) => {
    return `${unitLocation.site_id}-${unitLocation.section_id}-${unitLocation.plant_id}`
}

export type Plant = {
    plantId: string
    snapshotDate: string
    unitLocation: UnitLocation
    regions?: { [key: string]: string }

    // features. heights, fruitCount, flowerCount etc
    [key: string]: any

    // concerns
    concerns?: string[]
}

export type Region = {
    regionId: string
    snapshotDate: string
    unitLocation: UnitLocation
    regionType: string
    predefined?: boolean // predefined Regions have pre-determined list of plants
    plants?: string[]
    sensorId?: string

    // features. bugCount etc
    [key: string]: any

    // concerns
    concerns?: string[]
}

export type SiteData = {
    plants: {
        [key: string]: Plant
    },
    regions: {
        [key: string]: Region
    },
}

export const locationToLocationId = (location: UnitLocation) => {
    return `${location.site_id}-${location.section_id}-${location.plant_id}`
}
