import {ImageType, ImageTypes, Plant} from "@zordi/zordi_object_schema";

const activePlantMetric = [
    "height", "flower", "baby_fruit", "white_fruit", "pink_fruit", "red_fruit", "leafArea",
    "mr_sensor_par", "mr_sensor_temperature",
    "mr_sensor_humidity",
]

const diseasePestMetric = [
    "aphid",
    "fungus_gnat",
    "thrip",
    "house_fly"]

export const getActivePlantMetric = (dev: boolean = false) => {
    if (dev) {
        return [...activePlantMetric, ...diseasePestMetric]
    } else {
        return activePlantMetric
    }
}

export const metricValueRetriever: { [key: string]: (data: any) => number | undefined } =
    {
        "height": (plant: Plant) => plant["canopyHeight"] ? plant["canopyHeight"]["height"] : undefined,
        "flower": (plant: Plant) => plant["fruitFlower"] ? plant["fruitFlower"]["flower"] : undefined,
        "baby_fruit": (plant: Plant) => plant["fruitFlower"] ? plant["fruitFlower"]["baby_fruit"] : undefined,
        "white_fruit": (plant: Plant) => plant["fruitFlower"] ? plant["fruitFlower"]["white_fruit"] : undefined,
        "pink_fruit": (plant: Plant) => plant["fruitFlower"] ? plant["fruitFlower"]["pink_fruit"] : undefined,
        "red_fruit": (plant: Plant) => plant["fruitFlower"] ? plant["fruitFlower"]["red_fruit"] : undefined,
        "leafArea": (plant: Plant) => plant["leafAreaIndex"] ? plant["leafAreaIndex"]["index"] : undefined,
        "mr_sensor_par": (plant: Plant) => plant["mr_sensor_par"] ? plant["mr_sensor_par"]["value"] : undefined,
        "mr_sensor_temperature": (plant: Plant) => plant["mr_sensor_temperature"] ?
            plant["mr_sensor_temperature"]["value"] : undefined,
        "mr_sensor_humidity": (plant: Plant) =>
            plant["mr_sensor_humidity"] ? plant["mr_sensor_humidity"]["value"] : undefined,
        "aphid": (plant: Plant) => plant["stickyTrap"] ? plant["stickyTrap"]["aphid"] : undefined,
        "house_fly": (plant: Plant) => plant["stickyTrap"] ? plant["stickyTrap"]["house_fly"] : undefined,
        "fungus_gnat": (plant: Plant) => plant["stickyTrap"] ? plant["stickyTrap"]["fungus_gnat"] : undefined,
        "thrip": (plant: Plant) => plant["stickyTrap"] ? plant["stickyTrap"]["thrip"] : undefined,
    }

export const valueToFixedDigits = (value: (number | undefined)) => {
    if (value === undefined) {
        return "N/A"
    } else if (Number.isInteger(value)) {
        return value
    } else {
        return value.toFixed(2)
    }
}

export const getPlantImages = (plant: Plant) => {
    const plantImages: { [key: string]: string }[] = []

    for (const imageField of Object.values(ImageTypes)) {
        const image = plant[imageField]
        if (image !== undefined) {
            const images = image.images
            const bucket = image.bucket
            for (const image of images) {
                if (image.imageType === ImageType.RGB) {
                    plantImages.push({
                        position: imageField,
                        bucket,
                        path: image.path
                    })
                }
            }
        }
    }
    return plantImages
}
