import React, {useEffect, useState} from "react";
import "firebase/compat/auth";
import {HStack, Table, TableContainer, Tbody, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {useDispatch, useSelector} from 'react-redux';
import '../../css/site_report.css';
import {AppDispatch, RootState} from "../../utils/store";
import {ConcernsBySource} from "../../redux/reportDataSlice";
import {fetchConcerns} from "../../redux/asyncThunk/reportData";
import {useParams} from "react-router";
import {Concern, TaskRecord} from "@zordi/zordi_object_schema";
import {ConcernRow} from "./ConcernRow";

type ComparisonByDay = {
    date: string,
    matched: {
        growerConcernId: string,
        reviewerConcernId: string
    }[],
    unmatchedGrowerConcernIds: string[],
    unmatchedReviewerConcernIds: string[],
}

export const SiteReportReVsIn = () => {
    let {siteId} = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const concerns = useSelector((state: RootState) => state.reportData.concerns);
    const [concernMap, setConcernMap] = useState<{ [key: string]: Concern }>({})
    const [comparisons, setComparisons] = useState<ComparisonByDay[]>([]);
    const [comparisonReportRecord, setComparisonReportRecord] = useState<TaskRecord | undefined>(undefined);

    useEffect(() => {
        if (siteId) {
            dispatch(fetchConcerns());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteId]);

    useEffect(() => {
        setComparisonReportRecord(concerns.comparisonReportRecord)
        setComparisons(sortConcernsByDate(concerns)
            .sort((a, b) => {
                return a.date < b.date ? 1 : -1
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [concerns]);


    const headRow = () => {
        return (
            <Tr className={"border-bottom"}>
                <Th><span className="align-center">Date</span></Th>
                <th colSpan={2} className="chakra-th align-center">Status</th>
                <Th></Th>
                <Th className="align-center">Concern Type: Concern</Th>
                <Th className="align-center">Remote</Th>
                <Th className="align-center">In-Person</Th>
                <Th className="align-center">Remotely<br/>Observable</Th>
            </Tr>
        )
    }

    const sortConcernsByDate = (concerns: ConcernsBySource) => {
        // group by date
        const growerConcernsMap: { [key: string]: Concern[] } = {}
        const reviewerConcernsMap: { [key: string]: Concern[] } = {}
        const allConcernMap: { [key: string]: Concern } = {}

        concerns.growerConcerns.forEach(concern => {
            if (growerConcernsMap[concern.snapshotDate]) {
                growerConcernsMap[concern.snapshotDate].push(concern)
            } else {
                growerConcernsMap[concern.snapshotDate] = [concern]
            }
            allConcernMap[concern.concernId] = concern

        })
        concerns.reviewerConcerns.forEach(concern => {
            if (reviewerConcernsMap[concern.snapshotDate]) {
                reviewerConcernsMap[concern.snapshotDate].push(concern)
            } else {
                reviewerConcernsMap[concern.snapshotDate] = [concern]
            }
            allConcernMap[concern.concernId] = concern
        })

        setConcernMap(allConcernMap)

        const comparisonsByDay: ComparisonByDay[] = []

        const dates = [...new Set([...Object.keys(growerConcernsMap), ...Object.keys(reviewerConcernsMap)])]
        for (const date of dates) {
            const comparisonByDay = processDayData(date,
                growerConcernsMap[date] ? growerConcernsMap[date] : [],
                reviewerConcernsMap[date] ? reviewerConcernsMap[date] : [])
            comparisonsByDay.push(comparisonByDay)
        }
        return comparisonsByDay
    }

    const processDayData = (date: string, growerConcerns: Concern[],
                            reviewerConcerns: Concern[]): ComparisonByDay => {
        const matched = []
        const unmatchedGrowerConcernIds: string[] = []
        const unmatchedReviewerConcernIds: string[] = []

        for (const growerConcern of growerConcerns) {
            if (growerConcern.matchKey) {
                for (const reviewerConcern of reviewerConcerns) {
                    if (growerConcern.matchKey === reviewerConcern.matchKey) {
                        matched.push({
                            growerConcernId: growerConcern.concernId,
                            reviewerConcernId: reviewerConcern.concernId,
                        })
                        break
                    }
                }
            } else {
                unmatchedGrowerConcernIds.push(growerConcern.concernId)
            }
        }

        for (const reviewerConcern of reviewerConcerns) {
            if (!reviewerConcern.matchKey) {
                unmatchedReviewerConcernIds.push(reviewerConcern.concernId)
            }
        }

        return {
            date,
            matched,
            unmatchedGrowerConcernIds,
            unmatchedReviewerConcernIds
        }
    }

    return (
        <>
            <HStack justify={"space-between"} w={"80%"}>
                <Text></Text>
                <Text>{comparisonReportRecord !== undefined ?
                    `(As of ${new Date(comparisonReportRecord.runAt).toLocaleString()})` :
                    ""
                }</Text>
            </HStack>

            <TableContainer>
                <Table className="report-table">
                    <Thead>
                        {headRow()}
                    </Thead>
                    <Tbody>
                        {comparisons.map((comparison) => {
                            const rows = [
                                ...comparison.matched.map(concernOrder => [concernMap[concernOrder.growerConcernId],
                                    concernMap[concernOrder.reviewerConcernId]]),
                                ...comparison.unmatchedGrowerConcernIds.map(
                                    concernId => [concernMap[concernId], undefined]),
                                ...comparison.unmatchedReviewerConcernIds.map(
                                    concernId => [undefined, concernMap[concernId]])
                            ]

                            return rows.map((row, i) => {
                                return (<ConcernRow
                                    key={i}
                                    date={comparison.date}
                                    growerConcern={row[0]}
                                    idx={i}
                                    length={rows.length}
                                    reviewerConcern={row[1]}/>)
                            });
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
};
