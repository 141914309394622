import {locationToLocationId, Plant, SiteData} from "../data/GreenhouseDataTypes";
import {UnitLocation} from "@zordi/zordi_object_schema";

/**
 * Get the next plant in the same row
 * Return null if there is none.
 * TODO: skip missing plants?
 */
export const getNextInRow = (selectedPlant: Plant | null, site: SiteData): Plant | null => {
    if (selectedPlant == null) {
        return null;
    }
    const currentPlantlocation = selectedPlant.unitLocation
    const nextPlantLocation: UnitLocation = {
        "site_id": currentPlantlocation.site_id,
        "section_id": currentPlantlocation.section_id,
        "plant_id": currentPlantlocation.plant_id + 1,
    }

    return site.plants[locationToLocationId(nextPlantLocation)]
}
export const getPreviousInRow = (selectedPlant: Plant | null, site: SiteData): Plant | null => {
    if (selectedPlant == null) {
        return null;
    }
    const currentPlantlocation = selectedPlant.unitLocation
    const previousPlantLocation: UnitLocation = {
        "site_id": currentPlantlocation.site_id,
        "section_id": currentPlantlocation.section_id,
        "plant_id": currentPlantlocation.plant_id - 1,
    }

    return site.plants[locationToLocationId(previousPlantLocation)]
}
export const getPlant = (selectedPlant: Plant | null, site: SiteData): Plant | null => {
    if (selectedPlant == null) {
        return null;
    }
    return site.plants[locationToLocationId(selectedPlant.unitLocation)]
}
