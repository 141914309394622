import React from "react";
import {Line} from 'react-chartjs-2';
import {
    CategoryScale,
    Chart as ChartJS, ChartEvent,
    Legend, LegendItem,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {MetricDataSet, TimeseriesData} from "../types/TimeseriesData";
import {Spinner} from "@chakra-ui/react";
import {getLabelOrDefault} from "../utils/labels";

const colorPalette: string[] = [
    "#eea29a",
    "#c94c4c",
    "#b1cbbb",
    "#deeaee",
    "#f4e1d2",
    "#b2b2b2",
    "#50394c",
    "#ffef96"
]

type ZordiChartJSLineChartProps = {
    timeseriesData: TimeseriesData | null,
    sensorSelected: (sensorId: string) => void
}
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const ZordiChartJSLineChart = ({timeseriesData, sensorSelected}: ZordiChartJSLineChartProps) => {
    if (!timeseriesData) {
        return (<Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
        />)
    }


    const labels = timeseriesData.labels;
    const datasets = timeseriesData.metrics.map((metric: MetricDataSet, index: number) => {
        return {
            label: getLabelOrDefault(metric.id, metric.id),
            data: metric.data,
            backgroundColor: colorPalette[index],
            borderColor: colorPalette[index],
        }
    })

    const legendOnclick = (e: ChartEvent, legendItem: LegendItem) => {
        sensorSelected(legendItem.text)
    }

    return (<div
        style={{
            minWidth: 0,
            width: "100%",
        }}
    >
        <Line
            options={{
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top' as const,
                        onClick: legendOnclick
                    },

                },
                spanGaps: true,
                scales: {
                    xAxes: {
                        display: true,
                        ticks: {
                            callback: (val, index) => {
                                return index % (Math.floor(labels.length / 5)) === 0 ? labels[index] : '';
                            },
                        }
                    }
                },

            }}
            data={{
                labels,
                datasets
            }}
        />
    </div>)
}