import {MetricFields} from '../types/MetricFields';
import {TimeseriesQuery} from '../types/TimeseriesQuery';
import {get} from './baseRequests';
import {Datum, Serie} from '@nivo/line';
import moment from 'moment';
import {TimeseriesData} from "../types/TimeseriesData";
import {HeatmapMap, HeatmapResult} from "../types/legacy/HeatmapResult";
import {SiteData} from "../data/GreenhouseDataTypes";

export const parseMetricFields = (json: any): MetricFields => {
    return json as MetricFields;
}

export const fetchFields = async (): Promise<MetricFields> => {
    return get('fields').then(json => parseMetricFields(json));
}

export const fetchHeatmap = async (): Promise<HeatmapMap> => {
    return get('heatmap').then(json => parseHeatmap(json));
}

export const fetchSiteData = async (snapshotDate: string, site: string): Promise<SiteData> => {
    return get(`heatmap/site?snapshotDate=${snapshotDate}&site=${site}`);
}

// check and update promise return type
export const fetchPlant = async (plantId:string, date:string): Promise<any> => {
    return get(`plant?plantId=${plantId}&snapshotDate=${date}`).then(json => json["plant"]);
}

export const fetchSignedUrl = async (bucket: string, s3path: string): Promise<string> => {
    return get(`heatmap/signedUrl?bucket=${bucket}&s3path=${s3path}`).then(json => json["signedUrl"]);
}

export const fetchSignedUrls = async (data:string): Promise<string> => {
    return get(`heatmap/signedUrls?data=${data}`).then(json => json["signedUrls"]);
}


export const fetchTimeSeriesNivo = async (timeseriesQuery: TimeseriesQuery): Promise<Serie[]> => {
    return get('ts', new URLSearchParams(timeseriesQuery.toQueryParams()))
        .then(json => parseTimeSeriesResponseNivo(timeseriesQuery.metricNames, json));
}

export const fetchTimeSeriesChartJs = async (timeseriesQuery: TimeseriesQuery, interval: number,
                                             sectionName?: string): Promise<TimeseriesData> => {
    return get('ts', new URLSearchParams(timeseriesQuery.toQueryParams()))
        .then(json => parseTimeSeriesResponseChartJs(timeseriesQuery.metricNames, json, interval, sectionName));
}

export const fetchMonnitTimeSeries = async (timeseriesQuery: TimeseriesQuery, interval: number): Promise<TimeseriesData> => {
    const dateFormat = "MM/DD h:mmA"

    return get('ts/monnit', new URLSearchParams(timeseriesQuery.toQueryParams()))
        .then(json => parseMonnitTimeSeriesResponse(timeseriesQuery.metricNames, json, interval, dateFormat));
}

export const fetchReportTimeSeries = async (timeseriesQuery: TimeseriesQuery,
                                            interval: number): Promise<TimeseriesData> => {
    const dateFormat = "YYYY-MM-DD"

    return get('ts/report', new URLSearchParams(timeseriesQuery.toQueryParams()))
        .then(json => parseMonnitTimeSeriesResponse(timeseriesQuery.metricNames, json, interval, dateFormat));
}

export const parseMonnitTimeSeriesResponse = (fieldNames: string[], json: any, interval: number,
                                              dateFormat: string): TimeseriesData => {
    const labels: string[] = []
    // expected timeseries data has the following format
    // { metricName-sensorId : [ {timestamp, value}, .... ]

    // Because there can be no guarantee whether all graphs will have the same data points, we need to fill the gaps
    let startTime: number = Number.MAX_SAFE_INTEGER
    let endTime: number = 0

    // Find the smallest startTime and largest endtime
    for (const metricName in json) {
        const dataPoints: any[] = json[metricName]
        startTime = Math.min(startTime, dataPoints[0]["timestamp"]);
        endTime = Math.max(endTime, dataPoints[dataPoints.length - 1]["timestamp"]);
    }

    for (let timestamp: number = startTime; timestamp <= endTime;
         timestamp = timestamp + interval) {
        const date = new Date(timestamp);
        labels.push(moment(date).utc(false).local().format(dateFormat))
    }

    const metrics = []
    for (const metricName in json) {
        const dataPoints = json[metricName]
        const data: number[] = []

        for (const dataPoint of dataPoints) {
            const dataPointDate = new Date(dataPoint["timestamp"])
            const index: number = (dataPointDate.getTime() - startTime) / interval
            data[index] = dataPoint["value"]
        }
        metrics.push({
            id: metricName,
            data
        })
    }

    return {
        labels,
        metrics
    }
}


export const parseTimeSeriesResponseChartJs = (fieldNames: string[], json: any, interval: number,
                                               sectionName?: string): TimeseriesData => {
    // json is an array of elasticsearch results for each field in fieldNames.
    // The order is preserved

    const labels: string[] = []
    let startTime: number = Number.MAX_SAFE_INTEGER
    let endTime: number = 0

    // Find the smallest startTime and largest endtime
    json.forEach((esResult: any) => {
        if (esResult.aggregations.aggs.buckets.length > 0) {
            startTime = Math.min(startTime, esResult.aggregations.aggs.buckets[0]["key"]);
            endTime = Math.max(endTime,
                esResult.aggregations.aggs.buckets[esResult.aggregations.aggs.buckets.length - 1]["key"])
        }
    })

    const formatTime = "hh:mm A"
    const formatDate = "YYYY-MM-DD"
    for (let timestamp: number = startTime; timestamp <= endTime; timestamp = timestamp + interval) {
        const date = new Date(timestamp);
        // TODO: hard coded for the demo. Remove later.
        if (interval === 15000 && sectionName === 'Environment') {
            labels.push(moment(date).format(formatTime));
        } else {
            labels.push(moment(date).format(formatDate));
        }
    }

    return {
        labels,
        metrics: json.map((esResult: any, index: number) => {
            const fieldName = fieldNames[index]
            const data: number[] = []
            esResult.aggregations.aggs.buckets.forEach((item: { [key: string]: any }) => {
                const index: number = (item["key"] - startTime) / interval
                data[index] = item[fieldName]["value"]
            })

            return {
                id: fieldName,
                data
            }
        })
    }
}

export const parseTimeSeriesResponseNivo = (fieldNames: string[], json: any): Serie[] => {
    // json is an array of elasticsearch results for each field in fieldNames.
    // The order is preserved

    return json.map((esResult: any, index: number) => {
        const fieldName = fieldNames[index]
        const points: Datum[] = esResult.aggregations.aggs.buckets.map((item: { [key: string]: any }) => {
            return {
                x: moment(item["key_as_string"]).format("YYYY-MM-DD HH:mm:ss"),
                y: item[fieldName]["value"]
            };
        })

        return {
            id: fieldName,
            data: points
        }
    })
}

const parseHeatmap = (json: any): HeatmapMap => {
    const heatmapResults: HeatmapResult[] = json.aggregations.per_side.buckets;

    const heatmapMap: { [key: string]: any } = {}

    heatmapResults.forEach(heatmapResult => {
        heatmapMap[heatmapResult.key] = {
            "baby_fruit": heatmapResult.baby_fruit.value,
            "pink_fruit": heatmapResult.pink_fruit.value,
            "red_fruit": heatmapResult.red_fruit.value,
            "white_fruit": heatmapResult.white_fruit.value,
            "flower": heatmapResult.flower.value
        }
    })

    heatmapMap["overview"] = {
        "baby_fruit": json.aggregations.total_baby_fruit.value,
        "pink_fruit": json.aggregations.total_pink_fruit.value,
        "red_fruit": json.aggregations.total_red_fruit.value,
        "white_fruit": json.aggregations.total_white_fruit.value,
        "flower": json.aggregations.total_flower.value
    }
    return heatmapMap;
}