import {Text} from "@chakra-ui/react";
import React from "react";
import {HeatmapLayout} from "../../../types/legacy/HeatmapLayout";

type GreenhouseGridProps = {
    width: string,
    height: string,
    heatmapLayout: HeatmapLayout,
}

// const gridScale = 16;
// const gridX = 140
// const gridY = 100

export const GreenhouseGrid = ({width, height, heatmapLayout}: GreenhouseGridProps) => {
    return (<Text>
        {"SDFSA"}
    </Text>)
}
//     const canvasDimension: Dimension = useMemo(() => {
//         return {width: parseInt(width), height: parseInt(height)}
//     }, [width, height])
//
//     const canvasId = "canvasId";
//     const pointMap: { [key: string]: HeatmapSide } = {}
//     heatmapLayout.dataPoints.forEach((dataPoint: HeatmapSide) => {
//         const coordinate: string = `${dataPoint.side.x}-${dataPoint.side.y}`
//         pointMap[coordinate] = dataPoint
//     })
//
//     const [canvas, setCanvas] = useState<HTMLCanvasElement>();
//     const [context2D, setContext2D] = useState<CanvasRenderingContext2D>();
//     const [dragging, setDragging] = useState(false);
//     const [lastPosition, setLastPosition] = useState({x: 0, y: 0});
//     const [panZoom, setPanZoom] = useState({
//         scale: 1.0,
//         x: 0, y: 0
//     });
//
//     const [gridHovered, setGridHovered] = useState<string>("")
//
//     const mousePosition = useMousePositionHook(canvas)
//     const mouseDown = useMouseDown(canvas);
//     const mouseWheel = useMouseScroll(canvas);
//
//     // Initialize Canvas and greenhouse drawing
//     useEffect(() => {
//         const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
//         setCanvas(canvas)
//         const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
//         setContext2D(ctx)
//         drawGreenhouse(ctx, heatmapLayout.getDisplayForPoints())
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);
//
//     // track mouse drag
//     useEffect(() => {
//         if (mouseDown) {
//             if (dragging) {
//                 let x = panZoom.x + mousePosition.x - lastPosition.x
//                 let y = panZoom.y + mousePosition.y - lastPosition.y
//                 setPanZoom({...panZoom, x, y,})
//                 setLastPosition(mousePosition)
//             } else {
//                 setLastPosition(mousePosition)
//                 setDragging(true)
//             }
//         } else {
//             if (dragging) {
//                 setDragging(false)
//             }
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [mousePosition, mouseDown, dragging]);
//
//     useEffect(() => {
//         const mouseGridPosition: Position = calculateGrid(mousePosition, panZoom)
//         const position: string = `${mouseGridPosition.x}-${mouseGridPosition.y}`
//         if (pointMap[position]) {
//             setGridHovered(position)
//         } else {
//             setGridHovered("")
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [mousePosition])
//
//     // track mouse wheel motion
//     useEffect(() => {
//         const scaleRate = mouseWheel > 0 ? 1.05 : 1 / 1.05;
//         let scale = panZoom.scale * scaleRate
//
//
//         if (scale < 0.6 || scale > 2) {
//             return
//         }
//         let x = mousePosition.x - (mousePosition.x - panZoom.x) * scaleRate;
//         let y = mousePosition.y - (mousePosition.y - panZoom.y) * scaleRate;
//
//         setPanZoom({...panZoom, x, y, scale})
//
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [mouseWheel])
//
//     // repaint
//     useEffect(() => {
//         // move event
//         if (context2D) {
//             redrawGreenhouse(context2D, heatmapLayout.getDisplayForPoints(), canvasDimension, panZoom, mousePosition)
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [mousePosition, panZoom])
//
//     return (<Box
//         width={width}
//         height={height}
//         bgColor={"white"}
//     >
//         {/*<Box>Side hovered: {`gutterId: ${pointMap[gridHovered].side.gutterId}, bagId: ${pointMap[gridHovered].side.bagId}, sideId: ${pointMap[gridHovered].side.sideId}`}</Box>*/}
//         <Box>Side hovered: {gridHovered ? `gutterId: ${pointMap[gridHovered].side.gutterId}, bagId: ${pointMap[gridHovered].side.bagId}, sideId: ${pointMap[gridHovered].side.sideId}` : "nothing hovered"}</Box>
//
//         <canvas
//             id={canvasId}
//             width={width}
//             height={height}
//             style={{border: "1px solid #d3d3d3"}}
//         >
//             Your browser does not support the HTML canvas tag.
//         </canvas>
//     </Box>)
// }
//
// const drawGreenhouse = (ctx: CanvasRenderingContext2D, points: GHSide[], panZoom: PanZoom = {
//     scale: 1.0,
//     x: 0,
//     y: 0
// }) => {
//     drawGrid(ctx)
//     drawPoints(ctx, points, panZoom)
// }
//
// const redrawGreenhouse = (ctx: CanvasRenderingContext2D, points: GHSide[], canvasDimension: Dimension, panZoom: PanZoom, mouseCoordinate: Position = {
//     x: 0,
//     y: 0
// },) => {
//     ctx.setTransform(1, 0, 0, 1, 0, 0);
//     ctx.globalAlpha = 1;
//     ctx.clearRect(0, 0, canvasDimension.width, canvasDimension.height);
//     ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
//     drawGrid(ctx)
//     ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
//     drawPoints(ctx, points, panZoom, mouseCoordinate);
// }
//
// function calculateGrid(mouseCoordinate: Position, panZoom: PanZoom): Position {
//     const x = Math.floor((mouseCoordinate.x - panZoom.x) / panZoom.scale / gridScale)
//     const y = Math.floor((mouseCoordinate.y - panZoom.y) / panZoom.scale / gridScale)
//     return {x, y}
// }
//
// function drawGrid(ctx: CanvasRenderingContext2D) {
//     var x = 0, y = 0;
//
//     const sizeX = gridScale * gridX
//     const sizeY = gridScale * gridY
//
//     ctx.lineWidth = 1;
//     ctx.strokeStyle = "#D3D3D3";
//     ctx.beginPath();
//     for (let i = 0; i < sizeX + 1; i += gridScale) {
//         ctx.moveTo(x + i, y);
//         ctx.lineTo(x + i, y + sizeY);
//     }
//     for (let i = 0; i < sizeY + 1; i += gridScale) {
//         ctx.moveTo(x, y + i);
//         ctx.lineTo(x + sizeX, y + i);
//
//     }
//     ctx.setTransform(1, 0, 0, 1, 0, 0); // reset the transform so the lineWidth is 1
//     ctx.stroke();
// }
//
// function drawPoints(ctx: CanvasRenderingContext2D, points: GHSide[], panZoom: PanZoom, mouseCoordinate: Position = {
//     x: 0,
//     y: 0
// }) {
//     ctx.lineWidth = 1;
//     const mouseGridPosition: Position = calculateGrid(mouseCoordinate, panZoom)
//     points.forEach(point => {
//         if (mouseGridPosition.x === point.x && mouseGridPosition.y === point.y) {
//             ctx.fillStyle = point.hoverColor;
//         } else {
//             ctx.fillStyle = point.color;
//         }
//
//         ctx.fillRect(point.x * gridScale, point.y * gridScale, 16, 16);
//     })
// }