import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import DetailsPage from "./pages/Details";
import {OverviewPage} from "./pages/Overview";
import {PlanningPage} from "./pages/Planning";
import {RecentOrdersPage} from "./pages/RecentOrders";
import {SalesPage} from "./pages/Sales";
import {InventoryPage} from "./pages/Inventory";
import {SiteOverviewPage} from "./pages/SiteOverview";
import {SiteReportPage} from "./pages/SiteReport";
import NotFoundPage from "./pages/NotFound";
import {WorkOrderTest} from "./pages/WorkOrderTest";
import {WorkOrderRoutines} from "./pages/WorkOrderRoutines";
import {WorkOrderOverview} from "./pages/WorkOrderOverview";
import {LaborLogPage} from "./pages/LaborLogPage";

interface Props {
    auth: firebase.auth.Auth;
}

const routes = ({auth}: Props) => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/site_overview/oak1"/>}/>
            <Route path="/site_overview/:siteId" element={<SiteOverviewPage/>}/>
            <Route path="/report/:siteId/" element={<SiteReportPage/>}/>
            <Route path="/report/:siteId/labor_report" element={<SiteReportPage/>}/>
            <Route path="/report/:siteId/:exp" element={<SiteReportPage/>}/>
            <Route path="/work_order_overview" element={<WorkOrderOverview/>}/>
            <Route path="/work_order_routines" element={<WorkOrderRoutines/>}/>
            <Route path="/overview" element={<OverviewPage auth={auth}/>}/>
            <Route path="/planning" element={<PlanningPage auth={auth}/>}/>
            <Route path="/recent_orders" element={<RecentOrdersPage auth={auth}/>}/>
            <Route path="/inventory" element={<InventoryPage auth={auth}/>}/>
            <Route path="/sales" element={<SalesPage auth={auth}/>}/>
            <Route path="/details" element={<DetailsPage/>}/>
            <Route path="/labor-log" element={<LaborLogPage/>}/>
            <Route path="/test"
                   element={
                       <WorkOrderTest/>
                   }
            />
            <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
    )
}

export default routes;
