import React, {useEffect, useState} from "react";
import {GreenhouseSide} from "../../../types/legacy/HeatmapLayout";
import {SideProvider, useSideContext} from "../../../contexts/SideProvider";
import {SideDetail} from "../../../types/SideDetail";
import {Box, HStack, Skeleton, Table, Tbody, Td, Text, Tr, useDisclosure, VStack,} from "@chakra-ui/react";
import {BoundingBox} from "../BoundingBox";
import {ImageModal} from "../../../pages/ImageModal";

type DetailViewProps = {
    selected: GreenhouseSide | undefined,
    overviewValue: GreenhouseSide | null
};
export const DetailViewWithGutterSide = ({selected, overviewValue}: DetailViewProps) => {
    if (selected) {
        // Show Side detail
        return (
            <SideProvider>
                <SideView selected={selected}/>
            </SideProvider>
        );
    } else {
        // Show greenhouse stats
        return <GreenhouseOverallView overviewValue={overviewValue}/>;
    }
};

type GreenhouseOverallViewProps = {
    overviewValue: GreenhouseSide | null
}

const GreenhouseOverallView = ({overviewValue}: GreenhouseOverallViewProps) => {
    const metricList: [string, string][] = [
        ["flower", "Flower"],
        ["white_fruit", "White fruit"],
        ["red_fruit", "Red fruit"],
        ["pink_fruit", "Pink fruit"],
        ["baby_fruit", "Young fruit"],
    ]

    const stats: string[][] = metricList.map(metric => {
        if (overviewValue) {
            return [metric[1], String(overviewValue.values[metric[0]])]
        } else {
            return [metric[1], "N/A"]
        }
    })

    return (
        <VStack w={"100%"} justifyContent={"left"}>
            <TitleView title={"Upper Greenhouse"}></TitleView>
            <DataView data={stats}/>
        </VStack>
    );
};

type TitleViewProps = {
    title: string;
};

const TitleView = ({title}: TitleViewProps) => {
    return (
        <HStack w={"100%"} justifyContent={"left"} p={"25px"}>
            <Text fontSize="20px" fontWeight={400} fontFamily={"Poppins"}>
                {title}
            </Text>
        </HStack>
    );
};

type DataViewProps = {
    data: string[][];
};

const DataView = ({data}: DataViewProps) => {
    return (
        <Table w={"90%"} variant="simple" fontFamily={"Poppins"}>
            <Tbody>
                {data.map((sampleStat, i) => {
                    return (
                        <Tr key={`data-view-tr-${i}`} borderBottom={"2px solid #C4C4C4"}>
                            <Td>{sampleStat[0]}</Td>
                            <Td isNumeric>{sampleStat[1]}</Td>
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
};

type SideViewProps = {
    selected: GreenhouseSide;
};

const SideView = ({selected}: SideViewProps) => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const sideContext = useSideContext();
    const [sideDetail, setSideDetail] = useState<SideDetail | undefined>(
        undefined
    );

    useEffect(() => {
        sideContext
            .getSideDetail(selected)
            .then((sideDetail) => setSideDetail(sideDetail));
    }, [sideContext, selected]);

    const metricList: [string, string][] = [
        ["flower", "Flower"],
        ["white_fruit", "White fruit"],
        ["red_fruit", "Red fruit"],
        ["pink_fruit", "Pink fruit"],
        ["baby_fruit", "Young fruit"],
    ]

    let stats: string[][] = metricList.map(metric => {
        return [metric[1], String(selected.values[metric[0]])]
    })

    const fixedData = [
        ["Canopy Height", "33cm"],
        ["Pest issue", "Aphids"],
        ["Next Harvest", "today"],
        ["Next Pruning", "in 5 days"],
    ]

    stats = [...stats, ...fixedData]

    return sideDetail ? (
        <VStack w={"100%"} justifyContent={"left"} id={"GreenHouseDetailView"}>
            <TitleView title={sideDetail.zordiId}></TitleView>
            <DataView data={stats}/>

            <HStack
                w={"100%"}
                justifyContent={"left"}
                paddingLeft={"20px"}
                paddingTop={"20px"}
            >
                <Text fontFamily={"Poppins"}>Recent Photo</Text>
            </HStack>

            <Box p={"20px"} minW={"500px"} onClick={onOpen}>
                <BoundingBox
                    elementId={"sideDetailCanvas"}
                    img={sideDetail.imageUrl}
                    boundingBoxes={sideDetail.detectionResults}
                ></BoundingBox>
            </Box>
            <ImageModal isOpen={isOpen} onClose={onClose} imageUrl={sideDetail.imageUrl}
                        boundingBoxes={sideDetail.detectionResults}></ImageModal>
        </VStack>
    ) : (
        <Skeleton height="700px"/>
    );
};
