import {Category, WorkOrder} from "@zordi/zordi_object_schema";
import {SortedWorkOrders} from '../components/report/LaborReport';
import {LaborReportData, workOrderCountToRate} from "../lib/labels/LaborReport";
import {dateToSnapshot, sortDescending} from './date';

enum headGrowerActions {
    inPerson = "[Head Grower] Scouting (In Person)",
    remote = "[Head Grower] Scouting (Remote)"
}

const checkHeadGrowerTaskType = (category: string, action: string) => {
    let newCategory = category;
    if (category === Category.HEAD_GROWER_TASKS) {
        switch (action) {
            case headGrowerActions.remote:
                newCategory = LaborReportData.HEAD_GROWER_TASKS_REMOTE.workType
                break;
            case headGrowerActions.inPerson:
                newCategory = LaborReportData.HEAD_GROWER_TASKS_ONSITE.workType;
                break;
            default:
                newCategory = LaborReportData.HEAD_GROWER_TASKS_OTHER.workType;
                break;
        }
    }

    return newCategory;
}

export const sortWorkOrderHelper = (workOrders: WorkOrder[], today: string) => {
    const tempWorkMap: SortedWorkOrders = {};

    const setupCategories = (date: string) => {
        let setup = {
            total: {
                hours: 0,
                workOrderCount: {
                    completedWorkOrderCount: 0,
                    totalWorkOrderCount: 0
                }
            }
        };
        Object.keys(LaborReportData).forEach((tableCategory: string) => {
            const key = LaborReportData[tableCategory].workType;
            setup = {
                ...setup, [key]: {
                    hours: 0,
                    workOrderCount: {
                        completedWorkOrderCount: 0,
                        totalWorkOrderCount: 0
                    }
                }
            };
        })
        tempWorkMap[date] = setup;
    };

    setupCategories(today);

    workOrders
        .filter(workOrder => workOrder.category !== undefined)
        .forEach((workOrder: WorkOrder) => {
            const {targetDate, laborHistory, category, action} = workOrder;
            if (!tempWorkMap[targetDate]) {
                setupCategories(targetDate);
            }

            let checkedCategory = checkHeadGrowerTaskType(category, action.action);
            tempWorkMap[targetDate].total.workOrderCount.completedWorkOrderCount +=
                workOrder.percentageCompleted ? workOrder.percentageCompleted : 0;
            tempWorkMap[targetDate].total.workOrderCount.totalWorkOrderCount += 1;
            tempWorkMap[targetDate][checkedCategory].workOrderCount.completedWorkOrderCount +=
                workOrder.percentageCompleted ? workOrder.percentageCompleted : 0;
            tempWorkMap[workOrder.targetDate][checkedCategory].workOrderCount.totalWorkOrderCount += 1;

            if (!laborHistory) return;

            Object.keys(laborHistory).forEach(date => {
                const hours = (laborHistory[date]) / 60;

                if (!tempWorkMap[date]) {
                    setupCategories(date);
                }

                tempWorkMap[date].total.hours += hours;
                tempWorkMap[date][checkedCategory].hours += hours;
            })
        });
    const workDates = sortDescending(Object.keys(tempWorkMap)).filter(date => {
        const dA = new Date(date);
        return dateToSnapshot(dA) > "2022-10-02";
    });

    const tempTableData = calculateTableDataHelper(today, workDates, tempWorkMap)

    return {tempWorkMap, workDates, tempTableData}
}

export type TableData = {
    [category: string]: {
        latestWork: number,
        latestWorkCompleted: string,
        todaysWork: number,
        todaysWorkCompleted: string
        averageHours: number,
        historicalWorkCompleted: string,
        thisWeekTotal: number,
        thisWeekWorkCompleted: string,
        targetHours: number | string,
        checkTargetMet: string,
        unit: string,
        actualAverage: number,
    }
}

export const calculateTableDataHelper = (today: string, workDates: string[], workOrderMapByDate: SortedWorkOrders) => {
    let tableData: TableData = {};
    const total = {
        dailyTotal: 0,
        weeklyTotal: 0,
        actualWeekTotal: 0,
        combinedTasksTotal: 0,
        thisWeekWorkCount: {
            completedWorkOrderCount: 0,
            totalWorkOrderCount: 0
        },
        historicalWorkCount: {
            completedWorkOrderCount: 0,
            totalWorkOrderCount: 0
        },
        combinedWorkCount: {
            completedWorkOrderCount: 0,
            totalWorkOrderCount: 0
        }
    };
    const latestDate: string = (workDates[0] === today) ? workDates[1] : workDates[0];

    Object.keys(LaborReportData).forEach((workTypeKey: string) => {
        const workTypeData = LaborReportData[workTypeKey];
        const workType = workTypeData.workType;
        const {thisWeekTotal, thisWeekWorkOrderCount} = getThisWeekTotal(workType, workDates, workOrderMapByDate);
        const {dailyAverage, weeklyAverage, historicalWorkOrderCount} = averagesByWeekday(workType, workDates,
            workOrderMapByDate);

        total.dailyTotal += dailyAverage;
        total.weeklyTotal += thisWeekTotal;
        total.actualWeekTotal += weeklyAverage;
        total.historicalWorkCount.completedWorkOrderCount += historicalWorkOrderCount.completedWorkOrderCount
        total.historicalWorkCount.totalWorkOrderCount += historicalWorkOrderCount.totalWorkOrderCount
        total.thisWeekWorkCount.completedWorkOrderCount += thisWeekWorkOrderCount.completedWorkOrderCount
        total.thisWeekWorkCount.totalWorkOrderCount += thisWeekWorkOrderCount.totalWorkOrderCount

        if (CombinedTasks.includes(workType)) {
            total.combinedTasksTotal += (weeklyAverage);
            total.combinedWorkCount.completedWorkOrderCount += historicalWorkOrderCount.completedWorkOrderCount
            total.combinedWorkCount.totalWorkOrderCount += historicalWorkOrderCount.totalWorkOrderCount
        }

        tableData[workType] = {
            latestWork: latestDate ? workOrderMapByDate[latestDate][workType].hours : 0,
            latestWorkCompleted: latestDate ? workOrderCountToRate(
                workOrderMapByDate[latestDate][workType].workOrderCount) : "(-)",
            todaysWork: workOrderMapByDate[today][workType].hours,
            todaysWorkCompleted: workOrderCountToRate(workOrderMapByDate[today][workType].workOrderCount),
            averageHours: dailyAverage,
            historicalWorkCompleted: workOrderCountToRate(historicalWorkOrderCount),
            thisWeekTotal: thisWeekTotal,
            thisWeekWorkCompleted: workOrderCountToRate(thisWeekWorkOrderCount),
            targetHours: workTypeData.targetHours,
            checkTargetMet: workTypeData.targetHours === "N/A" ? '' : weeklyAverage <= parseInt(
                workTypeData.targetHours) ? 'green' : 'red',
            unit: workTypeData.unit,
            actualAverage: weeklyAverage,
        }
    });
    return {tableData, total};
}


const getThisWeekDates = (workDates: string[]) => {
    const startOfTheWeek = new Date();
    let today = new Date();
    today = new Date();
    const dayToday = today.getDay();
    startOfTheWeek.setDate(startOfTheWeek.getDate() - dayToday);
    return workDates.filter(date => {
        const startDate = dateToSnapshot(startOfTheWeek);
        const endDate = dateToSnapshot(today);
        const woDate = dateToSnapshot(new Date(date));
        return (woDate >= startDate && woDate <= endDate);
    })
}

const getThisWeekTotal = (workOrderType: string, workDates: string[], workOrderMapByDate: SortedWorkOrders) => {
    const workDatesThisWeek: string[] = getThisWeekDates(workDates.slice(0, 7));
    let allHours = 0;
    let completedWorkOrderCount = 0;
    let totalWorkOrderCount = 0;
    workDatesThisWeek.forEach((date: string) => {
        const workOrder = workOrderMapByDate[date as keyof typeof workOrderMapByDate][workOrderType];
        allHours += workOrder.hours;
        completedWorkOrderCount += workOrder.workOrderCount.completedWorkOrderCount
        totalWorkOrderCount += workOrder.workOrderCount.totalWorkOrderCount
    })
    return {
        thisWeekTotal: allHours,
        thisWeekWorkOrderCount: {
            completedWorkOrderCount,
            totalWorkOrderCount
        }
    };
}

const sundayDates = (startDate = "2022-10-03") => {
    const today = new Date();
    let sunday = new Date(startDate);
    let sundays = [];
    while (sunday <= today) {
        const newSunday = new Date(sunday);
        sundays.push(newSunday);
        sunday.setDate(sunday.getDate() + 7);
    }
    return sundays;
}

export const CombinedTasks = [LaborReportData.CROP_MONITORING.workType, LaborReportData.PRUNING_AND_SHAPING.workType,
    LaborReportData.IPM.workType, LaborReportData.GREENHOUSE_CONTROL.workType];

const averagesByWeekday = (workType: string, workDates: string[], workOrderMapByDate: SortedWorkOrders) => {
    const weeklyAverages: number[] = [];
    const dailyAverages: number[] = [];
    const sunday = sundayDates();
    let completedWorkOrderCount = 0;
    let totalWorkOrderCount = 0;
    sunday.forEach(startDate => {
        const thisWeekDates = workDates.filter(date => {
            const dA = new Date(date);
            const dB = new Date(startDate);
            const dC = new Date(startDate);
            dC.setDate(dB.getDate() + 6);

            return dA.getTime() >= dB.getTime() && dA.getTime() <= dC.getTime();
        })

        let thisWeekHours = 0;
        thisWeekDates.forEach(date => {
            thisWeekHours += workOrderMapByDate[date][workType].hours
            totalWorkOrderCount += workOrderMapByDate[date][workType].workOrderCount.totalWorkOrderCount
            completedWorkOrderCount += workOrderMapByDate[date][workType].workOrderCount.completedWorkOrderCount
        })


        if (thisWeekDates.length > 0) {
            weeklyAverages.push(thisWeekHours);
            dailyAverages.push(thisWeekHours / 5)
        } else {
            weeklyAverages.push(0);
            dailyAverages.push(0);
        }
    })

    let bayCountedWeekAve = weeklyAverages.reduce((a, b) => a + b);
    if (workType === LaborReportData.HEAD_GROWER_TASKS_REMOTE.workType || workType === LaborReportData.HEAD_GROWER_TASKS_ONSITE.workType) {
    } else {
        bayCountedWeekAve = bayCountedWeekAve / 2;
    }

    return {
        dailyAverage: dailyAverages.reduce((a, b) => a + b) / dailyAverages.length,
        weeklyAverage: bayCountedWeekAve / weeklyAverages.length,
        historicalWorkOrderCount: {
            totalWorkOrderCount,
            completedWorkOrderCount
        }
    };
}
