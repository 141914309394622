export const Metrics = {
    TEMPERATURE: {
        id: "Celsius_TemperatureData",
        label: "Temperature",
        colors: ["#f2d58d", "#f2cd6f", "#edbd45"],
        unit: "\u00b0C",
    },
    HUMIDITY: {
        id: "Humidity_Percentage",
        label: "Humidity",
        colors: ["#66e3d0", "#4acfbb", "#09ad9a"],
        unit: "%",
    },
    DLI: {
        id: "PAR_PAR_DLI",
        label: "DLI",
        colors: ["#e8c1a0", "#f0b584", "#f0974d"],
        unit: "mol/m²/day",
    },
    PPFD: {
        id: "PAR_PPFD",
        label: "PPFD",
        colors: ["#fa8470", "#eb6049", "#f25035"],
        unit: "µmol/m2/s",
    }
}

export const MetricsById = {
    "Celsius_TemperatureData": "TEMPERATURE",
    "Humidity_Percentage": "HUMIDITY",
    "PAR_PAR_DLI": "DLI",
    "PAR_PPFD": "PPFD"
}

export const MetricsByValue = (value:any) => {
    const {TEMPERATURE, HUMIDITY, DLI, PPFD} = Metrics
    return [TEMPERATURE, HUMIDITY, DLI, PPFD].filter(sensor => Object.values(sensor).includes(value))
}
