import React, {createContext, useContext} from "react";
import {adminCreateWorkOrderProcessing} from "../api/admin";

export interface AdminState {
    createWorkOrderProcessing: (snapshotDate: string, siteId: string) => Promise<void>;
}

const AdminContext = createContext<AdminState | null>(null);

type AdminProviderProps = {
    children?: React.ReactNode;
}

const AdminProvider: React.FC<AdminProviderProps> = ({children}) => {
    const createWorkOrderProcessing = (snapshotDate: string, siteId: string): Promise<void> => {
        return adminCreateWorkOrderProcessing(snapshotDate, siteId);
    }

    return (
        <AdminContext.Provider value={{createWorkOrderProcessing}}>{children}</AdminContext.Provider>
    );
};

const useAdminContext = () => {
    const context = useContext(AdminContext);

    if (!context) {
        throw new Error("Unable to use useOrderContext without OrderProvider");
    }

    return context;
};

export {useAdminContext, AdminProvider};
