import {HeatmapProvider, useHeatmapContext} from "../../contexts/HeatmapProvider";
import React, {MutableRefObject, useEffect, useState} from "react";
import {ZordiHeatMap} from "./ZordiHeatMap";
import {Title} from "../typography/title";
import {Flex, HStack, Select} from "@chakra-ui/react";
import {
    HeatMapCell,
    HeatMapCellRow,
    HeatMapCellType,
    SiteData,
    unitLocationToLocationId
} from "../../data/GreenhouseDataTypes";
import {getActivePlantMetric, metricValueRetriever} from "./activeMetric";
import {MetricSensor} from "../../pages/SiteOverview";
import {SiteIdToLayout} from "../../data/LayoutMapping";
import {useSearchParams} from "react-router-dom";
import {setUrlParam} from "../../utils/url";

export const HeatmapModule = (
    {
        snapshotDate,
        scrollToMetricSensor,
        heatmapRef,
        siteId,
    }: {
        snapshotDate: string, scrollToMetricSensor: MetricSensor | undefined, heatmapRef: MutableRefObject<any>,
        siteId: string
    }) => {
    return (
        <HeatmapProvider>
            <Heatmap snapshotDate={snapshotDate}
                     scrollToMetricSensor={scrollToMetricSensor}
                     heatmapRef={heatmapRef}
                     siteId={siteId}
            ></Heatmap>
        </HeatmapProvider>
    )
}

const Heatmap = ({
                     siteId,
                     snapshotDate,
                     scrollToMetricSensor,
                     heatmapRef,
                 }: {
    siteId: string,
    snapshotDate: string,
    scrollToMetricSensor: MetricSensor | undefined,
    heatmapRef: MutableRefObject<any>
}) => {
    const heatmapContext = useHeatmapContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const dev: boolean = searchParams.get("dev") === "true"

    const getMetricParam = () => {
        const metric = searchParams.get("metric")
        if (metric && getActivePlantMetric(dev).includes(metric)) {
            return metric
        } else {
            return "height"
        }
    }

    const [selectedMetric, setSelectedMetric] = useState<string>(getMetricParam());
    const [siteData, setSiteData] = useState<SiteData>({plants: {}, regions: {}});
    const [sensorToLocationMap, setSensorToLocationMap] = useState<{ [key: string]: string }>({});
    const [layout, setLayout] = useState<HeatMapCellRow[]>(SiteIdToLayout[siteId]);
    const [loading, setLoading] = useState<boolean>(true)

    const setMetricAndUrl = (metric: string) => {
        setSelectedMetric(metric)
        setUrlParam("metric", metric, searchParams, setSearchParams)
    }

    useEffect(() => {
        setLayout(SiteIdToLayout[siteId])
        heatmapContext.getSiteData(snapshotDate, siteId).then((result) => {
            const sensorMap: { [key: string]: string } = {}
            for (const regionId in result.regions) {
                const region = result.regions[regionId]
                if (region.sensorId) {
                    sensorMap[region.sensorId] = unitLocationToLocationId(region.unitLocation)
                }
            }
            setSensorToLocationMap(sensorMap)
            setSiteData(result)
            setLoading(false)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshotDate, siteId]);

    useEffect(() => {
        if (scrollToMetricSensor) {
            setMetricAndUrl(scrollToMetricSensor.metric)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollToMetricSensor])

    useEffect(() => {
        const newLayout = layout.map((row: HeatMapCellRow) => {
            return {
                id: row.id,
                data: row.data.map((cell: HeatMapCell) => {
                        switch (cell.cellType) {
                            case HeatMapCellType.ROW_ID_HOLDER:
                            case HeatMapCellType.EMPTY_SPACE:
                                break;
                            case HeatMapCellType.HEAT_MAP_NO_DATA_CELL:
                            case HeatMapCellType.HEAT_MAP_DATA_CELL:
                                const plant = siteData.plants[cell.location]
                                if (plant && metricValueRetriever[selectedMetric](plant) !== undefined) {
                                    // Check if the plant has data of the selected metric
                                    cell.cellType = HeatMapCellType.HEAT_MAP_DATA_CELL
                                    cell.unitId = plant.plantId
                                    cell.y = metricValueRetriever[selectedMetric](plant)
                                } else {
                                    cell.cellType = HeatMapCellType.HEAT_MAP_NO_DATA_CELL
                                    cell.y = undefined
                                }
                                break;
                            default:
                                console.log(`unhandled cells`)
                        }

                        return cell
                    }
                )
            }
        })
        setLayout(newLayout)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteData, selectedMetric])

    return (
        <>
            <Flex ref={heatmapRef} w={'full'} justify={{base: "space-between"}}>
                <Title w='full' align="left" fontSize='xl'
                       fontWeight={700}
                       fontFamily={"Poppins"} text="Greenhouse Heatmap"></Title>
                <HStack w='300px'>
                    <Select
                        value={selectedMetric}
                        onChange={e => setMetricAndUrl(e.target.value)}
                        fontFamily={"Poppins"}
                    >
                        {
                            getActivePlantMetric(dev).map(metric => {
                                return (
                                    <option key={metric} value={metric}>{metric}</option>
                                )
                            })
                        }
                    </Select>

                </HStack>
            </Flex>
            {
                <ZordiHeatMap layout={layout} siteData={siteData} selectedMetric={selectedMetric}
                              loading={loading}
                              scrollToMetricSensor={scrollToMetricSensor}
                              sensorToLocationMap={sensorToLocationMap}
                ></ZordiHeatMap>
            }
        </>
    );
}


