import React, {createContext, useContext} from "react";
import {fetchHeatmap, fetchSignedUrl, fetchSiteData, fetchSignedUrls} from "../api/es";
import {HeatmapMap} from "../types/legacy/HeatmapResult";
import {SiteData} from "../data/GreenhouseDataTypes";

export interface HeatmapProviderState {
    getHeatmap: () => Promise<HeatmapMap>;
    getSiteData: (snapshotDate: string, site: string) => Promise<SiteData>;
    getSignedUrl: (bucket: string, s3path: string) => Promise<string>;
    getSignedUrls: (data: string) => Promise<string>;
}

const HeatmapContext = createContext<HeatmapProviderState | null>(null);

type HeatmapProviderProps = {
    children?: React.ReactNode;
}

const HeatmapProvider: React.FC<HeatmapProviderProps> = ({children}) => {
    const getHeatmap = (): Promise<HeatmapMap> => {
        return fetchHeatmap();
    }

    const getSiteData = (snapshotDate: string, site: string): Promise<SiteData> => {
        return fetchSiteData(snapshotDate, site);
    }

    const getSignedUrl = (bucket: string, s3path: string): Promise<string> => {
        return fetchSignedUrl(bucket, s3path)
    }

    const getSignedUrls = (data: string): Promise<string> => {
        return fetchSignedUrls(data)
    }

    return (
        <HeatmapContext.Provider
            value={{getHeatmap, getSiteData, getSignedUrl, getSignedUrls}}>{children}</HeatmapContext.Provider>
    );
};

const useHeatmapContext = () => {
    const context = useContext(HeatmapContext);

    if (!context) {
        throw new Error("Unable to use HeatmapContext without HeatmapProvider");
    }

    return context;
};

export {useHeatmapContext, HeatmapProvider};
