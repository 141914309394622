import { Text, TextProps } from "@chakra-ui/react";
import { titleStyle } from "../../ZordiChakraTheme";

export interface TitleProps extends TextProps {
  text: string;
}

export const Title = ({ text, ...rest }: TitleProps) => {
  return (
    <Text w="full" align="left" {...titleStyle} {...rest}>
      {text}
    </Text>
  );
};
