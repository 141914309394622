import {Serie} from "@nivo/line";
import React, {createContext, useContext} from "react";
import {fetchMonnitTimeSeries, fetchReportTimeSeries, fetchTimeSeriesChartJs, fetchTimeSeriesNivo} from "../api/es";
import {TimeseriesData} from "../types/TimeseriesData";
import {TimeseriesQuery} from "../types/TimeseriesQuery";

export interface TimeSeriesProviderState {
    getTimeseriesNivo: (startTime?: Date) => Promise<Serie[]>;
    getTimeseriesChartJs: (sectionName?: string) => Promise<TimeseriesData>;
    getMonnitTimeseries: () => Promise<TimeseriesData>
    getReportTimeseries: () => Promise<TimeseriesData>
}

const TimeseriesContext = createContext<TimeSeriesProviderState | null>(null);

type TimeSeriesProviderProps = {
    startTime: Date,
    endTime: Date,
    metricNames: string[],
    siteId: string,
    children?: React.ReactNode;
}

const TimeSeriesProvider:
        React.FC<TimeSeriesProviderProps> = ({
                                                 children,
                                                 startTime, endTime, metricNames,
                                                 siteId
                                             }) => {
        const interval: [string, number] = calculateInterval(startTime, endTime);

        let query: TimeseriesQuery = new TimeseriesQuery(
            startTime.toISOString(),
            endTime.toISOString(),
            metricNames,
            interval[0],
            siteId
        )

        const getTimeseriesNivo = (startTime: Date | undefined): Promise<Serie[]> => {
            if (!!startTime && query.startTime !== startTime.toISOString()) {
                query.startTime = startTime.toISOString()
            }
            return fetchTimeSeriesNivo(query);
        }

        const getTimeseriesChartJs = (sectionName?: string): Promise<TimeseriesData> => {
            return fetchTimeSeriesChartJs(query, interval[1], sectionName)
        }

        const getMonnitTimeseries = (): Promise<TimeseriesData> => {
            return fetchMonnitTimeSeries(query, interval[1])
        }

        const getReportTimeseries = (): Promise<TimeseriesData> => {
            return fetchReportTimeSeries(query, interval[1])
        }

        return (
            <TimeseriesContext.Provider
                value={{
                    getTimeseriesNivo,
                    getTimeseriesChartJs,
                    getMonnitTimeseries,
                    getReportTimeseries
                }}>{children}</TimeseriesContext.Provider>
        );
    }
;

const useTimeseriesContext = () => {
    const context = useContext(TimeseriesContext);

    if (!context) {
        throw new Error("Unable to use MonnitContext without MonnitProvider");
    }

    return context;
};

const calculateInterval = (startTime: Date, endTime: Date): [string, number] => {
    const intervalConfiguration: [number, [string, number]][] = [
        [60, ["1s", 1000]],
        [3600, ["1m", 60 * 1000]],
        [86400, ["30m", 30 * 60 * 1000]],
        [86400 * 10, ["1h", 1 * 60 * 60 * 1000]],
        [86400 * 90, ["24h", 24 * 60 * 60 * 1000]],
        [86400 * 365, ["7d", 7 * 86400 * 1000]],
        [86400 * 365 * 10, ["30d", 30 * 86400 * 1000]],
    ]
    const differenceSeconds: number = (endTime.getTime() - startTime.getTime()) / 1000;

    for (let i: number = 0; i < intervalConfiguration.length; i++) {
        let config = intervalConfiguration[i];
        if (config[0] >= differenceSeconds) {
            return config[1]
        }
    }

    return ["1y", 365 * 86400 * 1000]
}

export {useTimeseriesContext, TimeSeriesProvider};
