import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {PageData} from '../lib/datatypes/PageData';
import {CityData} from '../lib/datatypes/CityData';
import {findReportPage, findCityById} from '../utils/header_navigation_helpers';

const initialPath = window.location.pathname.split("/");
const city = findCityById(initialPath[2]);
const page = findReportPage(initialPath[3]);

export interface OverviewState {
    siteId: string | null;
    city: CityData;
    pageData: PageData;
    newWorkOrderSidebar: boolean;
}

const initialState: OverviewState = {
    siteId: initialPath[2],
    city: city,
    pageData: page,
    newWorkOrderSidebar: false,
}


export const PageSlice = createSlice({
    name: 'pageSlice',
    initialState,
    reducers: {
        toggleNewWorkOrderSidebar: (state, action:PayloadAction<boolean>) => {
            state.newWorkOrderSidebar = action.payload
        },
        updateCity: (state, action: PayloadAction<CityData>) => {
            state.city = action.payload;
            state.siteId = action.payload.id;
        },
        updatePage: (state, action: PayloadAction<PageData>) => {
            state.pageData = action.payload;
        },

    },
})

export const {toggleNewWorkOrderSidebar, updateCity, updatePage} = PageSlice.actions;

export const pageSliceReducer = PageSlice.reducer;
