import React, {useCallback, useEffect, useState} from 'react';
import {
    Button,
    ChakraProvider,
    FormControl,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useDisclosure,
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../utils/store";
import {convertEnum} from "../utils/transferObject";
import {getWorkOrderOptions, listWorkOrders, updateWorkOrderStatus} from "../redux/asyncThunk/workOrder";
import "../css/work_order_overview.css"
import {CurrentWorkOrderTable} from "../components/workorder/CurrentWorkOrderTable";
import {WorkOrderStatus} from "@zordi/zordi_object_schema/src/to/References";
import {Category, MDReadWorkOrderResponse, MDReadWorkOrderResponse as WorkOrder} from "@zordi/zordi_object_schema";
import {Alert} from "../components/common/Alert";
import {resetWorkOrder, setIdle} from "../redux/workOrderSlice";
import {FloatingSelect} from "../components/common/FloatingSelect";
import {theme} from "../components/workorder/NewWorkOrderSidebar";
import {DateRangePicker} from "../components/common/DateRangePicker";
import {
    FilterColumns,
    FilterDatesOptions,
    FilterEnumOptions,
    filterWorkOrders,
    initialFilters,
    regionFilterOptions
} from "../utils/work_order_filter";
import moment from "moment";

export const WorkOrderOverview = () => {
    const dispatch = useDispatch<AppDispatch>()
    const allWorkOrders = useSelector((state: RootState) => state.workOrder.workOrders)
    const options = useSelector((state: RootState) => state.workOrder.options)
    const [workOrders, setWorkOrders] = useState<MDReadWorkOrderResponse[]>(allWorkOrders)

    const [filters, setFilters] = useState(initialFilters)
    const [filterDates, setFilterDates] = useState<{ startDate: Date | null, endDate: Date | null }>({
        startDate: null,
        endDate: null
    })

    const [fetchRquested, setFetchRequested] = useState<boolean>(false)
    const fetchStatus = useSelector((state: RootState) => state.workOrder.status)
    const updatedWorkOrder = useSelector((state: RootState) => state.workOrder.updatedWorkOrder)
    const {isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose} = useDisclosure()
    const cancelRef = React.useRef()

    const [currentWorkOrders, setCurrentWorkOrders] = useState<MDReadWorkOrderResponse[]>([])
    const [currentGrowerTasks, setCurrentGrowerTasks] = useState<MDReadWorkOrderResponse[]>([])
    const [currentReviewerTasks, setCurrentReviewerTasks] = useState<MDReadWorkOrderResponse[]>([])

    const getWorkOrdersByStatusActive = useCallback(() => {
        dispatch(listWorkOrders({
            status: [WorkOrderStatus.ACTIVE]
        }))
    }, [dispatch])

    useEffect(() => {
        getWorkOrdersByStatusActive()
        dispatch(getWorkOrderOptions())
    }, [dispatch, getWorkOrdersByStatusActive])

    const getWorkOrdersByFilterDates = useCallback(() => {
        let request = {}
        const {startDate, endDate} = filterDates
        if (startDate !== null) {
            request = {...request, startDate}
        }
        if (endDate !== null) {
            request = {...request, endDate}
        }
        dispatch(listWorkOrders(request))
    }, [dispatch, filterDates])

    useEffect(() => {
        const {startDate, endDate} = filterDates
        if ((startDate === null && endDate === null && filters.date === "all_dates") || (startDate !== null && endDate !== null)) {
            getWorkOrdersByFilterDates()
        }
    }, [filterDates, getWorkOrdersByFilterDates, filters.date])

    useEffect(() => {
        const filteredWO: MDReadWorkOrderResponse[] = filterWorkOrders(allWorkOrders, filters)
        setWorkOrders(filteredWO)
    }, [filters, allWorkOrders])

    useEffect(() => {
        filterTables(workOrders)
    }, [workOrders])

    useEffect(() => {
        if (fetchStatus === "succeeded" && updatedWorkOrder !== null && fetchRquested) {
            onAlertOpen()
        } else if (fetchStatus === "failed" && fetchRquested) {
            onAlertOpen()
        }
    }, [fetchStatus, updatedWorkOrder, onAlertOpen, fetchRquested, dispatch])

    const filterTables = (workOrders: MDReadWorkOrderResponse[]) => {
        const current: MDReadWorkOrderResponse[] = [];
        const grower: MDReadWorkOrderResponse[] = [];
        const reviewer: MDReadWorkOrderResponse[] = [];
        workOrders.forEach((workorder: MDReadWorkOrderResponse) => {
            if (workorder.category.label === Category.HEAD_GROWER_TASKS) {
                grower.push(workorder)
            } else if (workorder.category.label === Category.REVIEWER_TASKS) {
                reviewer.push(workorder)
            } else {
                current.push(workorder)
            }
        })
        setCurrentWorkOrders(current)
        setCurrentReviewerTasks(reviewer)
        setCurrentGrowerTasks(grower)
    }

    const updateStatus = (id: string, status: WorkOrderStatus) => {
        dispatch(updateWorkOrderStatus({id, status}))
        setFetchRequested(true)
    }

    const handleAlertClose = () => {
        onAlertClose()
        getWorkOrdersByStatusActive()
        dispatch(resetWorkOrder())
        dispatch(setIdle())
        setFetchRequested(false)
    }

    const alertBody = (updatedWorkOrder: WorkOrder) => {
        return (
            <p>
                <br/>
                <span className={"label"}>Work order ID</span><br/>
                {updatedWorkOrder.workOrderId}
                <br/> <br/>
                <span className={"label"}>Updated work order status</span><br/>
                {convertEnum(updatedWorkOrder.status)}
            </p>
        )
    }

    const handleFilterOnChange = async (column: FilterColumns, value: any) => {
        setFilters({...filters, [column]: value})
        if (column === "date") {
            handleDateChange(value)
        }
    }

    const handleDateChange = (type: string, customizedStartDate: Date | null = null, customizedEndDate: Date | null = null) => {
        let today = moment().startOf("day").toDate()
        let startDate = customizedStartDate
        let endDate = customizedEndDate
        switch (type) {
            case "today":
                startDate = today
                endDate = moment().endOf("day").toDate()
                break
            case "past_week":
                endDate = moment().endOf("day").toDate()
                startDate = moment().startOf("day").subtract(7, 'd').toDate()
                break
            default:
                break
        }
        setFilterDates({startDate, endDate})
    }

    const convenienceBtnOnClick = {
        allActive: () => {
            setFilters({...initialFilters})
            setFilterDates({startDate: null, endDate: null})
        },
        canceledPastWeek: () => {
            setFilters({...initialFilters, status: WorkOrderStatus.CANCELLED})
            handleDateChange("past_week")
        },
    }

    return (
        <>
            <Alert status={fetchStatus} isOpen={isAlertOpen} onClose={handleAlertClose}
                   body={!!updatedWorkOrder ? alertBody(updatedWorkOrder) : null} cancelRef={cancelRef}
                   header={"Work Order Status Update"}/>
            <div className="wo-table-filter-container">
                <div className={"filter-container"}>
                    <ChakraProvider theme={theme}>
                        <FormControl variant="floating">
                            <FloatingSelect selectLabel={"Date"} onChange={(val) => handleFilterOnChange("date", val)}
                                            options={FilterDatesOptions} state={filters.date}/>
                        </FormControl>
                        <DateRangePicker date={filterDates} setDate={handleDateChange}
                                         isDisabled={filters.date !== "customize"}/>
                        <FormControl variant="floating">
                            <FloatingSelect selectLabel={"Status"}
                                            onChange={(val) => handleFilterOnChange("status", val)}
                                            options={[{label: "All", value: "all"}, ...FilterEnumOptions.status]}
                                            state={filters.status}/>
                        </FormControl>
                        <FormControl variant="floating">
                            <FloatingSelect selectLabel={"Region"}
                                            onChange={(val) => handleFilterOnChange("region", val)}
                                            options={regionFilterOptions(options)}
                                            state={filters.region}/>
                        </FormControl>
                        <FormControl variant="floating">
                            <FloatingSelect selectLabel={"Priority"}
                                            onChange={(val) => handleFilterOnChange("priority", val)}
                                            options={[{label: "All", value: "all"}, ...FilterEnumOptions.priority]}
                                            state={filters.priority}/>
                        </FormControl>
                        <FormControl variant="floating">
                            <FloatingSelect selectLabel={"Assignee"}
                                            onChange={(val) => handleFilterOnChange("assignee", val)}
                                            options={[{label: "All", value: "all"}, ...options.worker]}
                                            state={filters.assignee}/>
                        </FormControl>
                    </ChakraProvider>
                </div>
                <div className={"filter-btn-container"}>
                    <Button className={"filter-btn"} onClick={convenienceBtnOnClick.allActive}>All Active (default)</Button>
                    <Button className={"filter-btn"} onClick={convenienceBtnOnClick.canceledPastWeek}>Canceled within 7
                        days</Button>
                </div>
            </div>
            <Tabs style={{width: "98%"}}>
                <TabList>
                    <Tab>Current Tasks</Tab>
                    <Tab>Growers Tasks</Tab>
                    <Tab>Reviewers Tasks</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <CurrentWorkOrderTable unorderedWorkOrders={currentWorkOrders} updateStatus={updateStatus}/>
                    </TabPanel>
                    <TabPanel>
                        <CurrentWorkOrderTable unorderedWorkOrders={currentGrowerTasks} updateStatus={updateStatus}/>
                    </TabPanel>
                    <TabPanel>
                        <CurrentWorkOrderTable unorderedWorkOrders={currentReviewerTasks} updateStatus={updateStatus}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}
