import {WorkOrder} from "@zordi/zordi_object_schema";
import {Divider, Grid, GridItem, Stack, Text} from "@chakra-ui/react";
import React from "react";
import {FiCheckCircle, FiXCircle} from "react-icons/fi";
import '../../css/site_report.css';

type WorkOrderCellProps = {
    workOrder: WorkOrder | undefined
}

export const ConcernActionCell = ({workOrder}: WorkOrderCellProps) => {
    if (workOrder === undefined) {
        return (<span></span>)
    }
    return (
        <Grid
            w={"full"}
            templateColumns='repeat(6, 1fr)'
        >
            <GridItem colSpan={1}>
                <Stack direction='row'>
                    <Text><b>Concern:</b></Text>
                </Stack>
            </GridItem>
            <GridItem colSpan={4}>
                <Stack as={"span"} direction='row'>
                    {
                        workOrder.concernValid === undefined ?
                            "" :
                            workOrder.concernValid ?
                                <span style={{padding: "2px 0 2px 0"}}><FiCheckCircle className="inline" color="green"/></span>
                                : <span style={{padding: "2px 0 2px 0"}}><FiXCircle className="inline"
                                                                                    color="red"/></span>
                    }
                    <span>{workOrder.problem.description}</span>
                </Stack>
            </GridItem>
            <GridItem colSpan={1}>
            </GridItem>
            <GridItem colSpan={6}>
                <Divider/>
            </GridItem>

            <GridItem colSpan={1}>
                <Stack direction='row'>
                    <Text><b>Action:</b></Text>
                </Stack>
            </GridItem>
            <GridItem colSpan={4}>
                <Stack as={"span"} direction='row'>
                    {
                        workOrder.actionValid === undefined ?
                            "" :
                            workOrder.actionValid ?
                                <span style={{padding: "2px 0 2px 0"}}><FiCheckCircle className="inline" color="green"/></span>
                                : <span style={{padding: "2px 0 2px 0"}}><FiXCircle className="inline"
                                                                                    color="red"/></span>
                    }
                    <span>{workOrder.action.action}</span>
                </Stack>
            </GridItem>
        </Grid>
    )
}
