export const statusToColor: { [key: string]: string } = {
    "Normal": "#70AD47",
    "Completed": "#70AD47",
    "Warning": "#FF6B00",
    "Needs Review": "#FF6B00",
    "Started": "#3488D4",
    "Distributed": "#C4C4C4",
}

export const defaultStatusColor = "#C4C4C4";

export const getStatusColor = (key: string) => {
    return statusToColor[key] || defaultStatusColor
}