import {createAsyncThunk} from '@reduxjs/toolkit';
import {get, post} from "../../api/baseRequests";
import {MDCreateWorkOrderRequest, MDListWorkOrdersRequest} from "@zordi/zordi_object_schema"
import {WorkOrderStatus} from "@zordi/zordi_object_schema/src/to/References";

interface UpdateObject {
    id: string,
    date: string,
    fieldsToUpdate: { [key: string]: any }
}

export const createWorkOrder = createAsyncThunk('/workorder/create',
    async (workOrderData: MDCreateWorkOrderRequest) => {
        return post('workorder/create', {
            workOrderData
        });
    })

export const listWorkOrders = createAsyncThunk('/workorder/list',
    async (request: MDListWorkOrdersRequest) => {
        const params = new URLSearchParams()
        // TODO: find a more generic way to convert to query parameters
        if (request.status) {
            request.status.forEach(status => params.append("status", status))
        }
        if (request.siteCode) {
            params.append("siteCode", request.siteCode)
        }
        if (request.startDate) {
            params.append("startDate", request.startDate)
        }
        if (request.endDate) {
            params.append("endDate", request.endDate)
        }
        return get('workorder/list', params)
    })

export const getWorkOrderOptions = createAsyncThunk('/workorder/options', async () => {
    return get('workorder/options');
})

//dynamo update
export const updateWorkOrder = createAsyncThunk('/workorder/update', async (
    workOrderData: UpdateObject
) => {
    const {fieldsToUpdate} = workOrderData
    return post('', {
        workOrderId: workOrderData.id,
        ...fieldsToUpdate
    });
})

//postgres update
export const updateWorkOrderStatus = createAsyncThunk('/workorder/updateWorkOrder', async (
    workOrderData: { id: string, status: WorkOrderStatus }
) => {
    console.log("updatewostatus thunk")
    const {id, status} = workOrderData
    return post('workorder/updateWorkOrder', {
        workOrderId: id, status
    });
})
