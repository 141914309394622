import React, {useCallback, useEffect} from "react";
import "./WorkOrderTable.css"
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../utils/store";
import {CurrentWorkOrderTable} from "../../components/workorder/CurrentWorkOrderTable";
import {WorkOrderStatus} from "@zordi/zordi_object_schema/src/to/References";
import {listWorkOrders} from "../../redux/asyncThunk/workOrder";
import {dateToSnapshot} from "../../utils/date";

type WorkOrderTableProps = {
    siteId: string
    startTime: Date
    endTime: Date
}

export const WorkOrderTable = ({siteId, startTime, endTime}: WorkOrderTableProps) => {
    const workOrders = useSelector((state: RootState) => state.workOrder.workOrders)
    const dispatch = useDispatch<AppDispatch>()

    const getWorkOrders = useCallback(() => {
        const status: WorkOrderStatus[] = ["scheduled" as WorkOrderStatus, "active" as WorkOrderStatus]
        dispatch(listWorkOrders({
            status,
            startDate: dateToSnapshot(startTime),
            endDate: dateToSnapshot(endTime),
            siteCode: siteId
        }))
    }, [dispatch, startTime, endTime, siteId])

    useEffect(() => {
        getWorkOrders()
    }, [getWorkOrders])

    return (
        <CurrentWorkOrderTable unorderedWorkOrders={workOrders} updateStatus={null}/>
    )
}
