import React, {Dispatch, Fragment, SetStateAction, useEffect, useState,} from 'react';
import {
    Box,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure
} from '@chakra-ui/react';
import {ZordiPopoverTrigger as PopoverTrigger} from "../common/ZordiPopeverTrigger";
import {MDReadWorkOrderRoutineResponse, MDReadWorkOrderRoutineResponse as Routine} from "@zordi/zordi_object_schema";
import {BsCaretDownFill, BsCheckSquare, BsFillCaretUpFill, BsSquare} from "react-icons/bs";
import {FiEdit} from "react-icons/fi";
import {RiDeleteBin5Line, RiPencilRulerLine} from "react-icons/ri";
import {GrDocumentNotes} from "react-icons/gr";
import "../../css/work_order_routine.css";
import {dateToSnapshot} from "../../utils/date";
import {useSelector} from "react-redux";
import {RootState} from "../../utils/store";
import {Alert} from "../common/Alert";
import {convertEnum} from "../../utils/transferObject";
import {sort} from "../../utils/table";

type WorkOrderRoutinesTableProp = {
    setEditOpen: Dispatch<SetStateAction<any>>
    submitDelete: (routine: string) => void
}

export const WorkOrderRoutinesTable = ({setEditOpen, submitDelete}: WorkOrderRoutinesTableProp) => {
    const unorderedRoutines: MDReadWorkOrderRoutineResponse[] = useSelector((state: RootState) => state.workOrderRoutine.routines)
    const [idToDelete, setIdToDelete] = useState<string | null>(null)
    const {isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose} = useDisclosure()
    const cancelRef = React.useRef()

    const [routines, setRoutines] = useState<MDReadWorkOrderRoutineResponse[]>(unorderedRoutines)
    const [sortOrder, setSortOrder] = useState<{ [key: string]: boolean }>(initialSortOrder)

    useEffect(() => {
        setRoutines(unorderedRoutines)
    }, [unorderedRoutines])

    const headrows = () => {
        const sortIcon = (field: string) => !sortOrder[field] ? <BsCaretDownFill style={{display: "inline"}}/> :
            <BsFillCaretUpFill style={{display: "inline"}}/>
        const handleSortClick = (fieldOne: string, fieldTwo?: string) => {
            setSortOrder({...sortOrder, [fieldOne]: !sortOrder[fieldOne]})
            setRoutines(sort(sortOrder[fieldOne], routines, fieldOne, fieldTwo,))
        }
        return (
            <>
                <Tr>
                    <th rowSpan={2} id={"notes"}></th>
                    <th rowSpan={2} className={"chakra-th"} onClick={() => handleSortClick("category", "label")}
                        style={{minWidth: "7rem"}}>
                        Category {sortIcon("category")}</th>
                    <th rowSpan={2} className={"chakra-th"}></th>
                    <th rowSpan={2} className={"chakra-th"} onClick={() => handleSortClick("action", "label")}>
                        Action {sortIcon("action")}</th>
                    <th rowSpan={2} className={"chakra-th"} onClick={() => handleSortClick("priority")}
                        style={{width: "4rem"}}>
                        Prio {sortIcon("priority")}</th>
                    <th rowSpan={2} className={"chakra-th"} onClick={() => handleSortClick("assignee", "name")}
                        style={{width: "7rem"}}>
                        Assignee {sortIcon("assignee")}</th>
                    <th rowSpan={2} className={"chakra-th"} style={{maxWidth: "10rem", whiteSpace: "pre-wrap"}}>
                        Verification Method
                    </th>
                    <th rowSpan={2} className={"chakra-th"} onClick={() => handleSortClick("region", "label")}
                        style={{width: "6rem"}}>
                        Region {sortIcon("region")}</th>
                    <th colSpan={7} className={"chakra-th weekdays"}>Weekdays</th>
                    <th rowSpan={2} className={"chakra-th"}>Frequency</th>
                    <th rowSpan={2} className={"chakra-th"} style={{maxWidth: "8rem", whiteSpace: "pre-wrap"}}>
                        Last WorkOrder
                    </th>
                    <th rowSpan={2} className={"chakra-th"} style={{width: "8rem", whiteSpace: "pre-wrap"}}>Ends</th>
                    <th rowSpan={2} className={"chakra-th"}></th>
                    <th rowSpan={2} className={"chakra-th"}></th>
                </Tr>
                <Tr>
                    <Th className={"routine-cell-weekday"}>S</Th>
                    <Th className={"routine-cell-weekday"}>M</Th>
                    <Th className={"routine-cell-weekday"}>T</Th>
                    <Th className={"routine-cell-weekday"}>W</Th>
                    <Th className={"routine-cell-weekday"}>T</Th>
                    <Th className={"routine-cell-weekday"}>F</Th>
                    <Th className={"routine-cell-weekday"}>S</Th>
                </Tr>
            </>
        )
    }

    const routineRow = (routine: MDReadWorkOrderRoutineResponse, i: number) => {
        const {
            category,
            action,
            actionParameter,
            assignee,
            priority,
            verificationMethod,
            region,
            weeklyCondition,
            frequency,
            lastCreated,
            workOrders,
            endCount,
            endDate,
            events
        } = routine
        const {sun, mon, tue, wed, thur, fri, sat} = weeklyCondition
        let endsDisplay: string
        if (endDate) {
            endsDisplay = `${dateToSnapshot(new Date(endDate))}`
        } else if (endCount) {
            endsDisplay = `${endCount - workOrders.length} orders`
        } else {
            endsDisplay = "never"
        }
        return (
            <Tr key={`${i}-routine`} className={"workorder-routine-body-tr"}>
                <Td>{events.length > 0 ? popover("Notes", eventsToNotes(events), <GrDocumentNotes/>) : " "}</Td>
                <Td>{category.label}</Td>
                <Td>{actionParameter ? popover("Action Parameter", actionParameter, <RiPencilRulerLine/>) : " "}</Td>
                <Td>{action.label}</Td>
                <Td>{priority}</Td>
                <Td>{assignee ? assignee.name : null}</Td>
                <Td style={{maxWidth: "10rem", whiteSpace: "pre-wrap"}}>{convertEnum(verificationMethod)}</Td>
                <Td>{region.label}</Td>
                <Td className={"routine-cell-weekday"}>{sun ? <BsCheckSquare/> : <BsSquare/>}</Td>
                <Td className={"routine-cell-weekday"}>{mon ? <BsCheckSquare/> : <BsSquare/>}</Td>
                <Td className={"routine-cell-weekday"}>{tue ? <BsCheckSquare/> : <BsSquare/>}</Td>
                <Td className={"routine-cell-weekday"}>{wed ? <BsCheckSquare/> : <BsSquare/>}</Td>
                <Td className={"routine-cell-weekday"}>{thur ? <BsCheckSquare/> : <BsSquare/>}</Td>
                <Td className={"routine-cell-weekday"}>{fri ? <BsCheckSquare/> : <BsSquare/>}</Td>
                <Td className={"routine-cell-weekday"}>{sat ? <BsCheckSquare/> : <BsSquare/>}</Td>
                <Td>{frequencyLabel(frequency)}</Td>
                <Td style={{maxWidth: "8rem", whiteSpace: "pre-wrap"}}>{lastCreated}</Td>
                <Td>{endsDisplay}</Td>
                <Td className={"routine-cell-weekday"}>
                    <Box>
                        <FiEdit onClick={() => setEditOpen(routine)}
                                className={"pointer"} style={{margin: "auto"}}/>
                    </Box>
                </Td>
                <Td className={"routine-cell-weekday"}><RiDeleteBin5Line onClick={() => handleDeleteClick(routine)}
                                                                         className={"pointer"}
                                                                         style={{margin: "auto"}}/></Td>
            </Tr>
        )
    }

    const handleDeleteClick = (routine: Routine) => {
        onAlertOpen()
        setIdToDelete(routine.routineId)
    }

    const deleteAlertCancel = () => {
        onAlertClose()
        setIdToDelete(null)
    }
    const deleteAlertConfirm = () => {
        onAlertClose()
        submitDelete(idToDelete ? idToDelete : "")
        setIdToDelete(null)
    }

    const eventsToNotes = (events: string[]) => {
        return events.map((string, i) => (<Fragment key={`notes-${i}`}><span>{string}</span><br/></Fragment>))
    }

    return (
        <>
            <Alert isOpen={isAlertOpen && idToDelete !== null} onCloseAll={deleteAlertConfirm}
                   onClose={deleteAlertCancel}
                   body={"Would you like to delete this routine?"} cancelRef={cancelRef}
                   header={"Work Order Routine"}/>
            <Table className={"workorder-routine-table"}>
                <Thead style={{position: "sticky", top: "0", backgroundColor: "white"}}>
                    {headrows()}
                </Thead>
                <Tbody>
                    {routines.map((routine, i) => (routineRow(routine, i)))}
                </Tbody>
            </Table>
        </>
    )
}


const frequencyLabel = (frequency: number) => {
    switch (frequency) {
        case 1:
            return "Weekly"
        case 2:
            return "Biweekly"
        case 12:
            return "Quarterly"
        case 53:
            return "Yearly"
        default:
            return `Every ${frequency} weeks`
    }
}

const initialSortOrder = {
    category: true,
    action: true,
    priority: true,
    assignee: true,
    region: true,
}

const popover = (title: string, content: React.ReactNode, icon: React.ReactNode) => {
    return (
        <Popover placement={"left-start"}>
            <PopoverTrigger>
                <div style={{cursor: "pointer"}}>
                    {icon}
                </div>
            </PopoverTrigger>
            <PopoverContent color='white' bg='blue.800' borderColor='blue.800'>
                <PopoverArrow/>
                <PopoverCloseButton/>
                <PopoverHeader pt={4} fontWeight='bold' border='0'>{title}</PopoverHeader>
                <PopoverBody>
                    {content}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}