export const sort = (order: boolean, arr: any, field: string, secondField?: string,) => {
    const newArr = [...arr]
    return newArr.sort((a: any, b: any) => {
        const aVal = secondField ? a[field][secondField] : a[field]
        const bVal = secondField ? b[field][secondField] : b[field]
        if (aVal < bVal) {
            return order ? -1 : 1;
        }
        if (aVal > bVal) {
            return order ? 1 : -1;
        }
        return 0;
    })
}