import {Box, Flex, Heading, Stack, Text, useColorModeValue, Avatar, HStack, VStack} from "@chakra-ui/react";
import React, {FC} from "react";
import StyledFirebaseAuth from "../utils/StyledFirebaseAuth";
import {LoginState} from "../types/LoginState";

import {useSelector } from 'react-redux';

import Unavailable from '../components/common/unavailable';

interface SignInPageProps {
    uiConfig: firebaseui.auth.Config;
    auth: any;
}
 
const SignInPage: FC<SignInPageProps> = ({uiConfig, auth}) => {

    const loginStatus = useSelector((state: any) => state.user.isLoggedIn);
    const authStatus = useSelector((state:any) => state.user.isAuthorized);

    const displayMessage = () => {
        if (!authStatus.zordi && !!loginStatus){
            return <Unavailable message={messages.unauthorized} />
        } else if (loginStatus === LoginState.UNKNOWN) {
            return <Unavailable message={messages.serverError} />
        }
    }

    return (
        <Flex
            minH={"100vh"}
            align={"center"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <Flex
                align={"center"}
                justify={"center"}
                bg={useColorModeValue("gray.50", "gray.800")}
            >
                <Stack minW={"50vw"} spacing={8} mx={"auto"} maxW={"xl"} py={12} px={6}>

                    <Box
                        rounded={"xl"}
                        bg={useColorModeValue("white", "gray.700")}
                        boxShadow={"lg"}
                        p={8}
                    >                
                        <Stack align={"center"}>
                            <Heading fontSize={"4xl"}>Sign in to your zordi account</Heading>
                            <Text fontSize={"lg"} color={"gray.600"}>
                                to enjoy all of our cool features ✌️
                            </Text>
                            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
                        </Stack>
                        <Stack spacing={10}>
                            <Stack
                                direction={{base: "column", sm: "row"}}
                                align={"center"}
                                justify={"center"}
                            />         
                            {auth.currentUser? (
                                <HStack bgColor={"#C5E0B4"} borderRadius={"300px"} height={"50px"} paddingRight={3}>
                                    <Avatar
                                        width={"50px"}
                                        src={
                                            auth.currentUser?.photoURL ??
                                            "https://gravatar.com/avatar/6c41233e9e9532b64fdf60f77a1f26b8?s=400&d=robohash&r=x"
                                        }
                                    />
                                    <VStack
                                        display={{base: "none", md: "flex"}}
                                        alignItems="flex-start"
                                        spacing="1px"
                                        ml="2"
                                    >
                                        <Text fontSize="sm">
                                            {auth.currentUser?.displayName ?? "Unauthorized User"}
                                        </Text>
                                    </VStack>
                                </HStack> 
                            ): null}  
                            {displayMessage()}  
                        </Stack>
                    </Box>
                </Stack>
            </Flex>
        </Flex>
    );
};

const messages = {
    "unauthorized":
    {
        header: "This account is not authorized to use this app",
        body: "Please use Zordi account or contact Zordi admin to be authorized.",
        imgSrc: "https://images.squarespace-cdn.com/content/v1/613d5b2226b1b9242f14666b/1631563080570-L5CKBCTRUMJ9HS53R51F/Strawberry.png?format=1000w",
    },
    "serverError": {
        header: "Something wrong with the server!",
        body: "Please try again later!",
        imgSrc: "https://images.squarespace-cdn.com/content/v1/613d5b2226b1b9242f14666b/1631563080570-L5CKBCTRUMJ9HS53R51F/Strawberry.png?format=1000w",
    }
}

export default SignInPage;
