import {CellCanvasRendererProps} from "@nivo/heatmap/dist/types/types";
import {HeatMapCell, HeatMapCellType, SiteData} from "../../data/GreenhouseDataTypes";

/**
 * Show different cell depending on the following variables
 * 1. Selected Metric is a plant vs region metric
 * 2. Placeholder(cell that represents spaces between sections)
 * 3. Special type of cell: sensor location, plant
 * 4. opacity(only when showing region metric) depending on whether it's a selected region
 */

export const getCustomCellRenderer = (selectedMetric: string, selectedLocation: string, siteData: SiteData) => {
    return plantCell(selectedLocation, siteData)
}

type CellDimension = {
    x: number,
    y: number,
    width: number,
    height: number
}

const getCellDimension = (props: CellCanvasRendererProps<HeatMapCell>) => {
    let {x, y, width, height} = props.cell
    x = x - (width / 2)
    y = y - (height / 2)
    return {
        x, y, width, height
    }
}

const plantCell = (selectedLocation: string, siteData: SiteData) => {
    return (ctx: CanvasRenderingContext2D, props: CellCanvasRendererProps<HeatMapCell>) => {
        const cellDimension: CellDimension = getCellDimension(props)
        const heatMapCell: HeatMapCell = props.cell.data as HeatMapCell
        switch (heatMapCell.cellType) {
            case HeatMapCellType.ROW_ID_HOLDER:
                if (heatMapCell.x % 12 === 0) {
                    placeHolderWithLine(ctx, cellDimension)
                } else {
                    const growerRow = Math.ceil((heatMapCell.x) / 3)
                    const orderWithInRow = heatMapCell.x % 3
                    if (orderWithInRow === 2) {
                        drawRowNumber(ctx, cellDimension, `${growerRow}`)
                    }

                    if (growerRow % 4 === 3 && orderWithInRow === 1) {
                        const bay = Math.ceil((heatMapCell.x) / 12)
                        drawBayNumber(ctx, cellDimension, `Bay ${bay}`)
                    }
                }
                break;
            case HeatMapCellType.EMPTY_SPACE:
                if (heatMapCell.x % 12 === 0) {
                    placeHolderWithLine(ctx, cellDimension)
                } else {
                    placeHolder(ctx, cellDimension)
                }

                break;
            case HeatMapCellType.HEAT_MAP_DATA_CELL:
                const plant = siteData.plants[props.cell.data.location]
                drawCell(ctx, cellDimension, props.cell.color)
                if (plant.plantId === selectedLocation) {
                    drawBorder(ctx, cellDimension, "red", 1)
                }
                if (plant) {
                    // Draw cell depending on the calculated color.
                    // Draw a border if it's the selected plant

                    break;
                } else {
                    // Not a placeholder, but has no data.
                    // Possibly because monitoring robot hasn't passed this location or selected metric wasn't processed
                    withNoData(ctx, cellDimension)
                    break;
                }
            case HeatMapCellType.HEAT_MAP_NO_DATA_CELL:
                withNoData(ctx, cellDimension)
                break;
            default:
                console.log(`unhandled cells`)
        }
    }
}

const drawRowNumber = (ctx: CanvasRenderingContext2D, cellDimension: CellDimension, text: string) => {
    ctx.font = "600 10px Helvetica";
    ctx.fillStyle = "#404142";
    ctx.textAlign = "center";
    ctx.fillText(text, cellDimension.x, cellDimension.y + (cellDimension.height / 1.5));
}

const drawBayNumber = (ctx: CanvasRenderingContext2D, cellDimension: CellDimension, text: string) => {
    ctx.font = "10px Helvetica";
    ctx.fillStyle = "#505254ㅊ";
    ctx.textAlign = "center";
    ctx.fillText(text, cellDimension.x, cellDimension.y);
}

const drawCell = (ctx: CanvasRenderingContext2D, cellDimension: CellDimension, color: string) => {
    ctx.fillStyle = color
    ctx.fillRect(cellDimension.x, cellDimension.y, cellDimension.width, cellDimension.height)
}

const drawBorder = (ctx: CanvasRenderingContext2D, cellDimension: CellDimension, borderColor: string,
                    borderWidth: number) => {
    ctx.lineWidth = borderWidth;
    ctx.strokeStyle = borderColor;
    ctx.strokeRect(cellDimension.x, cellDimension.y, cellDimension.width, cellDimension.height);
}

const withNoData = (ctx: CanvasRenderingContext2D, cellDimension: CellDimension) => {
    // very light gray
    drawCell(ctx, cellDimension, "#F1F1F1")
}

const placeHolder = (ctx: CanvasRenderingContext2D, cellDimension: CellDimension) => {
    // light gray
    drawCell(ctx, cellDimension, "#F6F6F4")
}

const placeHolderWithLine = (ctx: CanvasRenderingContext2D, cellDimension: CellDimension) => {
    ctx.beginPath();
    ctx.moveTo(cellDimension.x + cellDimension.width / 2, cellDimension.y - cellDimension.height / 2)
    ctx.lineTo(cellDimension.x + cellDimension.width / 2, cellDimension.y + cellDimension.height / 2)
    ctx.strokeStyle = "gray"

    ctx.stroke()
}
