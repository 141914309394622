import React, {useEffect, useMemo, useState} from "react";
import {Column, useSortBy, useTable} from "react-table";
import {WorkOrderStatus as Status, WorkOrder, WorkOrderSource} from "@zordi/zordi_object_schema";
import {useSearchParams} from "react-router-dom";
import {dateToSnapshot, localDateFromSnapshot} from "../utils/date";
import {
    Button,
    Container,
    Divider,
    Flex,
    GridItem,
    HStack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import SingleDatePicker from "../common/SingleDatePicker";
import {setUrlParam} from "../utils/url";
import {fetchWorkOrdersByDateSource, postUpdateWorkOrder} from "../api/order"; // we need this to make JSX compile

export const WorkOrderTest = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [snapshotDate, setSnapshotDate] = useState<Date>(
        searchParams.has("date") ? localDateFromSnapshot(searchParams.get("date") as string) : new Date())

    const [workOrders, setWorkOrders] = useState<WorkOrder[]>([])

    useEffect(() => {
        fetchWorkOrdersByDateSource({
            siteId: "OAK1",
            endTime: snapshotDate
        }, WorkOrderSource.StepFunction).then(setWorkOrders)
    }, [snapshotDate])

    const setDate = (newDate: Date) => {
        setSnapshotDate(newDate)
        setUrlParam("date", dateToSnapshot(newDate), searchParams, setSearchParams)
    }

    const updateWorkOrder = async (workOrder: WorkOrder, newStatus: Status) => {
        await postUpdateWorkOrder(workOrder.workOrderId, {
            status: newStatus
        })

        fetchWorkOrdersByDateSource({
            siteId: "OAK1",
            endTime: snapshotDate
        }, WorkOrderSource.StepFunction).then(setWorkOrders)
    }

    const statusButton = (workOrder: WorkOrder, index: number) => {
        switch (workOrder.status) {
            case (Status.NEEDS_VERIFICATION || Status.COMPLETED_AND_VERIFIED):
                return (<Button colorScheme='whatsapp' variant="outline" disabled>
                    {workOrder.status}
                </Button>)
            case Status.CANCELLED:
                return <Button colorScheme='red' variant="outline" disabled>
                    {workOrder.status}
                </Button>
            case (Status.SCHEDULED || Status.ACTIVE):
                return (<HStack w={"100%"}>
                    ASDFASEF
                    <Text>{workOrder.status}</Text>
                    <Button colorScheme="whatsapp" variant="outline"
                            onClick={() => updateWorkOrder(workOrder, Status.NEEDS_VERIFICATION)}>
                        Done
                    </Button>
                    <Button colorScheme="red" variant="outline"
                            onClick={() => updateWorkOrder(workOrder, Status.CANCELLED)}>
                        Cancel
                    </Button>
                </HStack>)

            default:
                return <Button colorScheme='red' variant="outline" disabled>
                    Error
                </Button>
        }
    }

    const workOrderTableColumns = useMemo(
        (): Column<WorkOrder>[] => [
            {
                Header: 'Execution date',
                id: 'execution_date',
                accessor: (row) => row.targetDate,
            },
            {
                Header: 'Status',
                id: 'status',
                accessor: (row) => row.status,
                Cell: (props: any) => {
                    return statusButton(props.data[props.cell.row.index], props.cell.row.index)
                }
            },
            {
                Header: 'Problem',
                id: 'problem',
                accessor: (row) => row.problem ? row.problem.description : "",
            },
            {
                Header: 'Action',
                id: 'action',
                accessor: (row) => row.action ? row.action.action : "",
            },
            {
                Header: 'Application Region',
                id: 'target_region',
                accessor: (row) => row.targetRegion ? row.targetRegion.regionId : "",
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const {
        rows, getTableProps, getTableBodyProps, headerGroups, prepareRow,
    } =
        useTable({
            columns: workOrderTableColumns,
            data: workOrders,
        }, useSortBy)

    return (
        <Container maxW={"container.3xl"} paddingLeft={"25px"} paddingRight={"25px"} m={0}>
            <Flex h={"100vh"} w={"100%"} direction={{base: "column"}}>
                <SingleDatePicker date={snapshotDate} setDate={setDate}/>
                <Divider borderColor={"zordiGreen"} borderWidth={"1px"}/>
                <GridItem colSpan={1}>
                    <Table {...getTableProps()} className="table-tiny"
                           fontFamily={"Poppins"}
                    >
                        <Thead>
                            {headerGroups.map((headerGroup) => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <Th key={column.id}>
                                            {column.render('Header')}
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {rows.map((row) => {
                                prepareRow(row)
                                return (
                                    <Tr {...row.getRowProps()} height={"42px"}>
                                        {row.cells.map((cell) => (
                                            <Td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </Td>
                                        ))}
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </GridItem>
            </Flex>
        </Container>
    )
}