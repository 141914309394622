import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {PlantInfo} from "../components/overview/greenhouse/ImageCarousel";
import {dateToSnapshot} from "../utils/date";

export interface SiteOverviewState {
    date: string;
    selectedPlant: PlantInfo | undefined;
}

const initialState: SiteOverviewState = {
    date: dateToSnapshot(new Date()),
    selectedPlant: undefined,
}

export const SiteOverviewSlice = createSlice({
    name: 'siteOverview',
    initialState,
    reducers: {
        updateDate: (state, action: PayloadAction<string>) => {
            state.date = action.payload
        },
        updatePlant: (state, action: PayloadAction<PlantInfo | undefined>) => {
            state.selectedPlant = action.payload;
        }
    },
})

export const {updateDate, updatePlant} = SiteOverviewSlice.actions;

export const siteOverviewReducer = SiteOverviewSlice.reducer;
