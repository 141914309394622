import React, {useState} from "react";
import "./DateTimePicker.css";
import {Button, HStack, Stack, Text, useMediaQuery} from '@chakra-ui/react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {titleStyle} from "../ZordiChakraTheme";

type DatePickerProps = {
    date: Date
    setDate: (newTime: Date) => void
}

export const SingleDatePicker = ({
                                     date, setDate
                                 }: DatePickerProps) => {
    const [newDate, setNewDate] = useState(date);
    const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)')

    return (
        <HStack w={"100%"} justify={"space-between"} py={5}>
            <Text {...titleStyle} hidden={!isLargerThan1280}>Date & Time Range</Text>
            <Stack direction={{base: "column", "lg": "row"}} fontFamily={"Poppins"}>
                <HStack>
                    <DatePicker
                        selected={newDate}
                        onChange={(date: Date) => {
                            setNewDate(date);
                        }} //only when value has changed
                        dateFormat="P"
                    />
                </HStack>
                <Button variant='zordi-basic'
                        onClick={() => {
                            setDate(newDate)
                        }}>Apply</Button>
            </Stack>
        </HStack>
    )
}

export default SingleDatePicker;
