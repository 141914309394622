import React, {createContext, useContext} from "react";
import {fetchAlerts} from "../api/alert";
import {Alert} from "../types/Alert";

export interface AlertProviderState {
    getAlerts: () => Promise<Alert[]>;
}

const AlertContext = createContext<AlertProviderState | null>(null);

type AlertProviderProps = {
    children?: React.ReactNode;
    startTime: Date
    endTime: Date
}

const AlertProvider: React.FC<AlertProviderProps> = ({children, startTime, endTime}) => {

    const getAlerts = (): Promise<Alert[]> => {
        return fetchAlerts(startTime, endTime);
    }

    return (
        <AlertContext.Provider value={{getAlerts}}>{children}</AlertContext.Provider>
    );
};

const useAlertContext = () => {
    const context = useContext(AlertContext);

    if (!context) {
        throw new Error("Unable to use useOrderContext without OrderProvider");
    }

    return context;
};

export {useAlertContext, AlertProvider};
