import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {Route, Routes} from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {FiCalendar, FiGrid, FiRepeat} from "react-icons/fi";
import {GiStrawberry} from "react-icons/gi";

import "./css/App.css";
import "./css/common.css";
import {get} from "./api/baseRequests";
import {HttpException} from "./types/HttpException";
import {login, logout, authorize, deauthorize, unknown} from './redux/userSlice';
import {RootState} from "./utils/store";

import AuthRoutes from './routes';
import Sidebar from "./common/Sidebar";
import Header from "./components/common/Header";
import {NewWorkOrderSidebar} from "./components/workorder/NewWorkOrderSidebar";
import SignInPage from "./pages/SignIn";
import {toggleNewWorkOrderSidebar} from "./redux/pageSlice";

// Configure Firebase.
firebase.initializeApp({
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FB_FIREBASE_APP_ID,
});

// Configure FirebaseUI.
const uiConfig: firebaseui.auth.Config = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
};

function App() {
    const authStatus = useSelector((state: any) => state.user.isAuthorized);
    const auth = firebase.auth();
    const dispatch = useDispatch();

    // the open button also exists in route > SiteOverview > Heatmap > ZordiHeatmap > CellDetailView > ImgCarousel > ImgCarouselNav
    // use of redux is simpler than passing props down, but redux state does not rerender component (opening the sidebar) on update
    // useEffect connects redux update to useState which will rerender the sidebar to open
    // second useEffect exists so that whenever it is closed, redux state also turns false. else it will not work as expected
    const [isNewWorkOrderSidebarOpen, setIsNewWorkOrderSidebarOpen] = useState<boolean>(false)
    const newWorkOrderOpen = useSelector((state:RootState) => state.page.newWorkOrderSidebar)

    useEffect(() => {
        if (newWorkOrderOpen) {
            setIsNewWorkOrderSidebarOpen(true)
        }
    }, [newWorkOrderOpen])

    useEffect(() => {
        if (!isNewWorkOrderSidebarOpen && newWorkOrderOpen) {
            dispatch(toggleNewWorkOrderSidebar(false))
        }
    }, [isNewWorkOrderSidebarOpen, newWorkOrderOpen, dispatch])
    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const unregisterAuthObserver = firebase
            .auth()
            .onAuthStateChanged(async (user) => {
                try {
                    if (!!user) {
                        await get("authorize");
                        const userJSON = JSON.stringify(user);
                        dispatch(authorize());
                        dispatch(login(JSON.parse(userJSON)));
                    } else {
                        dispatch(logout());
                        dispatch(deauthorize());
                    }
                } catch (e: any) {
                    if (e instanceof HttpException) {
                        if (e.status === 403) {
                            dispatch(deauthorize());
                        }
                    }
                    dispatch(unknown())
                }
            });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, [dispatch]);

    const AuthenticatedScreen = () => {
        const route: string = window.location.pathname.split("/")[1]
        const sidebarItem: any = sidebarItems[route]
        return (
            <div>
                <NewWorkOrderSidebar isOpen={isNewWorkOrderSidebarOpen}
                                     onClose={() => setIsNewWorkOrderSidebarOpen(false)}/>
                <Sidebar selectedIcon={sidebarItem.icon} header={<Header auth={auth}/>}
                         setSidebar={setIsNewWorkOrderSidebarOpen}>
                    <AuthRoutes auth={auth}/>
                </Sidebar>
            </div>
        );
    };
    const UnAuthenticatedScreen = () => {
        return (
            <Routes>
                <Route path="*" element={<SignInPage auth={auth} uiConfig={uiConfig}/>}/>
            </Routes>
        )
    };

    return (
        <div className="App">
            {authStatus.zordi ? AuthenticatedScreen() : UnAuthenticatedScreen()}
        </div>

    )
}


export const sidebarItems: { [key: string]: any } = {
    "": {
        "name": "Sites",
    },
    "site_overview": {
        "name": "Site Overview",
        "type": "site_overview",
    },
    "overview": {
        "name": "Overview",
        "icon": FiGrid,
        "link": "/overview",
        "type": "overview",
    },
    "work_order_overview": {
        "name": "Work Order Overview",
        "icon": FiCalendar,
        "link": "/work_order_overview",
        "type": "work_order_overview",
    },
    "work_order_routines": {
        "name": "Work Order Routines",
        "icon": FiRepeat,
        "link": "/work_order_routines",
    },
    "inventory": {
        "name": "Inventory",
        "icon": GiStrawberry,
        "link": "/inventory",
    },
    "report": {
        "name": "Site Report",
        "type": "report",
    },
    "test": {
        "name": "test pages",
        "type": "test",
    },
    "labor-log": {
        "name": "labor-log",
        "type": "labor-log",
    }
}


export default App;
