import {
    Avatar,
    Box,
    Flex,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import firebase from "firebase/compat/app";
import {FiChevronDown} from "react-icons/fi";
import React, {FC, useEffect, useState} from "react";
import {Title} from "../../common/typography/title";
import {Link, useLocation} from 'react-router-dom';

import '../../css/header.css';

import {useDispatch, useSelector} from 'react-redux';
import {updateCity, updatePage} from '../../redux/pageSlice';

import {CityData} from '../../lib/datatypes/CityData';
import {PageData} from '../../lib/datatypes/PageData';
import cities from '../../lib/labels/cities';
import pages from '../../lib/labels/pages';
import {RootState} from '../../utils/store';

import {sidebarItems} from "../../App";

const {SITE_OVERVIEW, SYSTEM_VS_GROWER, REMOTE_VS_INPERSON, LABOR_REPORT, SAMPLE} = pages;
const {OAKFIELD, JACKSON} = cities;

interface HeaderProps {
    pageName?: string;
    pageType?: string;
    auth: firebase.auth.Auth;
    additionalMenu?: any;
}

const navItems = [{
    city: OAKFIELD,
    pages: [SITE_OVERVIEW, SYSTEM_VS_GROWER, REMOTE_VS_INPERSON, LABOR_REPORT]
}];

const Header: FC<HeaderProps> = ({auth}) => {
    const [title, setTitle] = useState<string>("")
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector((state: RootState) => state.user.user);
    const city = useSelector((state: RootState) => state.page.city);
    const page = useSelector((state: RootState) => state.page.pageData);

    const setPageInfo = (city: CityData, page: PageData) => {
        dispatch(updateCity(city));
        dispatch(updatePage(page));
    };

    const addMenu = () => {
        return navItems.map((item, idx) => {
            const menuItems = item.pages.map((page: PageData, i) => {
                return (
                    <MenuItem key={`${i}-menu-item-${item.city.id}`}>
                        <Link
                            to={`/${page.pageType}/${item.city.id}/${page.page !== undefined ? `${page.page}` : ""}`}
                            onClick={() => setPageInfo(item.city, page)}>
                            {page.menuLabel}
                        </Link>
                    </MenuItem>
                )
            });
            return (
                <Menu key={`${idx}-menu-${item.city.city}`}>
                    <MenuButton>
                        {item.city.city}
                    </MenuButton>
                    <MenuList>
                        {menuItems}
                    </MenuList>
                </Menu>
            )
        })
    }

    const getTitle = (pathname: string, city: CityData, page: PageData) => {
        const route: string = pathname.split("/")[1]
        const title = {city: city.city, page: ""}
        if (route === "site_overview" || route === "report") {
            title.page = page.pageTitle
        } else {
            const checkItem: any = sidebarItems[route as keyof typeof sidebarItems]
            title.page = checkItem !== undefined ? checkItem.name : ""
        }
        return `${title.city}: ${title.page}`
    }

    useEffect(() => {
        setTitle(getTitle(location.pathname, city, page))
    }, [city, page, location.pathname])

    return (
        <VStack
            className={"header"}
            w='full'
        >
            <HStack spacing={{base: "0", md: "6"}}
                    w='full'
                    justify={"space-between"}
                    paddingLeft={"25px"} paddingRight={"25px"}
            >

                <Title text={title} color={"zordiGreen"}/>
                {addMenu()}
                <Menu>
                    <Link to={"/overview"} className="tab-item" onClick={() => setPageInfo(JACKSON, SAMPLE)}>
                        Jackson(TN)
                    </Link>
                </Menu>
                <HStack>
                    <Flex alignItems={"center"}>
                        <Menu>
                            <MenuButton
                                py={2}
                                transition="all 0.3s"
                                _focus={{boxShadow: "none"}}
                            >
                                <HStack bgColor={"#C5E0B4"} borderRadius={"300px"} height={"50px"} paddingRight={3}>
                                    <Avatar
                                        width={"50px"}
                                        src={
                                            user?.photoURL ??
                                            "https://gravatar.com/avatar/6c41233e9e9532b64fdf60f77a1f26b8?s=400&d=robohash&r=x"
                                        }
                                    />
                                    <VStack
                                        display={{base: "none", md: "flex"}}
                                        alignItems="flex-start"
                                        spacing="1px"
                                        ml="2"
                                    >
                                        <Text fontSize="sm">
                                            {user?.displayName ?? "Unauthorized User"}
                                        </Text>
                                    </VStack>
                                    <Box display={{base: "none", md: "flex"}}>
                                        <FiChevronDown/>
                                    </Box>
                                </HStack>
                            </MenuButton>
                            <MenuList
                                bg={useColorModeValue("white", "gray.900")}
                                borderColor={useColorModeValue("gray.200", "gray.700")}
                            >
                                <MenuItem color={"red.500"} onClick={() => auth.signOut()}>
                                    Sign out
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </HStack>
            </HStack>
        </VStack>
    );
};

export default Header;
