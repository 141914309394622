import {WorkOrder, WorkOrderMatch} from "@zordi/zordi_object_schema";
import React from "react";
import moment from "moment/moment";

export const checkValidity = (systemWorkOrder: WorkOrder | undefined, growerWorkOrder: WorkOrder | undefined) => {
    let statement = "";
    let className = "";
    if (systemWorkOrder && growerWorkOrder) {
        if (systemWorkOrder.workOrderMatch === WorkOrderMatch.FALSE_POSITIVE) {
            statement = "Invalid";
            className = "red";
        } else {
            statement = "Valid";
            className = "green";
        }
    } else if (systemWorkOrder) {
        if (systemWorkOrder.workOrderMatch === WorkOrderMatch.TRUE_POSITIVE) {
            statement = "Valid";
            className = "green";
        } else if (systemWorkOrder.workOrderMatch === WorkOrderMatch.FALSE_POSITIVE) {
            statement = "Invalid";
            className = "red";
        }
    } else if (growerWorkOrder) {
        if (growerWorkOrder.workOrderMatch === WorkOrderMatch.TRUE_NEGATIVE) {
            statement = "Grower work order invalid";
            className = "red";
        } else if (growerWorkOrder.workOrderMatch === WorkOrderMatch.FALSE_NEGATIVE) {
            statement = "Grower work order valid";
            className = "green";
        }
    } else {
        // should never happen
    }
    return <span className={className}>{statement}</span>;
}

export const checkMatching = (systemWorkOrder: WorkOrder | undefined, growerWorkOrder: WorkOrder | undefined) => {
    let statement = "";
    let className = "";
    if (systemWorkOrder && growerWorkOrder) {
        if (systemWorkOrder.workOrderMatch === WorkOrderMatch.TRUE_POSITIVE) {
            if (systemWorkOrder.hasMatching) {
                statement = "Matching";
                className = "green";
            } else {
                statement = "Not Matching";
                className = "red";
            }
        }
    }
    return <span className={className}>{statement}</span>;
}

export const appendTimestamp = (note: string) => {
    const timeString = moment().utc().format("YYYY/MM/DD hh:mma UTC")

    return `[${timeString}] ${note}`
}
