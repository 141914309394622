import React from "react";
import "firebase/compat/auth";

import {OrderProvider} from "../contexts/OrderProvider";
import {SiteReportSyVsGr} from "../components/report/SiteReportSyVsGr";
import {SiteReportReVsIn} from "../components/report/SiteReportReVsIn";
import {LaborReport} from "../components/report/LaborReport";
import {useSelector} from "react-redux";
import {RootState} from "../utils/store";
import pages from "../lib/labels/pages";

const {SYSTEM_VS_GROWER, REMOTE_VS_INPERSON, LABOR_REPORT} = pages;

export const SiteReportPage = () => {
    const page = useSelector((state: RootState) => state.page.pageData)

    const findPage = () => {
        switch (page) {
            case SYSTEM_VS_GROWER:
                    return <SiteReportSyVsGr/>
            case REMOTE_VS_INPERSON:
                    return <SiteReportReVsIn/>
            case LABOR_REPORT:
                return <LaborReport />
            default:
                return <SiteReportSyVsGr/>
        }
    }
    return (
        <OrderProvider>
            {findPage()}
        </OrderProvider>
    )
};
