import {createAsyncThunk} from '@reduxjs/toolkit'
import {get, post} from "../../api/baseRequests";
import {
    MDCreateLaborDataRequest,
    MDReadLaborDataByRowRequest,
    MDUpdateLaborDataRequest
} from "@zordi/zordi_object_schema";

export const createLaborData = createAsyncThunk('/workorder/create_entry',
    async (createLaborDataRequest: MDCreateLaborDataRequest) => {
        return post('workorder/create_entry', {
            createLaborDataRequest
        });
    })

export const updateLaborData = createAsyncThunk('/workorder/update_entry',
    async (updateLaborDataRequest: MDUpdateLaborDataRequest) => {
        return post('workorder/update_entry', {
            updateLaborDataRequest
        });
    })

export const getLaborDataByRow = createAsyncThunk('/workorder/get_entries',
    async (readLaborDataByRowRequest: MDReadLaborDataByRowRequest) => {
        const params = new URLSearchParams()
        params.append("siteId", readLaborDataByRowRequest.siteId)
        if (readLaborDataByRowRequest.rowNumber != null) {
            params.append("rowNumber", readLaborDataByRowRequest.rowNumber.toString())
        }
        params.append("isGeneral", readLaborDataByRowRequest.isGeneral.toString())

        return get('workorder/entries_by_row', params);
    })


export const getRowOptions = createAsyncThunk('/workorder/row_options',
    async (siteCode: string) => {
        const params = new URLSearchParams()
        params.append("siteCode", siteCode)
        return get('workorder/row_options', params)
    })
