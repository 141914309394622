import React, {useCallback, useEffect, useState} from "react"; // we need this to make JSX compile
import {useDispatch, useSelector} from "react-redux";
import {RootState} from '../../../utils/store';
import "firebase/compat/auth";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
} from "@chakra-ui/react";
import {SiteData} from "../../../data/GreenhouseDataTypes";
import {useHeatmapContext} from "../../../contexts/HeatmapProvider";
import {getPlantImages} from "../../../common/heatmap/activeMetric";
import {getNextInRow, getPreviousInRow} from "../../../utils/greenhouse_navigation_helper";
import {updatePlant} from "../../../redux/siteOverviewSlice";
import {Plant} from "../../../data/GreenhouseDataTypes";
import {plantIdToGrowerId} from "../../../utils/labels";
import {ImageCarouselNav, prevWeekDates} from "./ImageCarouselNav";
import {Positions} from '../../../lib/labels/PlantImgPositions';
import {fetchPlant} from '../../../api/es';
import '../../../css/overview.css';
import {Magnifier} from "./Magnifier";

type Props = {
    isOpen: boolean,
    onClose: () => void,
    siteData: SiteData,
    selectedPlant: Plant
};

export interface PlantInfo {
    id: string;
    imgSrcList: any;
}

type PrevDateImages = {
    [date: string]: { [position: string]: string }
}

export const ImageCarousel = ({isOpen, onClose, siteData, selectedPlant}: Props) => {
    const heatmapContext = useHeatmapContext();
    const dispatch = useDispatch();
    const date = useSelector((state: RootState) => state.siteOverview.date);
    const displayedPlantInfo = useSelector((state: RootState) => state.siteOverview.selectedPlant)
    const [displayedPlantId, setDisplayedPlantId] = useState<string>(selectedPlant.plantId);
    const [displayedPosition, setDisplayedPosition] = useState<string>(Positions.TOP_RGB.id);
    const [comparedPlant, setComparedPlant] = useState<string[] | undefined>(undefined);
    const [prevDateImages, setPrevDateImages] = useState<PrevDateImages | undefined>(undefined);
    const [prevPlantInfo, setPrevPlantInfo] = useState<PlantInfo | undefined>(undefined);
    const [nextPlantInfo, setNextPlantInfo] = useState<PlantInfo | undefined>(undefined);

    const getSignedUrls = async (plant: Plant) => {
        const imageList = getPlantImages(plant);
        let getInfo: any = {};
        imageList.forEach(image => {
            getInfo[image["position"]] = {bucket: image["bucket"], path: image["path"]};
        });
        return await heatmapContext.getSignedUrls(JSON.stringify(getInfo))
            .then(val => JSON.parse(val));
    }

    const getPlantInfo = useCallback(async (plantId: string) => {
        const plant = siteData.plants[plantId];
        const newPlantInfo: PlantInfo = {
            id: plant !== undefined ? plantIdToGrowerId(displayedPlantId) : "No Plant selected",
            imgSrcList: await getSignedUrls(plant),
        }
        return newPlantInfo;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteData, selectedPlant])

    const getPrevWeekImages = async (plantId: string, selectedDate: string) => {
        const dates = prevWeekDates(selectedDate)
        let result: { [date: string]: { [position: string]: string } } = {};
        for (let date of dates) {
            const plant: Plant = await fetchPlant(plantId, date);
            result[date] = await getSignedUrls(plant);
        }
        return result;
    };

    useEffect(() => {
        setComparedPlant(undefined)
        setPrevPlantInfo(undefined)
        dispatch(updatePlant(undefined))
        setNextPlantInfo(undefined)

        setDisplayedPlantId(selectedPlant.plantId)
    }, [selectedPlant, dispatch])

    useEffect(() => {
        const plant = siteData.plants[displayedPlantId];
        const prevPlant = getPreviousInRow(plant, siteData);
        const nextPlant = getNextInRow(plant, siteData);

        if (!displayedPlantInfo) {
            getPlantInfo(displayedPlantId).then((plant: PlantInfo) => {
                dispatch(updatePlant(plant))
            })
        }

        if (!prevPlantInfo && prevPlant) {
            getPlantInfo(prevPlant.plantId).then(setPrevPlantInfo)
        }

        if (!nextPlantInfo && nextPlant) {
            getPlantInfo(nextPlant.plantId).then(setNextPlantInfo)
        }
        getPrevWeekImages(displayedPlantId, date).then((plants) => {
            setPrevDateImages(plants)
            setComparedPlant([Object.keys(plants)[0], Positions.SIDE_RGB.id]);
        });

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedPlantId, siteData]);

    const handlePreviousPlantButton = () => {
        const previousPlant = getPreviousInRow(siteData.plants[displayedPlantId], siteData);
        if (previousPlant) {
            setPrevPlantInfo(undefined)
            dispatch(updatePlant(prevPlantInfo))
            setNextPlantInfo(displayedPlantInfo)

            setDisplayedPlantId(previousPlant.plantId)
        }
    };

    const handleNextPlantButton = () => {
        const nextPlant = getNextInRow(siteData.plants[displayedPlantId], siteData);
        if (nextPlant) {
            setPrevPlantInfo(displayedPlantInfo)
            dispatch(updatePlant(nextPlantInfo))
            setNextPlantInfo(undefined)
            setDisplayedPlantId(nextPlant.plantId)
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"6xl"} blockScrollOnMount={true}>
            <ModalOverlay/>
            <ModalContent className="imgcarousel-container">
                <ModalCloseButton/>
                <ModalBody className={'modal-body'}>
                    <div className={"nav-column"}>
                        <ImageCarouselNav
                            next={handleNextPlantButton}
                            prev={handlePreviousPlantButton}
                            plant={displayedPlantInfo}
                            setComparedPlant={setComparedPlant}
                            setPosition={setDisplayedPosition}
                            displayedPosition={displayedPosition}
                            plantId={displayedPlantId}
                        />
                        {!!prevDateImages && !!comparedPlant ? (
                            <div className={"comparison-box"}>
                                {prevDateImages[comparedPlant[0]][comparedPlant[1]] ? (
                                    <Magnifier src={prevDateImages[comparedPlant[0]][comparedPlant[1]]}/>
                                ) : (<div className={"center-child"}><span>No Plant Image</span></div>)}
                            </div>
                        ) : (<div className={"center-child"}><span>No Plant Image</span></div>)}
                    </div>
                    <div className={"main-box"}>
                        {!!displayedPlantInfo && !!displayedPosition && displayedPlantInfo.imgSrcList[displayedPosition] ? (
                            <Magnifier src={displayedPlantInfo.imgSrcList[displayedPosition]}/>
                        ) : <div>No Plant Image</div>}
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
};
