import {get} from './baseRequests';
import {Alert} from "../types/Alert";

export const parseAlerts = (json: any): Alert[] => {
    return json.map((item: any) => {
        return item as Alert;
    });
}

export const fetchAlerts = async (startTime: Date, endTime: Date): Promise<Alert[]> => {
    const queryParams: [string, string][] = [
        ["start", startTime.toISOString()],
        ["end", endTime.toISOString()]
    ]
    return get('alert', new URLSearchParams(queryParams)).then(json => parseAlerts(json));
}
