const cities = {
  BOSTON: {
    city: "Boston",
    id: "bos1",
  },
  OAKFIELD: {
    city: "OakField",
    id: "oak1",
  },
  JACKSON: {
    city: "Jackson(TN)",
    id: "",
  }
}

export default cities;
