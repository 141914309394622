import React, {useEffect, useState} from "react";
import {Box, HStack, Image, Spinner, Table, Tbody, Td, Text, Tr, useDisclosure, VStack,} from "@chakra-ui/react";
import {Plant, SiteData} from "../../data/GreenhouseDataTypes";
import {useHeatmapContext} from "../../contexts/HeatmapProvider";
import {getActivePlantMetric, getPlantImages, metricValueRetriever, valueToFixedDigits} from "./activeMetric";
import {plantIdToGrowerId} from '../../utils/labels';
import {ImageCarousel} from "../../components/overview/greenhouse/ImageCarousel";

type CellDetailViewProps = {
    selectedPlant: Plant | null
    siteData: SiteData
};

export const CellDetailView = ({
                                   selectedPlant,
                                   siteData,
                               }: CellDetailViewProps) => {

    const heatmapContext = useHeatmapContext();
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [rgb, setRgb] = useState<string | null>(null);

    useEffect(() => {
        if (selectedPlant) {
            const images = getPlantImages(selectedPlant)
            if (images.length > 0) {
                const firstImage = images[0]
                heatmapContext.getSignedUrl(firstImage["bucket"], firstImage["path"]).then((result) => {
                    setRgb(result)
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPlant]);

    if (selectedPlant) {
        let idToDisplay = plantIdToGrowerId(selectedPlant.plantId);
        let data = []
        for (let metric of getActivePlantMetric()) {
            if (metricValueRetriever[metric] !== undefined) {
                data.push([metric, valueToFixedDigits(metricValueRetriever[metric](selectedPlant))])
            } else {
                data.push([metric, valueToFixedDigits(selectedPlant[metric])])
            }
        }

        return (
            <VStack w={"350px"} justifyContent={"left"} id={"GreenHouseDetailView"}>
                <TitleView title={idToDisplay}></TitleView>
                <DataView data={data}></DataView>
                {rgb ? (
                    <>
                        <Text paddingTop={"20px"} fontFamily={"Poppins"}>RGB Image (click image to see all
                            images)</Text>
                        <Box p={"20px"} onClick={onOpen}>
                            <span className="pointer">
                                <Image
                                    borderColor={"red"}
                                    objectFit='cover'
                                    fallback={<Spinner marginLeft={"25px"} size='xl'></Spinner>}
                                    src={rgb}
                                    className="thumbnail-image"/>
                            </span>
                        </Box>
                        <ImageCarousel isOpen={isOpen} onClose={onClose} siteData={siteData}
                                       selectedPlant={selectedPlant}></ImageCarousel>
                    </>
                ) : ("")}

            </VStack>
        )
    } else {
        return (<VStack w={"350px"} justifyContent={"left"}>
            <TitleView title={"No Plant selected"}></TitleView>
        </VStack>)
    }
};

const TitleView = ({title}: { title: string }) => {
    return (
        <HStack w={"100%"} justifyContent={"left"} p={"25px"}>
            <Text fontSize="20px" fontWeight={400} fontFamily={"Poppins"}>
                {title}
            </Text>
        </HStack>
    );
};

const DataView = ({data}: { data: (string | number)[][] }) => {
    return (
        <Table w={"350px"} variant="simple" fontFamily={"Poppins"}>
            <Tbody>
                {data.map((sampleStat, i) => {
                    return (
                        <Tr key={`data-view-tr-${i}`} borderBottom={"2px solid #C4C4C4"}>
                            <Td>{sampleStat[0]}</Td>
                            <Td isNumeric>{sampleStat[1]}</Td>
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
};
