import React, {Fragment, useEffect, useState} from "react"; // we need this to make JSX compile
import "firebase/compat/auth";
import {
    Checkbox,
    Container,
    Flex,
    HStack,
    Link,
    ListItem,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    UnorderedList,
    VStack,
} from "@chakra-ui/react";
import {Title} from "../../common/typography/title";
import "../../css/site_report.css";
import {useParams} from "react-router";
import {useOrderContext} from "../../contexts/OrderProvider";
import {WorkOrdersBySource} from "../../api/order";
import {TaskRecord, WorkOrder} from "@zordi/zordi_object_schema";
import {WorkOrderCell} from "./WorkOrderCell";
import {WorkOrderReviewModule} from "./WorkOrderReviewModule";
import {useDispatch} from 'react-redux';
import {updateSiteReportWorkOrders as updateWorkOrders} from '../../redux/reportDataSlice';
import {ConcernActionCell} from './ConcernActionCell';
import {checkMatching, checkValidity} from '../../utils/site_report';

type ComparisonByDay = {
    date: string,
    matched: {
        systemWorkOrderId: string,
        growerWorkOrderId: string
    }[],
    unmatchedSystemWorkOrderIds: string[],
    unmatchedGrowerWorkOrderIds: string[],
}

export const SiteReportSyVsGr = () => {
    const orderContext = useOrderContext();
    const dispatch = useDispatch();

    const [comparisons, setComparisons] = useState<ComparisonByDay[]>([]);
    const [hideExperimentWorkOrders, setHideExperimentWorkOrders] = useState<boolean>(true);
    const [workOrderGenerationRecord, setWorkOrderGenerationRecord] = useState<TaskRecord | undefined>(undefined);
    const [comparisonReportRecord, setComparisonReportRecord] = useState<TaskRecord | undefined>(undefined);

    // Contains work order shown on the UI
    const [workOrderMap, setWorkOrderMap] = useState<{ [key: string]: WorkOrder }>({})
    let {siteId} = useParams();

    const sortWorkOrders = (workOrders: WorkOrdersBySource) => {

        // group by date
        const growerWorkOrderMap: { [key: string]: WorkOrder[] } = {}
        const systemWorkOrderMap: { [key: string]: WorkOrder[] } = {}

        const allWorkOrderMap: { [key: string]: WorkOrder } = {}

        workOrders.growerWorkOrders
            .forEach(workOrder => {
                if (growerWorkOrderMap[workOrder.targetDate]) {
                    growerWorkOrderMap[workOrder.targetDate].push(workOrder)
                } else {
                    growerWorkOrderMap[workOrder.targetDate] = [workOrder]
                }
                allWorkOrderMap[workOrder.workOrderId] = workOrder

            })
        workOrders.systemWorkOrders
            .forEach(workOrder => {
                if (systemWorkOrderMap[workOrder.targetDate]) {
                    systemWorkOrderMap[workOrder.targetDate].push(workOrder)
                } else {
                    systemWorkOrderMap[workOrder.targetDate] = [workOrder]
                }
                allWorkOrderMap[workOrder.workOrderId] = workOrder
            })
        setWorkOrderMap(allWorkOrderMap)

        const comparisonsByDay: ComparisonByDay[] = []

        const dates = [...new Set([...Object.keys(systemWorkOrderMap), ...Object.keys(growerWorkOrderMap)])]
        for (const date of dates) {
            const comparisonByDay = processDayData(date,
                growerWorkOrderMap[date] ? growerWorkOrderMap[date] : [],
                systemWorkOrderMap[date] ? systemWorkOrderMap[date] : [])
            comparisonsByDay.push(comparisonByDay)
        }
        return comparisonsByDay
    }

    const processDayData = (date: string, growerWorkOrders: WorkOrder[],
                            systemWorkOrders: WorkOrder[]): ComparisonByDay => {
        const matched = []
        const unmatchedSystemWorkOrderIds: string[] = []
        const unmatchedGrowerWorkOrderIds: string[] = []
        for (const systemWorkOrder of systemWorkOrders) {
            if (systemWorkOrder.matchKey) {
                for (const growerWorkOrder of growerWorkOrders) {
                    if (systemWorkOrder.matchKey === growerWorkOrder.matchKey) {
                        matched.push({
                            systemWorkOrderId: systemWorkOrder.workOrderId,
                            growerWorkOrderId: growerWorkOrder.workOrderId,
                        })
                    }
                }
            } else {
                unmatchedSystemWorkOrderIds.push(systemWorkOrder.workOrderId)
            }
        }

        for (const growerWorkOrder of growerWorkOrders) {
            if (!growerWorkOrder.matchKey) {
                unmatchedGrowerWorkOrderIds.push(growerWorkOrder.workOrderId)
            }
        }

        return {
            date,
            matched,
            unmatchedSystemWorkOrderIds,
            unmatchedGrowerWorkOrderIds
        }
    }

    useEffect(() => {
            if (siteId) {
                const startTime = new Date(new Date().getTime() - 86000 * 1000 * 7)
                orderContext.getAllWorkOrders({
                    siteId: siteId.toUpperCase(),
                    startTime
                }).then((workOrders) => {
                    setComparisons(sortWorkOrders(workOrders))
                    setWorkOrderGenerationRecord(workOrders.workOrderGenerationRecord)
                    setComparisonReportRecord(workOrders.comparisonReportRecord)
                    dispatch(updateWorkOrders(workOrders));
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [orderContext, siteId]
    )
    ;

    const updateWorkOrderFields = async (workOrderUpdates:
                                             {
                                                 workOrder: WorkOrder,
                                                 fieldsToUpdate: { [key: string]: any }
                                             }[]) => {
        const promises = workOrderUpdates.map(
            workOrderUpdate => orderContext.updateWorkOrder(workOrderUpdate.workOrder.workOrderId,
                workOrderUpdate.workOrder.targetDate, workOrderUpdate.fieldsToUpdate))
        await Promise.all(promises)

        const updateToWorkOrderMap: { [key: string]: WorkOrder } = {}

        workOrderUpdates.forEach(workOrderUpdate => {
            let reviewNotes = workOrderMap[workOrderUpdate.workOrder.workOrderId].reviewNotes
            if (workOrderUpdate.fieldsToUpdate.reviewNotes) {
                if (reviewNotes === undefined) {
                    reviewNotes = [workOrderUpdate.fieldsToUpdate.reviewNotes]
                } else {
                    reviewNotes = [...reviewNotes, workOrderUpdate.fieldsToUpdate.reviewNotes]
                }
            }
            updateToWorkOrderMap[workOrderUpdate.workOrder.workOrderId] = {
                ...workOrderMap[workOrderUpdate.workOrder.workOrderId],
                ...workOrderUpdate.fieldsToUpdate,
                reviewNotes
            }
        })

        setWorkOrderMap({
                ...workOrderMap,
                ...updateToWorkOrderMap
            }
        )
    }

    const workOrderComparisonModule = (workOrderComparison: ComparisonByDay) => {

        if (workOrderComparison.date === "2022-10-28") {
            console.log(`workOrderComparison matched ${workOrderComparison.matched.length}`)
            console.log(
                `workOrderComparison unmatchedGrowerWorkOrderIds ${workOrderComparison.unmatchedGrowerWorkOrderIds.length}`)
            console.log(
                `workOrderComparison unmatchedSystemWorkOrderIds ${workOrderComparison.unmatchedSystemWorkOrderIds.length}`)
        }

        const aggregateLabor = (matchedWorkOrders: string[], unmatchedWorkOrders: string[]) => {
            let sum: number = 0

            matchedWorkOrders.forEach(workOrderId => {
                const matchedWorkOrder = workOrderMap[workOrderId]
                if (matchedWorkOrder && matchedWorkOrder.laborHours) {
                    sum = sum + matchedWorkOrder.laborHours
                }

            })
            unmatchedWorkOrders.forEach(workOrderId => {
                const unmatchedWorkOrder = workOrderMap[workOrderId]
                if (unmatchedWorkOrder && unmatchedWorkOrder.laborHours) {
                    sum = sum + unmatchedWorkOrder.laborHours
                }
            })
            return (sum / 60).toFixed(2)
        }

        // const aggregateConcernAccuracy = (matchedWorkOrders: string[], unmatchedWorkOrders: string[]) => {
        //     let accurateCount: number = 0
        //     let inaccurateCount: number = 0
        //
        //
        //     matchedWorkOrders.forEach(workOrderId => {
        //         const matchedWorkOrder = workOrderMap[workOrderId]
        //         if (matchedWorkOrder && matchedWorkOrder.concernValid !== undefined) {
        //             if (matchedWorkOrder.concernValid) {
        //                 accurateCount++
        //             } else {
        //                 inaccurateCount++
        //             }
        //         }
        //
        //     })
        //     unmatchedWorkOrders.forEach(workOrderId => {
        //         const unmatchedWorkOrder = workOrderMap[workOrderId]
        //         if (unmatchedWorkOrder.concernValid) {
        //             accurateCount++
        //         } else {
        //             inaccurateCount++
        //         }
        //     })
        //     if (accurateCount + inaccurateCount === 0) {
        //         return "N/A"
        //     } else {
        //         return `${(accurateCount / (accurateCount + inaccurateCount) * 100).toFixed(1)} %`
        //     }
        // }
        //
        // const aggregateActionAccuracy = (matchedWorkOrders: string[], unmatchedWorkOrders: string[]) => {
        //     let accurateCount: number = 0
        //     let inaccurateCount: number = 0
        //
        //     matchedWorkOrders.forEach(workOrderId => {
        //         const matchedWorkOrder = workOrderMap[workOrderId]
        //         if (matchedWorkOrder && matchedWorkOrder.actionValid !== undefined && matchedWorkOrder.actionValid) {
        //             accurateCount++
        //         } else {
        //             inaccurateCount++
        //         }
        //
        //     })
        //     unmatchedWorkOrders.forEach(workOrderId => {
        //         const unmatchedWorkOrder = workOrderMap[workOrderId]
        //         if (unmatchedWorkOrder && unmatchedWorkOrder.actionValid !== undefined) {
        //             accurateCount++
        //         } else {
        //             inaccurateCount++
        //         }
        //     })
        //
        //     if (accurateCount + inaccurateCount === 0) {
        //         return "N/A"
        //     } else {
        //         return `${(accurateCount / (accurateCount + inaccurateCount) * 100).toFixed(1)} %`
        //     }
        // }

        return (
            <Fragment key={workOrderComparison.date}>
                <Tr bgColor={"#ADD8E6"}>
                    <Td>
                        <b>{workOrderComparison.date}</b>
                    </Td>
                    <td colSpan={2}/>
                    <Td>
                        Total labor: {aggregateLabor(workOrderComparison.matched.map(
                        orderPair => orderPair.systemWorkOrderId), workOrderComparison.unmatchedSystemWorkOrderIds)}
                    </Td>
                    <Td>
                        Total labor: {aggregateLabor(workOrderComparison.matched.map(
                        orderPair => orderPair.growerWorkOrderId), workOrderComparison.unmatchedGrowerWorkOrderIds)}
                    </Td>
                </Tr>
                {
                    workOrderComparison.matched
                        .filter(matched => {
                            return !hideExperimentWorkOrders || !workOrderMap[matched.systemWorkOrderId].action.action.includes(
                                "Experiment")
                        })
                        .map((matched, i) => {
                            const growerWorkOrder = workOrderMap[matched.growerWorkOrderId];
                            const systemWorkOrder = workOrderMap[matched.systemWorkOrderId];
                            return (
                                <Fragment key={`matched${workOrderComparison.date}${i}`}>
                                    <Tr>
                                        <th rowSpan={2} className={`chakra-th`}>
                                            {workOrderComparison.date}
                                        </th>
                                        <Td className={`chakra-td`}>
                                            {systemWorkOrder || growerWorkOrder ? checkValidity(systemWorkOrder,
                                                growerWorkOrder) : null}
                                        </Td>
                                        <WorkOrderReviewModule
                                            systemWorkOrder={workOrderMap[matched.systemWorkOrderId]}
                                            growerWorkOrder={workOrderMap[matched.growerWorkOrderId]}
                                            update={updateWorkOrderFields}/>
                                        <td colSpan={2} className={`chakra-td`}>
                                            <ConcernActionCell workOrder={workOrderMap[matched.systemWorkOrderId]}/>
                                        </td>
                                    </Tr>
                                    <Tr className="border-bottom">
                                        <Td className={`chakra-td`}>
                                            {systemWorkOrder || growerWorkOrder ? checkMatching(systemWorkOrder,
                                                growerWorkOrder) : null}
                                        </Td>
                                        <Td><WorkOrderCell workOrder={workOrderMap[matched.systemWorkOrderId]}
                                                           editable={true}
                                                           update={updateWorkOrderFields}></WorkOrderCell></Td>
                                        <Td><WorkOrderCell workOrder={workOrderMap[matched.growerWorkOrderId]}
                                                           editable={false}
                                                           update={updateWorkOrderFields}></WorkOrderCell></Td>
                                    </Tr>
                                </Fragment>
                            )
                        })
                }
                {
                    workOrderComparison.unmatchedSystemWorkOrderIds
                        .filter(systemWorkOrderId => {
                            return !hideExperimentWorkOrders || !workOrderMap[systemWorkOrderId].action.action.includes(
                                "Experiment")
                        })
                        .map((systemWorkOrderId, i) => {
                            const systemWorkOrder = workOrderMap[systemWorkOrderId];
                            return (
                                <Fragment key={`unmatched${workOrderComparison.date}${i}`}>
                                    <Tr>
                                        <th rowSpan={2} className={`chakra-th`}>
                                            {workOrderComparison.date}
                                        </th>
                                        <Td className={`chakra-td`}>
                                            {systemWorkOrder ? checkValidity(systemWorkOrder, undefined) : null}
                                        </Td>
                                        <WorkOrderReviewModule
                                            systemWorkOrder={workOrderMap[systemWorkOrderId]}
                                            growerWorkOrder={undefined}
                                            update={updateWorkOrderFields}/>
                                        <td className={`chakra-td`}>
                                            <ConcernActionCell workOrder={systemWorkOrder}/>
                                        </td>
                                        <Td/>
                                    </Tr>
                                    <Tr className="border-bottom">
                                        <Td className={`chakra-td`}>
                                            {systemWorkOrder || undefined ? checkMatching(systemWorkOrder,
                                                undefined) : null}
                                        </Td>
                                        <Td><WorkOrderCell workOrder={systemWorkOrder} editable={true}
                                                           update={updateWorkOrderFields}></WorkOrderCell></Td>
                                        <Td/>
                                    </Tr>
                                </Fragment>
                            )
                        })
                }
                {
                    workOrderComparison.unmatchedGrowerWorkOrderIds
                        .filter(growerWorkOrderId => {
                            return !hideExperimentWorkOrders || !workOrderMap[growerWorkOrderId].action.action.includes(
                                "Experiment")
                        })
                        .map((growerWorkOrderId, i) => {
                            const growerWorkOrder = workOrderMap[growerWorkOrderId];
                            return (
                                <Fragment key={`unmatched${workOrderComparison.date}${i}`}>
                                    <Tr>
                                        <th rowSpan={2} className={`chakra-th`}>
                                            {workOrderComparison.date}
                                        </th>
                                        <Td className={`chakra-td`}>
                                            {growerWorkOrder ? checkValidity(undefined, growerWorkOrder) : null}
                                        </Td>
                                        <WorkOrderReviewModule
                                            systemWorkOrder={undefined}
                                            growerWorkOrder={growerWorkOrder}
                                            update={updateWorkOrderFields}/>
                                        <Td/>
                                        <td className={`chakra-td`}>
                                            <ConcernActionCell workOrder={growerWorkOrder}/>
                                        </td>
                                    </Tr>
                                    <Tr className="border-bottom">
                                        <Td className={`chakra-td`}>
                                            {growerWorkOrder || undefined ? checkMatching(undefined,
                                                growerWorkOrder) : null}
                                        </Td>
                                        <Td/>
                                        <Td><WorkOrderCell workOrder={growerWorkOrder} editable={true}
                                                           update={updateWorkOrderFields}></WorkOrderCell></Td>
                                    </Tr>
                                </Fragment>
                            )
                        })
                }
            </Fragment>
        )
    }

    const laborSummary = (comparisons: ComparisonByDay[]) => {
        const sortedByCategory: { [key: string]: number } = {}
        comparisons.forEach(comparison => {
            comparison.matched.forEach(match => {
                const growerWorkOrder = workOrderMap[match.growerWorkOrderId]
                const laborHour = growerWorkOrder.laborHours ? growerWorkOrder.laborHours : 0
                if (sortedByCategory[growerWorkOrder.category]) {
                    sortedByCategory[growerWorkOrder.category] =
                        sortedByCategory[growerWorkOrder.category] + laborHour
                } else {
                    sortedByCategory[growerWorkOrder.category] = laborHour
                }
            })

            comparison.unmatchedGrowerWorkOrderIds.forEach(growerWorkOrderId => {
                const growerWorkOrder = workOrderMap[growerWorkOrderId]
                const laborHour = growerWorkOrder.laborHours ? growerWorkOrder.laborHours : 0
                if (sortedByCategory[growerWorkOrder.category]) {
                    sortedByCategory[growerWorkOrder.category] =
                        sortedByCategory[growerWorkOrder.category] + laborHour
                } else {
                    sortedByCategory[growerWorkOrder.category] = laborHour
                }
            })
        })

        return (<>
            <Title text={"Labor Summary"}></Title>
            <HStack gridGap="8px" w={"100%"}>
                <UnorderedList marginLeft={"50px"} textAlign={"left"}>
                    {Object.keys(sortedByCategory).map(
                        key => {
                            return (
                                <ListItem key={key}>
                                    {key} : {sortedByCategory[key].toFixed(2)}
                                </ListItem>
                            )
                        }
                    )}
                    <ListItem>
                        <b>Total</b> : {Object.keys(sortedByCategory)
                        .map(key => sortedByCategory[key] ? sortedByCategory[key] : 0)
                        .reduce((a, b) => a + b)
                        .toFixed(2)
                    }
                    </ListItem>
                </UnorderedList>
            </HStack>
        </>)
    }


    // Graphs to show
    // 1. Plant Health Quality Metrics should track grower-managed sections within 10%. Currently identified metrics
    // are: Yield Canopy height/uniformity (similar trifoliates, berry layout) Flower counts Disease Burden Pest Burden
    // 2. 40 hrs/wk/acre of worker & 4 hrs/wk/acre of facility manager, including all greenhouse ops (fertilizer
    // mixing, spraying, harvesting, training, pruning, cleaning, greenhouse maintenance) but not including
    // remotely-manageable tasks (e.g. inventory orders).
    // 3. 95% recall (5% false negative) in concern detection
    // 4. 95% recall (5% false negative) in grouping and identifying motivating concerns
    // 5. 97% accuracy in work order generation, including routine work orders
    // 6. 80% accuracy in remote task completion verification
    const linkToES = "https://my-deployment-4c5d40.kb.us-east-1.aws.found.io:9243/app/dashboards#/view/022bab90-3a04-11ed-b744-6f3e8dc6594a?_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-90d%2Fd%2Cto%3Anow))"

    return (
        <Container maxW={"container.3xl"} paddingLeft={"25px"} paddingRight={"25px"} m={0}>
            <Flex h={"100vh"} w={"100%"} direction={{base: "column"}}>
                <Link
                    color={"blue"}
                    href={linkToES}
                    isExternal>Elasticsearch Dashboard Link
                </Link>
                <VStack w={"100%"} textAlign={"left"}>
                    <Title text={siteId!.toUpperCase()}></Title>
                    {comparisons.length > 0 ? laborSummary(comparisons) : ""}
                    <HStack w={"100%"} textAlign={"right"}>
                        <Checkbox isChecked={hideExperimentWorkOrders}
                                  onChange={(e) => {
                                      setHideExperimentWorkOrders(e.target.checked)
                                  }}>Hide Experiment Work Orders</Checkbox>
                    </HStack>
                    <Table className="report-table" w={"100%"}>
                        <Thead>
                            <Tr>
                                <Th>
                                </Th>
                                <th className={`chakra-th`}>
                                    Status
                                </th>
                                <Th>
                                </Th>
                                <Th>
                                    System-generated<br/>
                                    {workOrderGenerationRecord !== undefined ?
                                        `(As of ${new Date(workOrderGenerationRecord.runAt).toLocaleString()})` :
                                        ""
                                    }
                                </Th>
                                <Th>
                                    Grower-generated<br/>
                                    {comparisonReportRecord !== undefined ?
                                        `(As of ${new Date(comparisonReportRecord.runAt).toLocaleString()})` :
                                        ""
                                    }
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody w={"100%"}>
                            {comparisons
                            .sort((a, b) => {
                                return a.date < b.date ? 1 : -1
                            })
                            .map((comparisonByDay) =>
                                workOrderComparisonModule(comparisonByDay))}
                        </Tbody>
                    </Table>
                </VStack>
            </Flex>
        </Container>)
};
