import React from "react";
import DatePicker from "react-datepicker";

type DatePickerProps = {
    date: { startDate: Date | null, endDate: Date | null }
    setDate: (type:string, startDate?: Date | null, endDate?:Date | null) => void
    isDisabled?: boolean
}

export const DateRangePicker = ({date, setDate, isDisabled}: DatePickerProps) => {
    return (
        <DatePicker
            id={"daterangepicker"}
            className={isDisabled? "inactive" : "active"}
            disabled={isDisabled}
            selected={date.startDate}
            onChange={([s, e]) => {setDate("customize", s, e)}}
            startDate={date.startDate}
            endDate={date.endDate}
            selectsRange
        />
    )
}
