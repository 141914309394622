import {LaborReportData as LaborReportDataType} from "../datatypes/ReportData";
import {Category} from "@zordi/zordi_object_schema";

const {
    IPM,
    CROP_MONITORING,
    SANITATION,
    GREENHOUSE_CONTROL,
    PRUNING_AND_SHAPING,
    HARVESTING,
    REVIEWER_TASKS,
} = Category

export const LaborReportData: { [label: string]: LaborReportDataType } = {
    HEAD_GROWER_TASKS_REMOTE: {
        workType: "Head Grower Tasks - Remote",
        targetHours: "0.3",
        unit: "hrs/wk/bay"
    },
    HEAD_GROWER_TASKS_ONSITE: {
        workType: "Head Grower Tasks - Onsite",
        targetHours: "N/A",
        unit: ""
    },
    HEAD_GROWER_TASKS_OTHER: {
        workType: "Head Grower Tasks - Other",
        targetHours: "N/A",
        unit: ""
    },
    REVIEWER_TASKS: {
        workType: REVIEWER_TASKS,
        targetHours: "N/A",
        unit: ""
    },
    CROP_MONITORING: {
        workType: CROP_MONITORING,
        targetHours: "2.5",
        unit: "hrs/wk/bay"
    },
    PRUNING_AND_SHAPING: {
        workType: PRUNING_AND_SHAPING,
        targetHours: "2.5",
        unit: "hrs/wk/bay"
    },
    IPM: {
        workType: IPM,
        targetHours: "2.5",
        unit: "hrs/wk/bay"
    },
    GREENHOUSE_CONTROL: {
        workType: GREENHOUSE_CONTROL,
        targetHours: "2.5",
        unit: "hrs/wk/bay"
    },
    SANITATION: {
        workType: SANITATION,
        targetHours: "N/A",
        unit: ""
    },
    HARVESTING: {
        workType: HARVESTING,
        targetHours: "N/A",
        unit: ""
    },
    UNIDENTIFIED: {
        workType: "Unidentified- No Category Input",
        targetHours: "N/A",
        unit: ""
    },
}

export interface WorkOrderCount {
    completedWorkOrderCount: number
    totalWorkOrderCount: number
}

// return a string on purpose to handle cases where there is no work order
export const workOrderCountToRate = (workOrderCount: WorkOrderCount): string => {
    if (!workOrderCount || workOrderCount.totalWorkOrderCount === 0) {
        return "(-)"
    } else {
        return `(${(100 * workOrderCount.completedWorkOrderCount / workOrderCount.totalWorkOrderCount).toFixed(0)}%|${workOrderCount.totalWorkOrderCount})`
    }
}
