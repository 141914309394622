import React, {useState} from 'react';
import {Button} from "@chakra-ui/react";
import {useSelector, useDispatch} from "react-redux";
import {RootState} from "../../../utils/store";
import {toggleNewWorkOrderSidebar} from "../../../redux/pageSlice";
import {dateToSnapshot} from "../../../utils/date";
import {plantIdToGrowerId} from "../../../utils/labels";
import '../../../css/overview.css';
import {PlantInfo} from './ImageCarousel';
import {PositionsLabel, PositionsInOrder} from "../../../lib/labels/PlantImgPositions";

type NavProp = {
    next: () => void
    prev: () => void
    setComparison?: (plant: string) => void
    setPosition: (position: string) => void
    displayedPosition: string | undefined
    plant: PlantInfo | undefined
    plantId: string
    setComparedPlant: (data: string[]) => void;
}

export const prevWeekDates = (selectedDate: string) => {
    const dates: string[] = [];
    let date = new Date(selectedDate);
    date.setDate(date.getDate() + 1);
    [...Array(3)].forEach(() => {
        date.setDate(date.getDate() - 7);
        dates.push(dateToSnapshot(date))
    })
    return dates;
}

export const ImageCarouselNav = ({
                                     prev,
                                     next,
                                     setPosition,
                                     displayedPosition,
                                     plant,
                                     plantId,
                                     setComparedPlant
                                 }: NavProp) => {
    const dispatch = useDispatch()
    const date = useSelector((state: RootState) => state.siteOverview.date);
    const [selectedRow, setSelectedRow] = useState<number>(0);
    const [selectedColumn, setSelectedColumn] = useState<number>(1);

    const handleOnClick = (column: number, row: number, position: string, date: string) => {
        if (column === 0) {
            setPosition(position);
        } else {
            setSelectedColumn(column);
            setSelectedRow(row);
            setComparedPlant([date, position]);
        }
    };

    const positionText = (column: number, position: string) => {
        let positionText: string = PositionsLabel[position as keyof typeof PositionsLabel];
        if (!PositionsLabel[position as keyof typeof PositionsLabel]) {
            positionText = position;
        }
        switch (column) {
            case 0:
                break;
            case 1:
                positionText = '<';
                break;
            case 2:
                positionText = '<<';
                break;
            case 3:
                positionText = '<<<';
                break;
            default:
                break;
        }
        return positionText;
    }

    const handleOpenNewWorkOrderSidebar = () => {
        dispatch(toggleNewWorkOrderSidebar(true))
    }

    return (
        <div className={"imgcarousel-nav"}>
            <div className={"imgcarousel-nav-sidebar"}>
                <Button onClick={handleOpenNewWorkOrderSidebar}>+ WO</Button>
            </div>
            <div  className={'imgcarousel-nav-container'}>
                <div className={"plant-id-container"}>
                    <button onClick={prev}>Prev</button>
                    <div>
                        {plantId ? plantIdToGrowerId(plantId) : "No Plant selected"}
                    </div>
                    <button onClick={next}>Next</button>
                </div>
                <div className={"chakra-th plant-position-year"}>
                    {!!date ? date.slice(0, 4) : ""}
                </div>
                <div className={"plant-position-container"}>
                    {!!date && !!displayedPosition ? [date, ...prevWeekDates(date)].map((date, column) => {
                        return (
                            <div className={"plant-positions"} key={`${column}-date`}>
                                <div className={"chakra-th date"}> {date.slice(5)} </div>
                                {plant ? PositionsInOrder.map((position, row,) => {
                                    return (
                                        <div key={`position-${row}-${column}`}
                                             className={`plant-position-cell ${(column !== 0 && row === selectedRow && column === selectedColumn) ? "highlight" : ""} ${column === 0 && position === displayedPosition ? "highlight" : ""}`}
                                             onClick={() => handleOnClick(column, row, position, date)}
                                        >
                                            {positionText(column, position)}
                                        </div>
                                    )
                                }) : null}
                            </div>
                        )
                    }) : null}
                </div>

            </div>
        </div>
    )
}