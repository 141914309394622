import React, {useEffect, useLayoutEffect, useState} from "react";
import {Image as ChakraImage, Spinner} from "@chakra-ui/react"

export interface BoundingBoxData {
    bbox: number[];
    label: string;
    score?: number;
}

interface BoundingBoxProps {
    boundingBoxes?: BoundingBoxData[];
    img: string;
    elementId: string
}

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


export const BoundingBox = (props: BoundingBoxProps) => {
    const [width, height] = useWindowSize();

    useEffect(() => {
        drawBoundingBoxes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height]);

    useEffect(() => {
        clearBB()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.img])

    const labelImage = (
        canvas: HTMLCanvasElement,
        label: string,
        x: number,
        y: number,
        width: number,
        height: number,
        ratio: number
    ) => {
        const ctx = canvas.getContext("2d");
        if (!ctx) return;
        ctx.strokeStyle = "red";
        const newX = x / ratio;
        const newY = y / ratio;
        const newWidth = width / ratio;
        const newHeight = height / ratio;
        ctx.rect(newX, newY, newWidth, newHeight);
        ctx.stroke();
        const labelWidth = ctx.measureText(label).width + 3;
        ctx.fillStyle = "red";
        ctx.fillRect(newX, newY - 14, labelWidth, 14);
        ctx.font = "14px";
        ctx.fillStyle = "white";
        ctx.fillText(label, newX, newY - 4);
    };

    const clearBB = () =>{
        const canvas = document.getElementById(
            props.elementId
        ) as HTMLCanvasElement;
        if (!canvas) return;
        const ctx = canvas.getContext("2d");
        if (!ctx) return;
        ctx.clearRect(0, 0, canvas.width, canvas.height)
    }

    const drawBoundingBoxes =
        () => {
            const canvas = document.getElementById(
                props.elementId
            ) as HTMLCanvasElement;
            if (!canvas) return;
            const image = document.getElementById(
                `${props.elementId}-img`
            ) as HTMLElement;
            if (!image) return;
            const ctx = canvas.getContext("2d");
            if (!ctx) return;

            canvas.style.position = "absolute";
            canvas.style.left = `${image.offsetLeft}px`;
            canvas.style.top = `${image.offsetTop}px`;
            canvas.width = image.offsetWidth;
            canvas.height = image.offsetHeight;
            clearBB()

            const ratio = 1280 / image.offsetWidth

            props.boundingBoxes?.forEach((box) => {
                if (box.bbox.length < 4) {
                    return;
                }
                labelImage(
                    canvas,
                    box.label,
                    box.bbox[0],
                    box.bbox[1],
                    box.bbox[2] - box.bbox[0],
                    box.bbox[3] - box.bbox[1],
                    ratio
                );
            });
        }

    return <>
        <ChakraImage id={`${props.elementId}-img`}
                     src={props.img}
                     fallback={<Spinner marginTop={"50px"} size='xl'></Spinner>}
                     onLoad={drawBoundingBoxes}
                     onChange={drawBoundingBoxes}
        ></ChakraImage>
        <canvas id={props.elementId}></canvas>

    </>;
};
