import {get} from './baseRequests';
import {SideDetail} from "../types/SideDetail";
import {GreenhouseSide} from "../types/legacy/HeatmapLayout";

export const parseSideDetail = (json: any): SideDetail => {
    return json as SideDetail;
}

export const fetchSideDetail = async (selected: GreenhouseSide): Promise<SideDetail> => {
    const gutterId = selected.gutterId;
    const bagId = selected.bagId;
    const sideId = selected.sideId;
    const greenhouseId = selected.greenhouseId;
    return get('sideDetail',
        new URLSearchParams(
            [["gutterId", gutterId.toString()],
                ["bagId", bagId.toString()],
                ["sideId", sideId.toString()],
                ["greenhouseId", greenhouseId.toString()],
                ["beforeTime", new Date().toISOString()]
            ]))
        .then(json => parseSideDetail(json));
}
