import {createSlice} from '@reduxjs/toolkit';
import {
    MDCreateLaborDataResponse,
    MDLaborDataEntry,
    MDReadLaborDataByRowResponse,
    MDUpdateLaborDataResponse,
    RowOption
} from "@zordi/zordi_object_schema";
import {createLaborData, getLaborDataByRow, getRowOptions, updateLaborData} from "./asyncThunk/laborData";

type LaborDataSliceState = {
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: any
    laborDataOverviewByRow: MDLaborDataEntry[]
    worksOnOtherRow: {
        isGeneral: boolean,
        rowNumber?: number
    } []
    rowOptions: RowOption[]
}

const initialState: LaborDataSliceState = {
    status: 'idle',
    error: '',
    laborDataOverviewByRow: [],
    worksOnOtherRow: [],
    rowOptions: []
}
const laborDataSlice = createSlice({
    name: 'workOrder',
    initialState,
    reducers: {
        setIdle: (state) => {
            state.status = 'idle'
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getLaborDataByRow.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getLaborDataByRow.fulfilled, (state, action) => {
                state.status = 'succeeded'
                const response = action.payload as MDReadLaborDataByRowResponse
                state.laborDataOverviewByRow = response.laborEntries
                state.worksOnOtherRow = response.otherRows
                state.error = ''
            })
            .addCase(getLaborDataByRow.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload
            })
            .addCase(createLaborData.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(createLaborData.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.error = ''
                const response = action.payload as MDCreateLaborDataResponse
                state.laborDataOverviewByRow = state.laborDataOverviewByRow.map((item) => {
                    if (item.workOrderId === action.meta.arg.workOrderId) {
                        const entry: MDLaborDataEntry = {
                            ...item,
                            ...response
                        }
                        return entry
                    } else {
                        return {
                            ...item,
                        }
                    }
                })
            })
            .addCase(createLaborData.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload
            })
            .addCase(updateLaborData.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateLaborData.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.error = ''
                const response = action.payload as MDUpdateLaborDataResponse

                state.laborDataOverviewByRow = state.laborDataOverviewByRow.map((item) => {
                    if (item.laborDataId === action.meta.arg.laborDataId) {
                        const entry: MDLaborDataEntry = {
                            ...item,
                            ...response
                        }
                        return entry
                    } else {
                        return {
                            ...item,
                        }
                    }
                })
            })
            .addCase(updateLaborData.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload
            })
            .addCase(getRowOptions.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.error = ''
                state.rowOptions = action.payload as RowOption[]
            })
    }
})

export const {setIdle} = laborDataSlice.actions;

export const laborDataSliceReducer = laborDataSlice.reducer;
