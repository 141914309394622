export class TimeseriesQuery {
    constructor(
        public startTime: string,
        public endTime: string,
        public metricNames: string[],
        public interval: string,
        public siteId: string) { }

    toQueryParams(): [string, string][] {
        let queryParams: [string, string][] = [];
        if (this.startTime) {
            queryParams.push(["startTime", this.startTime])
        }

        if (this.endTime) {
            queryParams.push(["endTime", this.endTime])
        }

        if (this.metricNames) {
            this.metricNames.forEach((metricName, index) => {
                queryParams.push([`metricNames[${index}]`, metricName])
            })
        }

        if (this.interval) {
            queryParams.push(["interval", this.interval])
        }

        if (this.siteId) {
            queryParams.push(["siteId", this.siteId])
        }

        return queryParams;
    }
}
