import React from "react";
import {Flex, Heading, Stack, Text, useColorModeValue,} from "@chakra-ui/react";

const NotFoundPage: React.FC = () => {
    return (
        <Flex
            minH={"100vh"}
            align={"center"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <Stack spacing={8} mx={"auto"} maxW={"xl"} py={12} px={6}>
                <Stack align={"center"}>
                    <Heading fontSize={"4xl"}>404 | Page Not Found 😢</Heading>
                    <Text fontSize={"lg"} color={"gray.600"}>Please visit home</Text>
                </Stack>
            </Stack>
        </Flex>
    );
};

export default NotFoundPage;
