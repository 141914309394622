import React, {useEffect, useState} from "react";
import {Box, VStack} from '@chakra-ui/react'
import {TimeSeriesProvider, useTimeseriesContext} from "../contexts/TimeSeriesProvider";
import {Title} from "./typography/title";
import {ZordiChartJSLineChart} from "./ZordiChartJSLineChart";
import {MetricDataSet, TimeseriesData} from "../types/TimeseriesData";


type PlantFeatureMetricProps = {
    startTime: Date,
    endTime: Date,
    title: string,
    metricName: string[],
    showDiff: boolean
}

export const PlantFeatureMetric = ({startTime, endTime, title, metricName, showDiff}: PlantFeatureMetricProps) => {
    const updatedStartTime = new Date(startTime);
    // PlantFeatureMetric doesn't have many data points. Fix the interval to at least 3 month.
    if ((endTime.getTime() - startTime.getTime()) < 2592000000 * 3) {
        updatedStartTime.setDate(endTime.getDate() - 90);
    }

    return (
        <VStack w='full'>
            <Title text={title}></Title>
            <Box w='100%' p={4} color='blue'>
                <TimeSeriesProvider startTime={updatedStartTime} endTime={endTime}
                                    siteId={"BOS1"}
                                    metricNames={metricName}>
                    <Graph showDiff={showDiff}></Graph>
                </TimeSeriesProvider>
            </Box>
        </VStack>
    )
}

type GraphOptions = {
    showDiff: boolean
}

const Graph = ({showDiff}: GraphOptions) => {
    const timeseriesContext = useTimeseriesContext();
    const [timeseriesChartJs, setTimeseriesChartJs] = useState<TimeseriesData | null>(null);

    useEffect(() => {
        timeseriesContext.getTimeseriesChartJs().then((timeseriesData) => setTimeseriesChartJs(timeseriesData));
    }, [timeseriesContext]);

    // instead of the raw data, return diff of consecutive terms
    // this is a temporary measure
    const getDiffCalculated = () => {
        if (!showDiff) {
            return timeseriesChartJs;
        }
        if (timeseriesChartJs) {
            const newTimeseries: MetricDataSet[] = timeseriesChartJs.metrics.map(metric => {
                const metricDataPoints = metric.data;
                let prev:number = 0;
                const diffData = []
                diffData.push(metricDataPoints[0])
                for (let i = 0; i < metricDataPoints.length - 1; i++) {
                    if (i > 0) {
                        if (metricDataPoints[i]) {
                            diffData.push((metricDataPoints[i] - prev) < 0 ? metricDataPoints[i] : metricDataPoints[i] - prev)
                        } else {
                            diffData.push(metricDataPoints[i])
                        }
                    }
                    if (metricDataPoints[i]) {
                        prev = metricDataPoints[i]
                    }

                }
                return {
                    id: metric.id + "_diff",
                    data: diffData
                }
            })

            return {
                labels: timeseriesChartJs.labels,
                metrics: [...newTimeseries]
            }
        } else {
            return null
        }

    }

    return (

        <div
            style={{
                minWidth: 0,
                minHeight: "300px",
                width: "100%",
                height: "100%",
            }}
        >
            <ZordiChartJSLineChart timeseriesData={getDiffCalculated()} sensorSelected={()=>{}}></ZordiChartJSLineChart>
        </div>
    );
};
export default PlantFeatureMetric;
