import React, {useEffect, useState} from 'react';
import {
    Button,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import {ZordiPopoverTrigger as PopoverTrigger} from "../common/ZordiPopeverTrigger";
import "../../css/work_order_overview.css"
import {BsCaretDownFill, BsFillCaretUpFill} from "react-icons/bs";
import {WorkOrderStatus} from "@zordi/zordi_object_schema/src/to/References";
import {MDReadWorkOrderResponse} from "@zordi/zordi_object_schema";
import {convertEnum} from "../../utils/transferObject";
import {sort} from "../../utils/table";

type CurrentWorkOrderTableProp = {
    unorderedWorkOrders: MDReadWorkOrderResponse[]
    updateStatus: ((id: string, status: WorkOrderStatus) => void) | null
}

export const CurrentWorkOrderTable = ({unorderedWorkOrders, updateStatus}: CurrentWorkOrderTableProp) => {
    const [workOrders, setWorkOrders] = useState<MDReadWorkOrderResponse[]>(unorderedWorkOrders)
    const [sortOrder, setSortOrder] = useState<{ [key: string]: boolean }>(initialSortOrder)

    useEffect(() => {
        setWorkOrders(unorderedWorkOrders)
    }, [unorderedWorkOrders])

    const headrow = () => {
        const sortIcon = (field: string) => !sortOrder[field] ? <BsCaretDownFill style={{display: "inline"}}/> :
            <BsFillCaretUpFill style={{display: "inline"}}/>
        const handleSortClick = (fieldOne: string, fieldTwo?: string) => {
            setSortOrder({...sortOrder, [fieldOne]: !sortOrder[fieldOne]})
            setWorkOrders(sort(sortOrder[fieldOne], workOrders, fieldOne, fieldTwo,))
        }
        return (
            <Tr className={'overview-table-row'}>
                <Th onClick={() => handleSortClick("status")} className={"pointer"} style={{maxWidth: "6rem"}}>
                    Status {sortIcon("status")}</Th>
                <Th onClick={() => handleSortClick("date")} className={"pointer"}>
                    Date {sortIcon("date")}</Th>
                <Th onClick={() => handleSortClick("priority")} className={"pointer"}>
                    Prio {sortIcon("priority")}</Th>
                <Th onClick={() => handleSortClick("region", "label")} className={"pointer"}>
                    Region {sortIcon("region")}</Th>
                <Th onClick={() => handleSortClick("action", "label")}
                    className={'wo-overview-action pointer'}>
                    Action {sortIcon("action")}</Th>
                <Th onClick={() => handleSortClick("assignee")} className={"pointer"}>
                    Assignee {sortIcon("assignee")}</Th>
                <Th>Progress</Th>
                {updateStatus ? <Th></Th> : <></>}
            </Tr>
        )
    }

    const workOrderRow = (workOrder: MDReadWorkOrderResponse) => {
        const bays = convertRowData(workOrder)

        return (
            <Tr key={`${workOrder.workOrderId}`} className={'overview-table-row'}>
                <Td style={{maxWidth: "6rem"}}>{convertEnum(workOrder.status)}</Td>
                <Td>{workOrder.date}</Td>
                <Td>{workOrder.priority}</Td>
                <Td className={'wo-overview-region'}>{workOrder.region.label}</Td>
                <Td className={'wo-overview-action'}>{workOrder.action.label}</Td>
                <Td>{workOrder.assignee.name}</Td>
                <Td>
                    <div className={'incomplete-rows-td'}>
                        {Object.entries(bays).map(([bay, rowData]) => bayPopover(bay, rowData))}
                    </div>
                </Td>
                {updateStatus ? (
                    <Td>
                        {workOrder.status !== WorkOrderStatus.CANCELLED ? (
                            <Button variant={'outline'} colorScheme={'red'} className={"cancel"}
                                    onClick={() => updateStatus(workOrder.workOrderId, WorkOrderStatus.CANCELLED)}>
                                Cancel
                            </Button>
                        ) : (
                            <Button variant={'outline'} colorScheme={'teal'} className={"cancel"}
                                    onClick={() => updateStatus(workOrder.workOrderId, WorkOrderStatus.ACTIVE)}>
                                Activate
                            </Button>
                        )}
                    </Td>) : <></>}
            </Tr>
        )
    }

    return (
        <TableContainer>
            <Table variant='simple'>
                <Thead>
                    {headrow()}
                </Thead>
                <Tbody>
                    {workOrders.map(workOrder => workOrderRow(workOrder))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

const initialSortOrder = {
    date: true,
    priority: true,
    region: true,
    action: true,
    assignee: true,
}

export const bayPopover = (bay: string, rowData: { complete: string[], incomplete: string[] }) => {
    const {complete, incomplete} = rowData
    let percentage = (((complete.length / (incomplete.length + complete.length))) * 100)
    percentage === 100 ? percentage.toFixed(0) : percentage.toFixed(1);
    return (
        <Popover placement={"left-start"} key={`bay-${bay}`}>
            <PopoverTrigger>
                <Button className={"incomplete-rows-btn"}>
                    <div style={{display: "flex", width: "100%", justifyContent: "space-between", gap: "5px"}}>
                        <span style={{color: "#3d3d3d"}}>Bay {bay}</span>
                        <span style={{color: "#575757"}}> {percentage}%</span>
                    </div>
                </Button>
            </PopoverTrigger>
            <PopoverContent color='white' bg='blue.800' borderColor='blue.800'>
                <PopoverArrow/>
                <PopoverCloseButton/>
                <PopoverHeader>Bay {bay}: {percentage}%</PopoverHeader>

                <PopoverHeader pt={4} fontWeight='bold' border='0'>Incomplete rows:</PopoverHeader>
                <PopoverBody>
                    <div className={"incomplete-rows-container"}>
                        {incomplete.sort().map((row, i) => <p key={`${bay}-${row}-${i}`}>{row}</p>)}
                    </div>
                </PopoverBody>
                <PopoverHeader pt={4} fontWeight='bold' border='0'>Complete rows:</PopoverHeader>
                <PopoverBody>
                    <div className={"incomplete-rows-container"}>
                        {complete.sort().map((row, i) => <p key={`${bay}-${row}-${i}`}>{row}</p>)}
                    </div>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

const convertRowData = (workOrder: MDReadWorkOrderResponse) => {
    const bays: { [bay: number]: { complete: string[], incomplete: string[] } } = {}
    if (workOrder.incompleteRows !== undefined) {
        workOrder.incompleteRows.forEach((row) => {
            const num = row.rowNumber === 1 ? 1 : Math.ceil(row.rowNumber / 2)
            const rowLabel = row.rowNumber % 2 === 0 ? `${num}R` : `${num}L`
            if (!!bays[row.bayNumber]) {
                bays[row.bayNumber].incomplete.push(rowLabel)
            } else {
                bays[row.bayNumber] = {
                    incomplete: [rowLabel],
                    complete: []
                }
            }
        })
    }
    if (workOrder.completeRows !== undefined) {
        workOrder.completeRows.forEach((row) => {
            const num = row.rowNumber === 1 ? 1 : Math.ceil(row.rowNumber / 2)
            const rowLabel = row.rowNumber % 2 > 0 ? `${num}L` : `${num}R`
            if (!!bays[row.bayNumber]) {
                bays[row.bayNumber].complete.push(rowLabel)
            } else {
                bays[row.bayNumber] = {
                    incomplete: [],
                    complete: [rowLabel]
                }
            }
        })
    }
    return bays
}
