import React, {createContext, useContext} from "react";
import {SideDetail} from "../types/SideDetail";
import {fetchSideDetail} from "../api/side";
import {GreenhouseSide} from "../types/legacy/HeatmapLayout";

export interface SideProviderState {
    getSideDetail: (selected: GreenhouseSide) => Promise<SideDetail>;
}

const SideDetailContext = createContext<SideProviderState | null>(null);

type SideProviderProps = {
    children?: React.ReactNode;
}

const SideProvider: React.FC<SideProviderProps> = ({children}) => {
    const getSideDetail = (selected: GreenhouseSide): Promise<SideDetail> => {
        return fetchSideDetail(selected);
    }

    return (
        <SideDetailContext.Provider value={{getSideDetail}}>{children}</SideDetailContext.Provider>
    );
};

const useSideContext = () => {
    const context = useContext(SideDetailContext);

    if (!context) {
        throw new Error("Unable to use useOrderContext without OrderProvider");
    }

    return context;
};

export {useSideContext, SideProvider};
