import { createAsyncThunk } from '@reduxjs/toolkit'
import {get, post} from "../../api/baseRequests";

export const fetchConcerns = createAsyncThunk('/workorder/concern/all?siteId=OAK1', async () => {
    return get('workorder/concern/all?siteId=OAK1')
})

interface UpdateObject {
    id: string,
    date: string,
    fieldsToUpdate: { [key: string]: any }
}
export const updateConcern = createAsyncThunk('/workorder/concern/update', async (
    concernData: UpdateObject
) => {
    const {fieldsToUpdate} = concernData
    return post('workorder/concern/update', {
        concernId: concernData.id,
        snapshotDate: concernData.date,
        ...fieldsToUpdate
    });
})
