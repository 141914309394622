import React, {useEffect, useState} from "react";
import {Box, Button, HStack, VStack} from '@chakra-ui/react';
import {TimeSeriesProvider, useTimeseriesContext} from "../../../contexts/TimeSeriesProvider";
import {ZordiLineChart} from "./ZordiLineChart";
import {TimeseriesDataNivo, MetricDataSet} from "../../../types/TimeseriesData";
import {Metrics, MetricsById} from "../../../lib/labels/SensorMetrics";
import "../../../css/overview.css";

const {TEMPERATURE, HUMIDITY, DLI, PPFD} = Metrics

type HighLevelMetricProps = {
    startTime: Date,
    endTime: Date,
    siteId: string,
    sensorSelected: (sensorId: string) => void
}

enum RangeOption {
    ONE_DAY,
    THREE_DAYS,
    ONE_WEEK
}

const intervalOptionToNumberOfMilliseconds = {
    [RangeOption.ONE_DAY]: 86400 * 1000,
    [RangeOption.THREE_DAYS]: 86400 * 3 * 1000,
    [RangeOption.ONE_WEEK]: 86400 * 7 * 1000,
}

const calculateStartTime = (range: number, endTime: Date) => {
    return new Date(endTime.getTime() - intervalOptionToNumberOfMilliseconds[range as keyof typeof intervalOptionToNumberOfMilliseconds])
}

export const HighLevelMetric = ({endTime, siteId, sensorSelected}: HighLevelMetricProps) => {
    const [range, setRange] = useState<RangeOption>(RangeOption.ONE_DAY)
    const [startTime, setStartTime] = useState(calculateStartTime(0, endTime))
    const [metrics, setMetrics] = useState<string[]>([
        TEMPERATURE.id,
        HUMIDITY.id,
        DLI.id,
        PPFD.id,
    ])

    useEffect(() => {
        setStartTime(calculateStartTime(range, endTime))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endTime])

    const handleMetricSelection = (metric: string) => {
        if (metrics.length > 1) {
            metrics.includes(metric) ? setMetrics(metrics.filter(listedMetric => listedMetric !== metric)) : setMetrics([...metrics, metric])
        } else {
            if (!metrics.includes(metric)) { setMetrics([...metrics, metric]) }
        }
    }

    const handleDateRangeSelection = (range: number) => {
        setRange(range)
        setStartTime(calculateStartTime(range, endTime))
    }

    return (
        <VStack w='full' p={"10px"}>
            <HStack w={"100%"} justify={"space-between"}>
                <div>
                    {Object.keys(Metrics).map((metrickey: string, i: number) => {
                        const {id, label} = Metrics[metrickey as keyof typeof Metrics]
                        return <Button className={"nivo-button"} isActive={metrics.includes(id)} onClick={() => handleMetricSelection(id)}
                                       key={`${i}+${id}`}>{label}</Button>
                    })}
                </div>
                <div>
                    <Button className={"nivo-button"} isActive={range === RangeOption.ONE_DAY}
                            onClick={() => handleDateRangeSelection(RangeOption.ONE_DAY)}>24
                        hrs</Button>
                    <Button className={"nivo-button"} isActive={range === RangeOption.THREE_DAYS}
                            onClick={() => handleDateRangeSelection(RangeOption.THREE_DAYS)}>3 days</Button>
                    <Button className={"nivo-button"} isActive={range === RangeOption.ONE_WEEK}
                            onClick={() => handleDateRangeSelection(RangeOption.ONE_WEEK)}>1
                        week</Button>
                </div>
            </HStack>
            <Box w='100%' p={4} color='blue'>
                <TimeSeriesProvider startTime={startTime} endTime={endTime}
                                    siteId={siteId}
                                    metricNames={[TEMPERATURE.id, HUMIDITY.id, DLI.id, PPFD.id]}>
                    <Graph sensorSelected={sensorSelected} metrics={metrics} startTime={startTime}></Graph>
                </TimeSeriesProvider>
            </Box>
        </VStack>
    )
}

type GraphProps = {
    sensorSelected?: (sensorId: string) => void
    metrics: string[]
    startTime: Date
}


const parseForNivo = (data: MetricDataSet[], labels: string[]) => {
    const order = {
        [TEMPERATURE.id]: 0,
        [HUMIDITY.id]: 0,
        [PPFD.id]: 0,
        [DLI.id]: 0,
    }
    const nivoData: TimeseriesDataNivo[] = []
    data.forEach((sensorData) => {
        const parsedSensorType = sensorData.id.split("-")
        const sensorId = parsedSensorType[1]
        const metric = Metrics[MetricsById[parsedSensorType[0] as keyof typeof MetricsById] as keyof typeof Metrics]
        const { id, label, colors } = metric
        const color = colors[order[id]]
        order[id]++
        const data: { x: string, y: number | null }[] = []
        sensorData.data.forEach((value: number, i: number) => {
            if (id === Metrics.PPFD.id) {
                value = value / 10
            }
            const y:number | null = value === null? null : parseFloat(value.toFixed(4))
            data.push({x: labels[i], y })
        })
        nivoData.push({
            id: `${label}-${sensorId}`,
            color,
            data,
        })
    })
    return nivoData
}

const Graph = ({sensorSelected, metrics, startTime}: GraphProps) => {
    const timeseriesContext = useTimeseriesContext()
    const [timeseries, setTimeseries] = useState<any | null>(null)

    useEffect(() => {
        timeseriesContext.getMonnitTimeseries()
            .then((timeseriesData) => {
                const {labels, metrics: timeseriesMetricsData} = timeseriesData
                const filtered: MetricDataSet[] = timeseriesMetricsData.filter(item => metrics.includes(item.id.split("-")[0]))
                const parsed = parseForNivo(filtered, labels)
                setTimeseries(parsed)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metrics, startTime])
    return (
        <ZordiLineChart timeseriesData={timeseries}></ZordiLineChart>
    )
}
export default HighLevelMetric
