export const PositionsInOrder = [
    "side_view_rgb",
    "fruitFlowerVis",
    "side_view_rgbd",
    "canopyHeightIndexVis",
    "top_view_rgb",
    "top_view_rgbd",
    "leafAreaIndexVis",
    "trap_view_rgb",
    "stickyTrapVis",
    "bottom_view_rgb"
]

export const Positions = {
    SIDE_RGB: {id: "side_view_rgb", label: "Side View RGB"},
    SIDE_RGBD: {id: "side_view_rgbd", label: "Side View RGBD"},
    CANOPY_HEIGHT: {id: "canopyHeightIndexVis", label: "Canopy Height ML"},
    FRUIT_FLOWER: {id: "fruitFlowerVis", label: "Fruit/Flower ML"},
    TOP_RGB: {id: "top_view_rgb", label: "Top View RGB"},
    TOP_RGBD: {id: "top_view_rgbd", label: "Top View RGBD"},
    LEAF_AREA_INDEX: {id: "leafAreaIndexVis", label: "Leaf Area ML"},
    TRAP: {id: "trap_view_rgb", label: "Trap View RGB"},
    TRAP_ML: {id: "trap_view_rgb", label: "Trap ML"},
    BOTTOM: {id: "bottom_view_rgb", label: "Bottom View RGB"},
};

type PositionType = {
    [position: string]: string;
}

export const PositionsLabel: PositionType = {
    "side_view_rgb": "Side View RGB",
    "fruitFlowerVis": "Fruit/Flower ML",
    "side_view_rgbd": "Side View RGBD",
    "canopyHeightIndexVis": "Canopy Height ML",
    "top_view_rgb": "Top View RGB",
    "top_view_rgbd": "Top View RGBD",
    "leafAreaIndexVis": "Leaf Area ML",
    "trap_view_rgb": "Trap View RGB",
    "stickyTrapVis": "Trap ML",
    "bottom_view_rgb": "Bottom View RGB",
};
