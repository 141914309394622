import {WorkOrder, WorkOrderMatch, WorkOrderReviewStatus} from "@zordi/zordi_object_schema";
import {
    Button,
    Divider,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    Portal,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Td,
    Text,
    Textarea,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {ZordiButtonSpinner} from "../common/ZordiButtonSpinner";
import '../../css/site_report.css';
import {ZordiPopoverTrigger} from "../common/ZordiPopeverTrigger";
import {appendTimestamp} from "../../utils/site_report";

type WorkOrderCellProps = {
    systemWorkOrder: WorkOrder | undefined
    growerWorkOrder: WorkOrder | undefined
    update: (workOrderUpdates: {
        workOrder: WorkOrder,
        fieldsToUpdate: { [key: string]: any }
    }[]) => Promise<void>
}
export const WorkOrderReviewModule = ({systemWorkOrder, growerWorkOrder, update}: WorkOrderCellProps) => {
    // Overall, status stored in both work orders are FOR SYSTEM Work orders.
    // System Work Order can be
    // - True Positive if it's a valid order (even without a matching order)
    // - False Positive if the work order should not be carried out.
    // - Not true/false Negative, because there would be no system work order to pair the status with

    // Grower Work Order can be
    // - True positive to show that there's a matching valid grower work order
    // - False Negative to show that grower work order is valid, and system work order does not exist
    // - Not False Positive because it's handled by System work order
    // - Not False Negative as we are not judging Grower Work Orders. If the grower missed, it is "okay" for
    // the system to miss as well

    // Options to select when there are matching orders
    // 1. "Valid, with matching" - True positive, True positive
    // 2. "Valid, with doesn't really match" - True positive
    // 3. "Invalid" - False Positive

    // Options to select when only system work order
    // 1. "Valid" - True positive
    // 2. "Invalid" - False Positive

    // Options to select when only grower work order
    // 1. "Grower Work order valid" - False Negative
    // 2. "Grower Work order invalid" - True Negative

    const [updating, setUpdating] = useState<boolean>(false)
    const [value, setValue] = React.useState(getDefaultOption(systemWorkOrder, growerWorkOrder))

    const [concernValid, setConcernValid] = useState<boolean>(
        systemWorkOrder?.concernValid === undefined ? true : systemWorkOrder?.concernValid)
    const [actionValid, setActionValid] = useState<boolean>(
        systemWorkOrder?.actionValid === undefined ? true : systemWorkOrder?.actionValid)
    const [note, setNote] = useState("");

    if (systemWorkOrder === undefined && growerWorkOrder === undefined) {
        return (<span></span>)
    }

    let options: [string, (workOrder: WorkOrder) => WorkOrder, (workOrder: WorkOrder) => WorkOrder][] = []
    let workOrderReviewStatus: WorkOrderReviewStatus;

    const nullFunction = (workOrder: WorkOrder) => workOrder

    const matching = (workOrder: WorkOrder) => {
        return {
            ...workOrder,
            workOrderMatch: WorkOrderMatch.TRUE_POSITIVE,
            hasMatching: true
        }
    }

    const notMatching = (workOrder: WorkOrder) => {
        return {
            ...workOrder,
            workOrderMatch: WorkOrderMatch.TRUE_POSITIVE,
            hasMatching: false
        }
    }

    const invalid = (workOrder: WorkOrder) => {
        return {
            ...workOrder,
            workOrderMatch: WorkOrderMatch.FALSE_POSITIVE,
            hasMatching: false
        }
    }

    const nonExistent = (workOrder: WorkOrder) => {
        return {
            ...workOrder,
            workOrderMatch: WorkOrderMatch.FALSE_NEGATIVE,
            hasMatching: false
        }
    }

    let reviewNotes: string[] | undefined;

    if (systemWorkOrder && growerWorkOrder) {
        workOrderReviewStatus =
            systemWorkOrder.workOrderReviewStatus ? systemWorkOrder.workOrderReviewStatus : WorkOrderReviewStatus.NEEDS_REVIEW
        reviewNotes = systemWorkOrder.reviewNotes
        options = [
            ["Valid, matching", matching, matching],
            ["Valid, not matching", notMatching, notMatching],
            ["Invalid", invalid, nonExistent]
        ]
    } else if (systemWorkOrder) {
        workOrderReviewStatus =
            systemWorkOrder.workOrderReviewStatus ? systemWorkOrder.workOrderReviewStatus : WorkOrderReviewStatus.NEEDS_REVIEW
        reviewNotes = systemWorkOrder.reviewNotes
        options = [
            ["Valid", matching, nullFunction],
            ["Invalid", invalid, nullFunction]
        ]
    } else if (growerWorkOrder) {
        workOrderReviewStatus =
            growerWorkOrder.workOrderReviewStatus ? growerWorkOrder.workOrderReviewStatus : WorkOrderReviewStatus.NEEDS_REVIEW
        reviewNotes = growerWorkOrder.reviewNotes
        options = [
            ["Grower work order valid", nullFunction, nonExistent],
            ["Grower work order invalid", nullFunction, notMatching]
        ]
    } else {
        // should never happen
        return (
            <></>
        )
    }

    const save = async (systemWorkOrder: WorkOrder | undefined, growerWorkOrder: WorkOrder | undefined) => {
        setUpdating(true)

        const workOrderUpdates = []
        if (systemWorkOrder) {
            workOrderUpdates.push({
                workOrder: systemWorkOrder,
                fieldsToUpdate: {
                    "workOrderMatch": systemWorkOrder.workOrderMatch,
                    "matchReviewed": true,
                    "workOrderReviewStatus": WorkOrderReviewStatus.REVIEWED,
                    "hasMatching": systemWorkOrder.hasMatching,
                    "concernValid": concernValid,
                    "actionValid": actionValid,
                    "reviewNotes": appendTimestamp(note)
                }
            })
        }

        if (growerWorkOrder) {
            workOrderUpdates.push(
                {
                    workOrder: growerWorkOrder,
                    fieldsToUpdate: {
                        "workOrderMatch": growerWorkOrder.workOrderMatch,
                        "matchReviewed": true,
                        "workOrderReviewStatus": WorkOrderReviewStatus.REVIEWED,
                        "hasMatching": growerWorkOrder.hasMatching,
                        "reviewNotes": appendTimestamp(note)
                    }
                }
            )
        }

        await update(workOrderUpdates)
        setUpdating(false)
    }

    const button = () => {
        if (workOrderReviewStatus === WorkOrderReviewStatus.REVIEWED) {
            return (<Button
                className={`badge-like-button green`}
                style={{marginTop: "0.6rem"}}>
                Reviewed
            </Button>)
        } else if (workOrderReviewStatus === WorkOrderReviewStatus.AUTO_REVIEWED) {
            return (<Button
                className={`badge-like-button blue`}
                style={{marginTop: "0.6rem"}}>
                Auto-Reviewed
            </Button>)
        } else {
            return (<Button
                className={`badge-like-button purple`}
                style={{marginTop: "0.6rem"}}>
                Needs Review
            </Button>)
        }
    }

    const displayNotes = (reviewNotes: string[] | undefined) => {
        if (reviewNotes !== undefined) {
            const reversed = reviewNotes.slice().reverse()
            return (reversed.map((reviewNote, i) => {
                return <Text key={i}>{reviewNote}</Text>
            }))
        } else {
            return ("")
        }
    }

    const reviewButton = () => {
        return (
            <>
                <Popover>
                    <ZordiPopoverTrigger>
                        {button()}
                    </ZordiPopoverTrigger>
                    <Portal>
                        <PopoverContent>
                            <PopoverArrow/>
                            <PopoverHeader>Is the work order valid?</PopoverHeader>
                            <PopoverCloseButton/>
                            <PopoverBody>
                                <RadioGroup
                                    value={value}
                                    onChange={setValue}
                                >
                                    <Stack direction='column'>
                                        {
                                            options.map((option, i) => {
                                                return (<Radio key={i} value={i.toString()}>
                                                    {option[0]}
                                                </Radio>)
                                            })
                                        }
                                    </Stack>
                                </RadioGroup>

                            </PopoverBody>
                            {systemWorkOrder !== undefined ? (
                                <>
                                    <Divider orientation='horizontal'/>
                                    <PopoverHeader>Is the system concern valid?</PopoverHeader>
                                    <PopoverBody>
                                        <Select defaultValue={concernValid ? 0 : 1}
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    setConcernValid(event.target.value === "0")
                                                }}
                                        >
                                            <option value={0}>Valid</option>
                                            <option value={1}>Invalid</option>
                                        </Select>
                                    </PopoverBody>
                                    <Divider orientation='horizontal'/>
                                    <PopoverHeader>Is the system action valid?</PopoverHeader>
                                    <PopoverBody>
                                        <Select defaultValue={actionValid ? 0 : 1}
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    setActionValid(event.target.value === "0")
                                                }}
                                        >
                                            <option value={0}>Valid</option>
                                            <option value={1}>Invalid</option>
                                        </Select>
                                        <Divider/>
                                        <Text>Notes: </Text>
                                        <Textarea placeholder="Optional" value={note}
                                                  onChange={(e) => (setNote(e.target.value))}/>
                                    </PopoverBody>
                                </>) : null}
                            <Divider orientation='horizontal'/>
                            {
                                updating ?
                                    (<ZordiButtonSpinner/>) : (
                                        <Button variant='outline' onClick={() => save(
                                            systemWorkOrder ?
                                                options[parseInt(value)][1](systemWorkOrder) :
                                                undefined
                                            , growerWorkOrder ?
                                                options[parseInt(value)][2](growerWorkOrder)
                                                : undefined
                                        )}>
                                            Save
                                        </Button>
                                    )
                            }
                            <Divider/>
                            {displayNotes(reviewNotes)}
                        </PopoverContent>
                    </Portal>
                </Popover>
            </>
        )
    }

    return (
        <Td rowSpan={2}>
            {reviewButton()}
        </Td>
    )
}

const getDefaultOption = (systemWorkOrder: WorkOrder | undefined, growerWorkOrder: WorkOrder | undefined) => {
    if (systemWorkOrder && growerWorkOrder) {
        if (systemWorkOrder.workOrderMatch === WorkOrderMatch.TRUE_POSITIVE && systemWorkOrder.hasMatching) {
            return "0"
        } else if (systemWorkOrder.workOrderMatch === WorkOrderMatch.TRUE_POSITIVE && !systemWorkOrder.hasMatching) {
            return "1"
        } else {
            return "2"
        }
    } else if (systemWorkOrder) {
        if (systemWorkOrder.workOrderMatch === WorkOrderMatch.TRUE_POSITIVE) {
            return "0"
        } else {
            return "1"
        }
    } else if (growerWorkOrder) {
        if (growerWorkOrder.workOrderMatch === WorkOrderMatch.FALSE_NEGATIVE) {
            return "0"
        } else {
            return "1"
        }
    }

    return "0"
}
