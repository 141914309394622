import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    InputGroup,
    ListItem,
    UnorderedList,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {get} from "../api/baseRequests";

type DetailsPageProps = {
    children?: React.ReactNode;
}

const DetailsPage: React.FC<DetailsPageProps> = ({children}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [bagId, setBagId] = useState<string | undefined>(
        searchParams.get("bagId") ?? undefined
    );
    const [gutterId, setGutterId] = useState<string | undefined>(
        searchParams.get("gutterId") ?? undefined
    );

    const [details, setDetails] = useState([]);

    const fetchData = async () => {
        const urlParams = new URLSearchParams();
        urlParams.append("bagId", bagId as string);
        urlParams.append("gutterId", gutterId as string);
        try {
            const data = await get("details", urlParams);
            setDetails(data.hits.hits);
        } catch (err) {
            console.error(err);
        }
    };

    // eslint-disable-next-line
    useEffect(() => {
        if (!bagId || !gutterId) {
            return;
        }

        fetchData().catch(console.error);
        // eslint-disable-next-line
    }, []);

    let detailView: JSX.Element | undefined = (
        <Box borderRadius="lg">
            <UnorderedList>
                {details.map((detail) => (
                    <ListItem>{JSON.stringify(detail)}</ListItem>
                ))}
            </UnorderedList>
        </Box>
    );
    if (details.length === 0) {
        detailView = undefined;
    }
    return (
        <Flex
            minH={"100vh"}
            align={"center"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <VStack spacing={5}>
                <Box borderRadius="lg">
                    <Heading>Details View</Heading>
                    <VStack spacing={5}>
                        <FormControl id="name">
                            <FormLabel>Bag ID</FormLabel>
                            <InputGroup borderColor="#E0E1E7">
                                <Input
                                    type="text"
                                    size="md"
                                    value={bagId}
                                    onChange={(val) => setBagId(val.target.value)}
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl id="name">
                            <FormLabel>Gutter ID</FormLabel>
                            {/* TODO: Add input validation */}
                            <InputGroup borderColor="#E0E1E7">
                                <Input
                                    type="text"
                                    size="md"
                                    value={gutterId}
                                    onChange={(val) => setGutterId(val.target.value)}
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl id="name" float="right">
                            <Button
                                onClick={async () => {
                                    setSearchParams({
                                        bagId: bagId ?? "",
                                        gutterId: gutterId ?? "",
                                    });
                                    try {
                                        await fetchData();
                                    } catch (err) {
                                        console.error(err);
                                    }
                                }}
                                variant="solid"
                                bg="#0D74FF"
                                color="white"
                                _hover={{}}
                            >
                                Update
                            </Button>
                        </FormControl>
                    </VStack>
                </Box>
                {detailView}
            </VStack>
        </Flex>
    );
};

export default DetailsPage;
