import {createSlice} from '@reduxjs/toolkit';
import {createWorkOrder, getWorkOrderOptions, listWorkOrders, updateWorkOrderStatus,} from "./asyncThunk/workOrder";
import {MDReadWorkOrderResponse, WorkOrderSource,} from "@zordi/zordi_object_schema";
import {
    WorkOrder,
    WorkOrderDataUsedOption,
    WorkOrderPriority,
    WorkOrderVerificationOption
} from "@zordi/zordi_object_schema/src/to/References";
import {convertEnum, convertWorkOrder, convertWorkOrderOptions} from "../utils/transferObject";

export type WorkOrderFormOptions = {
    category: { label: string, value: string }[]
    problem: { [key: string]: { label: string, value: string } [] }
    action: { [key: string]: { label: string, value: string } [] }
    site: { label: string, value: string }[]
    region: { [key: string]: { label: string, value: string }[] }
    worker: { label: string, value: string }[]
    priority: WorkOrderPriority[]
    status: string
    verificationMethods: string[]
    workOrderSource: WorkOrderSource.Grower
    verificationMethod: { label: string, value: string }[]
    dataUsed: { label: string, value: string }[]
}

type WorkOrderSliceState = {
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: any
    workOrders: MDReadWorkOrderResponse[]
    updatedWorkOrder: MDReadWorkOrderResponse | null
    newWorkOrder: MDReadWorkOrderResponse | null
    newRoutine: any
    options: WorkOrderFormOptions
}

const initialState: WorkOrderSliceState = {
    status: 'idle',
    error: '',
    workOrders: [],
    updatedWorkOrder: null,
    newWorkOrder: null,
    newRoutine: null,
    options: {
        category: [],
        problem: {},
        action: {},
        site: [],
        region: {},
        worker: [],
        priority: [],
        status: "scheduled",
        verificationMethods: [],
        workOrderSource: WorkOrderSource.Grower,
        verificationMethod: Object.values(WorkOrderVerificationOption).map(item => ({
            label: convertEnum(item),
            value: item as string
        })),
        dataUsed: Object.values(WorkOrderDataUsedOption).map(item => ({
            label: convertEnum(item),
            value: item as string
        })),
    }
}
export const workOrderSlice = createSlice({
    name: 'workOrder',
    initialState,
    reducers: {
        resetWorkOrder: (state) => {
            state.newWorkOrder = null
            state.updatedWorkOrder = null
        },
        setIdle: (state) => {
            state.status = 'idle'
        },
    },
    extraReducers: builder => {
        builder
            .addCase(createWorkOrder.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(createWorkOrder.fulfilled, (state, action) => {
                const newWO = convertWorkOrder(action.payload)
                state.status = 'succeeded'
                state.newWorkOrder = newWO
                state.workOrders = [...state.workOrders, newWO]
                state.error = ''
            })
            .addCase(createWorkOrder.rejected, (state, action) => {
                state.status = 'failed'
                state.newWorkOrder = null
                state.error = action.payload
            })
            .addCase(listWorkOrders.pending, (state) => {
                state.status = 'loading'
                state.error = null
            })
            .addCase(listWorkOrders.fulfilled, (state, action) => {
                const workOrders = action.payload.map((workOrder: WorkOrder) => (convertWorkOrder(workOrder)))
                state.status = 'succeeded'
                state.workOrders = workOrders
            })
            .addCase(listWorkOrders.rejected, (state, action) => {
                state.status = 'failed'
                state.workOrders = []
                state.error = action.payload
            })
            .addCase(getWorkOrderOptions.pending, (state) => {
                state.status = 'loading'
                state.error = null
            })
            .addCase(getWorkOrderOptions.fulfilled, (state, action) => {
                const updatedOptions: { [key: string]: any } = {}
                for (const [key, value] of Object.entries(action.payload)) {
                    updatedOptions[key] = value
                }
                state.status = 'succeeded'
                state.options = {...state.options, ...convertWorkOrderOptions(updatedOptions)}
            })
            .addCase(getWorkOrderOptions.rejected, (state, action) => {
                state.status = 'failed'
                state.options = initialState.options
                state.error = action.payload
            })
            .addCase(updateWorkOrderStatus.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(updateWorkOrderStatus.fulfilled, (state, action) => {
                const updatedWO = convertWorkOrder(action.payload)

                state.status = 'succeeded'
                state.updatedWorkOrder = updatedWO
                state.workOrders =
                    [...state.workOrders.filter(WO => WO.workOrderId === updatedWO.workOrderId), updatedWO]
                state.error = ''
            })
            .addCase(updateWorkOrderStatus.rejected, (state, action) => {
                state.status = 'failed'
                state.updatedWorkOrder = null
                state.error = action.payload
            })
    }
})

export const {resetWorkOrder, setIdle} = workOrderSlice.actions;

export const workOrderSliceReducer = workOrderSlice.reducer;
