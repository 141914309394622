import React, {useMemo, useState} from "react"; // we need this to make JSX compile
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import DateTimePicker from "../common/DateTimePicker";
import moment from 'moment';
import {Button, Container, Divider, Flex, Grid, GridItem} from "@chakra-ui/react";
import {AlertTable} from "../common/AlertTable";
import PlantFeatureMetric from "../common/PlantFeatureMetric";
import GreenHouseSummary from "../common/GreenHouseSummary";
import OrderTable from "../common/OrderTable";
import {OrderQuery} from "../types/OrderQuery";
import {Column} from "react-table";
import {Order} from "../types/Order";
import {Title} from "../common/typography/title";
import HighLevelLegacyMetric from "../common/HighLevelMetricLegacy";

type OverviewPageProps = {
    auth: firebase.auth.Auth;
};

export const OverviewPage = ({auth}: OverviewPageProps) => {
    const today = new Date(1651640400000);
    const [endTime, setEndTime] = useState<Date>(today);
    const [startTime, setStartTime] = useState<Date>(moment(today).subtract(1, 'day').toDate());
    const columns = useMemo(
        (): Column<Order>[] => [
            {
                Header: 'Assignee',
                id: 'assignee',
                accessor: (row) => row.assignedTo,
            },
            {
                Header: 'Description',
                id: 'description',
                accessor: (row) => row.description,
            },
            {
                Header: "",
                id: 'more button',
                Cell: (props: any) =>
                    <Button variant="zordi-basic" onClick={() => console.log(props)}>
                        Open
                    </Button>
            }

        ],
        [],
    )

    if (auth.currentUser == null) {
        return <></>;
    }

    const orderQuery: OrderQuery = {
        startTime: startTime,
        endTime: endTime
    }

    return (
        <Container maxW={"container.3xl"} paddingLeft={"25px"} paddingRight={"25px"} m={0}>
            <Flex h={"100vh"} w={"100%"} direction={{base: "column"}}>
                <DateTimePicker startTime={startTime} endTime={endTime} setStartTime={setStartTime} setEndTime={setEndTime}/>
                <Divider borderColor={"zordiGreen"} borderWidth={"1px"}/>
                <Grid
                    w={"full"}
                    templateColumns='repeat(3, 1fr)'
                >
                    <GridItem colSpan={3} py={5}>
                        <Title text="AI-monitored Greenhouse Status & Proposed Work Order"/>
                        <Grid
                            w={"full"}
                            templateColumns='repeat(2, 1fr)'
                        >
                            <GridItem colSpan={1}>
                                <AlertTable startTime={startTime} endTime={endTime}></AlertTable>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <OrderTable title={" "} columns={columns} orderQuery={orderQuery}/>
                            </GridItem>
                        </Grid>


                    </GridItem>

                    {/*<GridItem colSpan={1}>*/}
                    {/*    /!*<WeatherTable startTime={startTime} endTime={endTime} />*!/*/}
                    {/*</GridItem>*/}
                    <GridItem colSpan={1}>
                        <PlantFeatureMetric startTime={startTime} endTime={endTime}                                            metricName={["num_baby_fruit", "num_mature_flowers", "num_white_fruit", "num_pink_fruit", "num_red_fruit" ]} title={"Plant Growth Trend"} showDiff={false} />
                    </GridItem>
                    <GridItem colSpan={1}>
                        <PlantFeatureMetric startTime={startTime} endTime={endTime} metricName={["num_aphids_adult_side_a", "num_thrips_adult_side_a", "num_fungus_gnats_adult_side_a" ]} title={"Pest"} showDiff={true} />
                    </GridItem>
                    <GridItem colSpan={1}>
                        <HighLevelLegacyMetric startTime={startTime} endTime={endTime} graphName={"Environment"}metricName={["Celsius_TemperatureData", "PAR_PAR_DLI", "Humidity_Percentage"]} />
                    </GridItem>
                    <GridItem colSpan={3}>
                        <GreenHouseSummary/>
                    </GridItem>
                </Grid>
            </Flex>
        </Container>
    );
};