import React, {useEffect, useState} from "react";
import {OrderProvider, useOrderContext} from "../contexts/OrderProvider";
import {Flex, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue, VStack,} from '@chakra-ui/react'
import {Column, usePagination, useSortBy, useTable} from 'react-table'
import {Order} from "../types/Order";
import {OrderQuery} from "../types/OrderQuery";
import "./OrderTable.css"

type OrderTableProps = {
    title: string
    orderQuery: OrderQuery
    columns: ReadonlyArray<Column<Order>>
}

export const OrderTable = ({orderQuery, title, columns}: OrderTableProps) => {
    return (
        <Flex
            w='full'
            py={5}
            bg={useColorModeValue("white", "gray.900")}
        >
            <VStack w='full' ms={0}>
                <OrderProvider>
                    <OrderTableWithData columns={columns} orderQuery={orderQuery}/>
                </OrderProvider>
            </VStack>
        </Flex>
    )
}

type OrderTableWithDataProps = {
    orderQuery: OrderQuery
    columns: ReadonlyArray<Column<Order>>
}

const OrderTableWithData = ({orderQuery, columns}: OrderTableWithDataProps) => {
    const orderContext = useOrderContext();
    const [data, setData] = useState<Order[]>([]);

    useEffect(() => {
        orderContext.getOrders(orderQuery).then((orders) => setData(orders))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderQuery]);

    const {
        getTableProps, getTableBodyProps, headerGroups, prepareRow,
        page,
    } =
        useTable({columns, data, initialState: {pageIndex: 0, pageSize: 5}}, useSortBy, usePagination)

    return (
        <>
            <Table {...getTableProps()} className="table-tiny"
                   fontFamily={"Poppins"}
            >
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th key={column.id}>
                                    {column.render('Header')}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row)
                        return (
                            <Tr {...row.getRowProps()} height={"42px"}>
                                {row.cells.map((cell) => (
                                    <Td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </Td>
                                ))}
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
            {/*<Flex justifyContent="space-between" m={4} alignItems="right">*/}
            {/*    <Flex>*/}
            {/*        <Tooltip label="Previous Page">*/}
            {/*            <IconButton*/}
            {/*                onClick={previousPage}*/}
            {/*                aria-label="Previous"*/}
            {/*                icon={<FiChevronLeft/>}*/}
            {/*            />*/}
            {/*        </Tooltip>*/}
            {/*    </Flex>*/}
            {/*    <Flex justifyContent={'center'} justifyItems={'center'} flexDirection={'column'} fontFamily={"Poppins"}>*/}
            {/*        <Text mr={8} width={'100%'}>*/}
            {/*            Page{" "}*/}
            {/*            <Text fontWeight="bold" as="span">*/}
            {/*                {pageIndex + 1}*/}
            {/*            </Text>{" "}*/}
            {/*            of{" "}*/}
            {/*            <Text fontWeight="bold" as="span">*/}
            {/*                {pageOptions.length}*/}
            {/*            </Text>*/}
            {/*        </Text>*/}
            {/*    </Flex>*/}
            {/*    <Flex>*/}
            {/*        <Tooltip label="Next Page">*/}
            {/*            <IconButton*/}
            {/*                onClick={nextPage}*/}
            {/*                aria-label="next"*/}
            {/*                icon={<FiChevronRight/>}*/}
            {/*            />*/}
            {/*        </Tooltip>*/}
            {/*    </Flex>*/}


            {/*</Flex>*/}
        </>
    )
}

export default OrderTable;
