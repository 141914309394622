import React from "react"; // we need this to make JSX compile
import "firebase/compat/auth";
import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay} from "@chakra-ui/react";
import {BoundingBox, BoundingBoxData} from "../common/heatmap/BoundingBox";

type ImageModalProps = {
    isOpen: boolean,
    onClose: () => void,
    imageUrl: string,
    boundingBoxes: BoundingBoxData[]
};

export const ImageModal = ({isOpen, onClose, imageUrl, boundingBoxes}: ImageModalProps) => {

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"6xl"} blockScrollOnMount={true}>
            <ModalOverlay/>
            <ModalContent>
                <ModalCloseButton/>
                <ModalBody justifyContent={"top"} w={"100%"} h={"500px"}>
                    <BoundingBox
                        img={imageUrl}
                        elementId={"modalCanvas"}
                        boundingBoxes={boundingBoxes}
                    ></BoundingBox>
                </ModalBody>
            </ModalContent>
        </Modal>)
};