import {ComputedCell, ResponsiveHeatMapCanvas} from "@nivo/heatmap";
import React, {Dispatch, SetStateAction, useState} from "react";
import {Box, Grid, GridItem, Text} from "@chakra-ui/react";
import {GreenhouseSide} from "../../../types/legacy/HeatmapLayout";
import {DetailViewWithGutterSide} from "./DetailViewWithGutterSide";
import {ColorInterpolatorId} from "@nivo/colors/dist/types/schemes";

type ZordiHeatMapProps = {
    heatmapLayout: (GreenhouseSide | null)[][],
    selectedMetric: string,
    overviewValue: GreenhouseSide | null
}

type SingleHeatMapRow = {
    "id": string,
    "data": SingleHeatMapItem[]
}

type SingleHeatMapItem = {
    x: string,
    y: any
    side: GreenhouseSide | undefined
}

export const ZordiHeatMapWithGutterSide = ({heatmapLayout, selectedMetric, overviewValue}: ZordiHeatMapProps) => {
    const [selected, setSelected] = useState<GreenhouseSide | undefined>(undefined);

    const dataToDisplay: SingleHeatMapRow[] = [];
    for (let i = 0; i < heatmapLayout.length; i++) {
        let items: SingleHeatMapItem[] = [];
        for (let j = 0; j < heatmapLayout[0].length; j++) {
            let item: SingleHeatMapItem;
            let greenhouseSide: GreenhouseSide | null = heatmapLayout[i][j];

            if (greenhouseSide) {
                item = {
                    x: `${j}`,
                    y: greenhouseSide.values[selectedMetric],
                    side: greenhouseSide
                }
            } else {
                item = {
                    x: `${j}`,
                    y: undefined,
                    side: undefined
                }
            }
            items.push(item)
        }
        let row = {
            id: `${i}`,
            data: items
        }
        dataToDisplay.push(row);
    }

    return (
        <Grid w={"100%"} templateColumns='repeat(4, 1fr)' paddingBottom={"100px"}>
            <GridItem h={"1000px"} colSpan={3} borderRight={"2px solid #70AD47"}>
                <GreenhouseHeatMap data={dataToDisplay} updateDetailView={setSelected}
                                   selectedMetric={selectedMetric}></GreenhouseHeatMap>
            </GridItem>
            <GridItem h={"1000px"} colSpan={1}>
                <DetailViewWithGutterSide selected={selected} overviewValue={overviewValue}></DetailViewWithGutterSide>
            </GridItem>
        </Grid>
    )
}

type GreenhouseHeatMapProps = {
    data: SingleHeatMapRow[],
    updateDetailView: Dispatch<SetStateAction<GreenhouseSide | undefined>>,
    selectedMetric: string
}

const GreenhouseHeatMap = ({data, updateDetailView, selectedMetric}: GreenhouseHeatMapProps) => {
    const onClick = (cell: ComputedCell<SingleHeatMapItem>) => {
        updateDetailView(cell.data.side)
    }

    const CustomTooltip = ({cell}: { cell: ComputedCell<any> }) => {
        if (cell && cell.data && cell.data.side) {
            return (
                <Box backgroundColor={"#FFFFFF"}
                     padding={'6px 9px'}
                     borderRadius={'2px'}
                     minWidth={'160px'}
                     boxShadow={'0 3px 5px rgba(0, 0, 0, .25)'}
                     color={'black'}
                     alignContent={"flex-start"}
                >
                    <Text>
                        {'id   '}&nbsp;<strong>{cell.data.side.zordiId}</strong>
                        <br/>
                        {selectedMetric}&nbsp;<strong>{cell.data.side.values[selectedMetric]}</strong>
                    </Text>
                </Box>
            )
        } else {
            return (<span></span>)
        }

    }

    const colorScheme: { [key: string]: ColorInterpolatorId } = {
        "flower": "purples",
        "white_fruit": "greys",
        "red_fruit": "reds",
        "pink_fruit": "red_purple",
        "baby_fruit": "greens",
    }

    return (<ResponsiveHeatMapCanvas
        data={data}
        margin={{top: 60, right: 60, bottom: 60, left: 60}}
        valueFormat=">-.2s"
        axisTop={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
            legend: 'Gutter',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        axisRight={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Bag',
            legendPosition: 'middle',
            legendOffset: 40
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Bag',
            legendPosition: 'middle',
            legendOffset: -42
        }}
        colors={{
            type: 'sequential',
            scheme: colorScheme[selectedMetric],

            minValue: -2,
            maxValue: 5
        }}

        hoverTarget={"cell"}
        emptyColor="#F6F6F4"
        inactiveOpacity={1}
        enableLabels={false}
        onClick={onClick}
        tooltip={CustomTooltip}
        // borderWidth={0.3}

        legends={[
            {
                anchor: 'bottom',
                translateX: 0,
                translateY: 30,
                length: 400,
                thickness: 8,
                direction: 'row',
                tickPosition: 'after',
                tickSize: 3,
                tickSpacing: 4,
                tickOverlap: false,
                tickFormat: '>-.2s',
                title: 'Value →',
                titleAlign: 'start',
                titleOffset: 4
            }
        ]}

    />)
}