import firebase from 'firebase/compat/app';
import {HttpException} from "../types/HttpException";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// make this static
const getJwtToken = async (): Promise<string> => {
    const token = await firebase.auth()?.currentUser?.getIdToken();
    if (token == null) {
        throw new Error('Failed to fetch jwt token from firebase server');
    }
    return token;
}

export const get = async (endpoint: string, queryParams: URLSearchParams | undefined = undefined): Promise<any> => {
    let apiUrl = `${API_BASE_URL}/${endpoint}`;
    if (queryParams) {
        apiUrl += `?${queryParams.toString()}`;
    }
    return fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getJwtToken(),
        }, // TODO: add headers after firebase integration
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            const errorMsg = getErrorMessage(response);
            console.error(errorMsg);
            const httpError: HttpException = new HttpException(response.status, response.url);
            return Promise.reject(httpError)
        }
    })
}

export const externalGet = async (api_base_url: string, endpoint: string, queryParams: URLSearchParams | undefined = undefined): Promise<any> => {
    let apiUrl = `${api_base_url}/${endpoint}`;
    if (queryParams) {
        apiUrl += `?${queryParams.toString()}`;
    }
    return fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            const errorMsg = getErrorMessage(response);
            console.error(errorMsg);
            const httpError: HttpException = new HttpException(response.status, response.url);
            return Promise.reject(httpError)
        }
    })
}


export const post = async (endpoint: string, body: any): Promise<any> => {
    return fetch(`${API_BASE_URL}/${endpoint}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getJwtToken(),
        }, // TODO: add headers after firebase integration
        body: JSON.stringify(body),
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            const errorMsg = getErrorMessage(response);
            console.error(errorMsg);
            return Promise.reject(new Error(errorMsg))
        }
    })
}

export const getErrorMessage = (response: any) => {
    return `Response status ${response.status} for ${response.url}`;
}
