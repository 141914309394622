import React, {useEffect, useMemo, useState} from "react";
import {Flex, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue, VStack, Text} from "@chakra-ui/react";
import {AlertProvider, useAlertContext} from "../contexts/AlertProvider";
import {Column, usePagination, useSortBy, useTable} from "react-table";
import {Alert} from "../types/Alert";
import {FcAdvance} from "react-icons/fc";
import {BsDot} from "react-icons/bs";
import {getStatusColor} from "../utils/colors";


type AlertTableProps = {
    startTime: Date;
    endTime: Date;
};

export const AlertTable = ({startTime, endTime}: AlertTableProps) => {
    // Always show at least the last 30 days
    const updatedStartTime = new Date(startTime);
    if ((endTime.getTime() - startTime.getTime()) < 2592000000 * 3) {
        updatedStartTime.setDate(endTime.getDate() - 30);
    }

    return (
        <Flex w="full" py={5} bg={useColorModeValue("white", "gray.900")}>
            <VStack w="full" ms={0}>
                <AlertProvider startTime={updatedStartTime} endTime={endTime}>
                    <AlertTableWithData/>
                </AlertProvider>
            </VStack>
        </Flex>
    );
};

const AlertTableWithData = () => {
    const alertContext = useAlertContext();
    const [data, setData] = useState<Alert[]>([]);

    useEffect(() => {
        alertContext.getAlerts().then((alerts) => setData(alerts));
    }, [alertContext]);

    const columns = useMemo(
        (): Column<Alert>[] => [
            {
                Header: "Type",
                id: "type",
                accessor: (row) => row.type,
                // Cell: ({ value }: { value: any }) => {
                //   if (value === "high_temperature") {
                //     return <FaTemperatureHigh></FaTemperatureHigh>;
                //   }
                //   return <FiAlertOctagon></FiAlertOctagon>;
                // },

            },
            {
                Header: "Status",
                id: "status",
                accessor: (row) => row.status,
                Cell: ({value}: { value: any }) => (
                    <Flex marginLeft={"-15px"}><BsDot size={30} color={getStatusColor(value)}></BsDot>
                        <Text paddingTop={"5px"}>{value}</Text>
                        </Flex>
                    // <Button variant="zordi-basic" onClick={() => console.log(props)}>
                    //     OpenBsDot
                    // </Button>
                ),
            },
            {
                Header: 'Description',
                id: 'description',
                accessor: (row) => row.description,
            },
            {
                Header: "",
                id: "more button",
                Cell: (props: any) => (
                    <FcAdvance></FcAdvance>
                    // <Button variant="zordi-basic" onClick={() => console.log(props)}>
                    //     OpenBsDot
                    // </Button>
                ),
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable<Alert>(
        {columns, data, initialState: {pageIndex: 0, pageSize: 5}},
        useSortBy,
        usePagination
    );

    return (
        <>
            <Table {...getTableProps()} className="table-tiny" fontFamily={"Poppins"}>
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th key={column.id}>{column.render("Header")}</Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()} height={"42px"}>
                                {row.cells.map((cell) => (
                                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                ))}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>

            {/*<Flex justifyContent="space-between" m={4} alignItems="right">*/}
            {/*    <Flex>*/}
            {/*        <Tooltip label="Previous Page">*/}
            {/*            <IconButton*/}
            {/*                onClick={previousPage}*/}
            {/*                aria-label="Previous"*/}
            {/*                icon={<FiChevronLeft/>}*/}
            {/*            />*/}
            {/*        </Tooltip>*/}
            {/*    </Flex>*/}
            {/*    <Flex*/}
            {/*        justifyContent={"center"}*/}
            {/*        justifyItems={"center"}*/}
            {/*        flexDirection={"column"}*/}
            {/*        fontFamily={"Poppins"}*/}
            {/*    >*/}
            {/*        <Text mr={8} width={"100%"}>*/}
            {/*            Page{" "}*/}
            {/*            <Text fontWeight="bold" as="span">*/}
            {/*                {pageIndex + 1}*/}
            {/*            </Text>{" "}*/}
            {/*            of{" "}*/}
            {/*            <Text fontWeight="bold" as="span">*/}
            {/*                {pageOptions.length}*/}
            {/*            </Text>*/}
            {/*        </Text>*/}
            {/*    </Flex>*/}
            {/*    <Flex>*/}
            {/*        <Tooltip label="Next Page">*/}
            {/*            <IconButton*/}
            {/*                onClick={nextPage}*/}
            {/*                aria-label="next"*/}
            {/*                icon={<FiChevronRight/>}*/}
            {/*            />*/}
            {/*        </Tooltip>*/}
            {/*    </Flex>*/}
            {/*</Flex>*/}
        </>
    );
};
