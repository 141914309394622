import {createSlice} from '@reduxjs/toolkit';
import {Concern, TaskRecord} from "@zordi/zordi_object_schema";
import {fetchConcerns, updateConcern} from "./asyncThunk/reportData"

interface ReportDataState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    type: '' | 'ReVsIn' | 'SyVsGr';
    concerns: ConcernsBySource
    workOrders: any;
}

const initialState: ReportDataState = {
    status: 'idle',
    type: '',
    concerns: {
        comparisonReportRecord: undefined,
        growerConcerns: [],
        reviewerConcerns: []
    },
    workOrders: [],
}


export const ReportDataSlice = createSlice({
    name: 'reportData',
    initialState,
    reducers: {
        updateSiteReportWorkOrders: (state, action) => {
            state.workOrders = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchConcerns.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.concerns = action.payload as ConcernsBySource
            })
            .addCase(updateConcern.fulfilled, (state, action) => {
                state.status = 'succeeded'
                console.log("result came back successful")
            })
            .addCase(updateConcern.rejected, (state, action) => {
                state.status = 'failed'
                console.log("result came back with error")
            })
    }
})

export interface ConcernsBySource {
    comparisonReportRecord: TaskRecord | undefined,
    growerConcerns: Concern[],
    reviewerConcerns: Concern[]
}

export const {updateSiteReportWorkOrders} = ReportDataSlice.actions;

export const reportDataReducer = ReportDataSlice.reducer;
