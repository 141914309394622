import {combineReducers} from 'redux';
import {userReducer} from './userSlice';
import {siteOverviewReducer} from './siteOverviewSlice';
import {reportDataReducer} from './reportDataSlice';
import {workOrderSliceReducer} from "./workOrderSlice";
import {pageSliceReducer} from "./pageSlice";
import {laborDataSliceReducer} from "./laborDataSlice";
import {workOrderRoutineSliceReducer} from "./workOrderRoutineSlice";

export const rootReducer = combineReducers({
    user: userReducer,
    siteOverview: siteOverviewReducer,
    reportData: reportDataReducer,
    workOrder: workOrderSliceReducer,
    page: pageSliceReducer,
    laborData: laborDataSliceReducer,
    workOrderRoutine: workOrderRoutineSliceReducer,
})
