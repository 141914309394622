import {createSlice} from '@reduxjs/toolkit';
import {
    createWorkOrderRoutine,
    deleteWorkOrderRoutine,
    getWorkOrderRoutinesByStatus,
    updateWorkOrderRoutine,
} from "./asyncThunk/workOrderRoutine";
import {MDReadWorkOrderRoutineResponse} from "@zordi/zordi_object_schema"
import {convertWorkOrderRoutine} from "../utils/transferObject";
import {Routine} from "@zordi/zordi_object_schema/src/to/References";

type WorkOrderRoutineSliceState = {
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: any
    routines: MDReadWorkOrderRoutineResponse[]
    newRoutine: MDReadWorkOrderRoutineResponse | null
    updatedRoutine: MDReadWorkOrderRoutineResponse | null
    deletedRoutine: string | null
}

const initialState: WorkOrderRoutineSliceState = {
    status: 'idle',
    error: null,
    routines: [],
    newRoutine: null,
    updatedRoutine: null,
    deletedRoutine: null
}
export const workOrderRoutineSlice = createSlice({
    name: 'workOrder',
    initialState,
    reducers: {
        resetRoutine: (state) => {
            state.newRoutine = null
            state.updatedRoutine = null
            state.deletedRoutine = null
        },
        setIdle: (state) => {
            state.status = 'idle'
            state.error = null
        },
    },
    extraReducers: builder => {
        builder
            .addCase(createWorkOrderRoutine.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(createWorkOrderRoutine.fulfilled, (state, action) => {
                const newRoutine = convertWorkOrderRoutine(action.payload)
                state.status = 'succeeded'
                state.newRoutine = newRoutine
                state.routines = [...state.routines, newRoutine]
                state.error = ''
            })
            .addCase(createWorkOrderRoutine.rejected, (state, action) => {
                state.status = 'failed'
                state.newRoutine = null
                state.error = action.payload
            })
            .addCase(getWorkOrderRoutinesByStatus.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getWorkOrderRoutinesByStatus.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.routines = action.payload.map((routine: Routine) => convertWorkOrderRoutine(routine))
                state.error = ''
            })
            .addCase(getWorkOrderRoutinesByStatus.rejected, (state, action) => {
                state.status = 'failed'
                state.routines = []
                state.error = action.payload
            })
            .addCase(updateWorkOrderRoutine.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateWorkOrderRoutine.fulfilled, (state, action) => {
                const updatedRoutine = convertWorkOrderRoutine(action.payload)
                state.status = 'succeeded'
                state.updatedRoutine = updatedRoutine
                state.routines = [...state.routines.filter(routine => routine.routineId !== updatedRoutine.routineId), updatedRoutine]
                state.error = ''
            })
            .addCase(updateWorkOrderRoutine.rejected, (state, action) => {
                state.status = 'failed'
                state.updatedRoutine = null
                state.error = action.payload
            })
            .addCase(deleteWorkOrderRoutine.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(deleteWorkOrderRoutine.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.deletedRoutine = action.payload.id
                state.routines = [...state.routines.filter(routine => routine.routineId !== action.payload.id)]
                state.error = ''
            })
            .addCase(deleteWorkOrderRoutine.rejected, (state, action) => {
                state.status = 'failed'
                state.deletedRoutine = null
                state.error = action.payload
            })
    }
})

export const {resetRoutine, setIdle} = workOrderRoutineSlice.actions;

export const workOrderRoutineSliceReducer = workOrderRoutineSlice.reducer;
