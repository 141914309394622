import {get, post} from './baseRequests';
import {Order} from "../types/Order";
import {OrderQuery} from "../types/OrderQuery";
import {GetWorkOrdersQuery} from "../contexts/OrderProvider";
import {dateToSnapshot} from "../utils/date";
import {TaskRecord, WorkOrder, WorkOrderSource} from "@zordi/zordi_object_schema";

const parseOrders = (json: any): Order[] => {
    return json.map((item: any) => {
        return item as Order;
    });
}

const parseWorkOrders = (json: any): WorkOrder[] => {
    return json.map((item: any) => {
        return item as WorkOrder;
    });
}


export const fetchOrders = async (orderQuery: OrderQuery): Promise<Order[]> => {
    return get('order').then(json => parseOrders(json));
}

export const fetchWorkOrdersByDateSource = async (getWorkOrdersQuery: GetWorkOrdersQuery, source: WorkOrderSource): Promise<WorkOrder[]> => {
    // TODO: make this a utility function somewhere
    const dateToSnapshot = (date: Date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString()
            .padStart(2, "0")}`
    }
    return get(`workorder/list?snapshotDate=${dateToSnapshot(
        getWorkOrdersQuery.endTime!)}&siteId=${getWorkOrdersQuery.siteId}&workOrderSource=${source}`)
        .then(json => parseWorkOrders(json));
}

export interface WorkOrdersBySource {
    workOrderGenerationRecord: TaskRecord,
    comparisonReportRecord: TaskRecord,
    systemWorkOrders: WorkOrder[],
    growerWorkOrders: WorkOrder[]
}

export const fetchAllWorkOrders = async (getWorkOrdersQuery: GetWorkOrdersQuery): Promise<WorkOrdersBySource> => {
    if (getWorkOrdersQuery.startTime) {
        const startDate = dateToSnapshot(getWorkOrdersQuery.startTime)
        console.log(`workorder/all?siteId=${getWorkOrdersQuery.siteId}&workOrderSource=System&startDate=${startDate}`)
        return get(`workorder/all?siteId=${getWorkOrdersQuery.siteId}&workOrderSource=System&startDate=${startDate}`)
            .then(json => {
                return json as WorkOrdersBySource
            });
    } else {
        console.log(`workorder/all?siteId=${getWorkOrdersQuery.siteId}&workOrderSource=System`)
        return get(`workorder/all?siteId=${getWorkOrdersQuery.siteId}&workOrderSource=System`)
            .then(json => {
                return json as WorkOrdersBySource
            });
    }

}

export const postWorkOrder = async (workOrderId: string, targetDate: string,
                                    fieldsToUpdate: { [key: string]: any }): Promise<void> => {
    // console.log(`workorder/all?siteId=${getWorkOrdersQuery.siteId}&workOrderSource=System`)
    console.log(`fieldsToUpdate ${JSON.stringify(fieldsToUpdate)}`)
    return post('workorder/update', {
        workOrderId, targetDate,
        ...fieldsToUpdate
    });
}

export const postUpdateWorkOrder = async (workOrderId: string,
                                          fieldsToUpdate: { [key: string]: any }): Promise<void> => {
    // console.log(`workorder/all?siteId=${getWorkOrdersQuery.siteId}&workOrderSource=System`)
    console.log(`fieldsToUpdate ${JSON.stringify(fieldsToUpdate)}`)
    return post('workorder/updateWorkOrder', {
        workOrderId,
        ...fieldsToUpdate
    });
}


