import {MDReadWorkOrderResponse} from "@zordi/zordi_object_schema";
import {WorkOrderPriority, WorkOrderStatus} from "@zordi/zordi_object_schema/src/to/References";
import {convertEnumToOptions} from "./transferObject";
import {WorkOrderFormOptions} from "../redux/workOrderSlice";

export type Filters = {
    date: string | null
    status: WorkOrderStatus | "all" | null
    region: string | null
    category: string | null
    action: string | null
    priority: WorkOrderPriority | string | null
    assignee: string | null
}

export const initialFilters = {
    date: null,
    status: WorkOrderStatus.ACTIVE,
    region: null,
    category: null,
    action: null,
    priority: null,
    assignee: null,
}

export type FilterColumns = "date" | "status" | "region" | "category" | "action" | "priority" | "assignee"

export const FilterEnumOptions = {
    status: convertEnumToOptions(WorkOrderStatus),
    priority: convertEnumToOptions(WorkOrderPriority)
}

export const regionFilterOptions = (options: WorkOrderFormOptions) => {
    if (options.site.length > 0) {
        return [{label: "All", value: "all"}, ...options.region[options.site[0].value]]
    } else {
        return [{label: "All", value: "all"}]
    }
}

export const FilterDatesOptions = [{
    label: "All",
    value: "all_dates",
}, {
    label: "Customize",
    value: "customize",
}, {
    label: "Today",
    value: "today",
}, {
    label: "Past 7 days",
    value: "past_week",
},]

export const filterWorkOrders = (workorders: MDReadWorkOrderResponse[], filters: Filters): MDReadWorkOrderResponse[] => {
    let filteredWO: MDReadWorkOrderResponse[] = [...workorders]

    if (filters.status !== "all" && filters.status !== null) {
        filteredWO = filteredWO.filter(wo => wo.status === filters.status)
    }
    if (filters.region !== "all" && filters.region !== null) {
        filteredWO = filteredWO.filter(wo => wo.region.id === filters.region)
    }
    if (filters.assignee !== "all" && filters.assignee !== null) {
        filteredWO = filteredWO.filter(wo => wo.assignee.id === filters.assignee)
    }
    if (filters.priority !== "all" && filters.priority !== null) {
        filteredWO = filteredWO.filter(wo => wo.priority === filters.priority as WorkOrderPriority)
    }
    return filteredWO
}