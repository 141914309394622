import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import {LoginState} from "../types/LoginState";

export interface UserState {
  isAuthorized: {
    zordi: boolean;
  },
  isLoggedIn: boolean | LoginState.UNKNOWN, 
  user: any,
}

const initialState: UserState = {
  isAuthorized: {zordi: false} , //for future specific auth per pages
  isLoggedIn: false,
  user: null,
}

export const userSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<any>) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    deauthorize: (state) => {
      state.isAuthorized.zordi = false;
    },
    authorize: (state) => {
      state.isAuthorized.zordi = true;
    },
    unknown: (state) => {
      state.isLoggedIn = LoginState.UNKNOWN;
    },
  },
})

export const { login, logout, deauthorize, authorize, unknown } = userSlice.actions;

export const userReducer = userSlice.reducer;
