import React, {useEffect} from 'react';
import {Alert, AlertIcon, Button, Container, Flex, Grid, GridItem, Heading, Stack, Text} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../utils/store";
import {createLaborData, getLaborDataByRow, updateLaborData} from "../redux/asyncThunk/laborData";
import {
    MDCreateLaborDataRequest,
    MDLaborDataEntry,
    MDLaborDataEntryStatus,
    MDUpdateLaborDataRequest
} from "@zordi/zordi_object_schema";
import {useSearchParams} from 'react-router-dom';
import {LaborLogOverview} from "../components/labor_log/LaborLogOverview";
import {fromDisplayRowToRawRow, fromRawRowToDisplayRow, setUrlParam} from "../utils/url";

export const LaborLogPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch<AppDispatch>()
    const laborDataOverviewByRow = useSelector((state: RootState) => state.laborData.laborDataOverviewByRow)
    const otherWorks = useSelector((state: RootState) => state.laborData.worksOnOtherRow)
    const generalUrl = "general"

    let siteId = searchParams.get("site") as string
    let selectedRow: number | null = fromDisplayRowToRawRow(searchParams.get("row") as string)

    const isGeneralSelected = (param: string | null) => {
        if (param == null) {
            return false
        } else {
            return param.toLowerCase() === generalUrl
        }
    }
    let generalSelected: boolean = isGeneralSelected(searchParams.get("row") as string)

    const otherWorkLinks = (otherWorks: {
        isGeneral: boolean,
        rowNumber?: number
    }[]) => {
        const rowLinks = otherWorks.map(otherWork => {
            if (otherWork.isGeneral) {
                return (
                    <Alert status='warning'>
                        <AlertIcon/>
                        Work in-progress on General area
                        <Button colorScheme='green' onClick={() => rowClicked(null, true)}>Go</Button>
                    </Alert>
                )
            } else {
                const rowNumber = otherWork.rowNumber as number
                return (
                    <Alert status='warning'>
                        <AlertIcon/>
                        Work in-progress on Row {otherWork.rowNumber}
                        <Button colorScheme='green' onClick={() => rowClicked(rowNumber, false)}>Go</Button>
                    </Alert>
                )
            }
        })

        return [...rowLinks]
    }

    const laborEntryToOption = (laborDataEntries: MDLaborDataEntry[]) => {
        return laborDataEntries.map(laborEntry => {
            if (laborEntry.entryStatus === MDLaborDataEntryStatus.ready) {
                return (
                    <Stack key={laborEntry.workOrderId} spacing={5} direction='row'>
                        <Button
                            colorScheme='green' onClick={() => handleStart(laborEntry.workOrderId)}>Start</Button>
                        <Text>{laborEntry.workOrderDescription}</Text>
                    </Stack>
                )
            } else if (laborEntry.entryStatus === MDLaborDataEntryStatus.paused) {
                return (
                    <Stack key={laborEntry.workOrderId} spacing={5} direction='row'>
                        <Button
                            colorScheme='orange'
                            onClick={() => handleStart(laborEntry.workOrderId)}>Resume</Button>
                        <Text>{laborEntry.workOrderDescription}</Text>
                    </Stack>
                )
            } else if (laborEntry.entryStatus === MDLaborDataEntryStatus.complete) {
                // Hide
                return (
                    <React.Fragment key={laborEntry.workOrderId}>
                    </React.Fragment>
                )
            } else {
                return (
                    <Stack key={laborEntry.workOrderId} spacing={5} direction='row'>
                        <Button
                            colorScheme='yellow' onClick={() => handlePause(laborEntry.laborDataId)}>Pause</Button>
                        <Button
                            colorScheme='blue' onClick={() => handleStop(laborEntry.laborDataId)}>Complete</Button>
                        <Text>{laborEntry.workOrderDescription}</Text>
                    </Stack>
                )
            }

        })
    }

    useEffect(() => {
        if (siteId !== null && selectedRow !== null) {
            dispatch(getLaborDataByRow({
                siteId, "rowNumber": selectedRow, isGeneral: false
            }))
        } else if (siteId !== null && generalSelected) {
            dispatch(getLaborDataByRow({
                siteId, isGeneral: true
            }))
        }
    }, [dispatch, siteId, selectedRow, generalSelected])

    const handleStart = (workOrderId: string) => {
        if (siteId !== null && selectedRow !== null) {
            const createLaborDataRequest: MDCreateLaborDataRequest = {
                workOrderId: workOrderId,
                siteId: siteId.toUpperCase(),
                rowNumber: selectedRow,
                isGeneral: false,
                startTime: new Date().getTime()
            }
            dispatch(createLaborData(createLaborDataRequest))
        } else if (siteId !== null && generalSelected) {
            const createLaborDataRequest: MDCreateLaborDataRequest = {
                workOrderId: workOrderId,
                siteId: siteId.toUpperCase(),
                isGeneral: true,
                startTime: new Date().getTime()
            }
            dispatch(createLaborData(createLaborDataRequest))
        }
    }

    const handlePause = (entryId: string | undefined) => {
        if (siteId !== null && (generalSelected || selectedRow !== null) && entryId !== undefined) {
            const updateLaborDataRequest: MDUpdateLaborDataRequest = {
                laborDataId: entryId,
                stopTime: new Date().getTime(),
                complete: false
            }
            dispatch(updateLaborData(updateLaborDataRequest))
        }
    }

    const handleStop = (entryId: string | undefined) => {
        console.log("stopping ", entryId)
        if (siteId !== null && (generalSelected || selectedRow !== null) && entryId !== undefined) {
            const updateLaborDataRequest: MDUpdateLaborDataRequest = {
                laborDataId: entryId,
                stopTime: new Date().getTime(),
                complete: true
            }
            dispatch(updateLaborData(updateLaborDataRequest))
        }
    }

    const rowClicked = (row: number | null, generalClicked: boolean) => {
        if (generalClicked) {
            setUrlParam("row", "general", searchParams, setSearchParams)
            selectedRow = null
            generalSelected = true
        } else if (row !== null) {
            const selectedDisplayRow = fromRawRowToDisplayRow(row)
            setUrlParam("row", selectedDisplayRow, searchParams, setSearchParams)
            selectedRow = row
            generalSelected = false
        }

    }

    const siteSelected = (siteCode: string) => {
        setUrlParam("site", siteCode, searchParams, setSearchParams)
        siteId = siteCode
    }

    if (siteId == null || (selectedRow == null && !generalSelected)) {
        return (
            <LaborLogOverview siteId={siteId}
                              rowClicked={rowClicked}
                              siteSelected={siteSelected}
            ></LaborLogOverview>
        )
    } else if (generalSelected) {
        return (
            <Container maxW={"container.3xl"} paddingLeft={"25px"} paddingRight={"25px"} m={0}>
                <Flex h={"100vh"} w={"100%"} direction={{base: "column"}} gap={"50px"}>
                    <Grid w={"full"}>
                        <GridItem colSpan={2}>
                            <Heading mb={4}>Active Work Orders: General</Heading>
                            <Text fontSize='xl'>
                                Please select the work orders you will work on in this section:
                            </Text>
                            {otherWorkLinks(otherWorks)}
                            <Stack spacing={5} direction='column'>
                                {laborEntryToOption(laborDataOverviewByRow)}
                            </Stack>
                        </GridItem>
                    </Grid>
                </Flex>
            </Container>
        )
    } else if (selectedRow) {
        return (
            <Container maxW={"container.3xl"} paddingLeft={"25px"} paddingRight={"25px"} m={0}>
                <Flex h={"100vh"} w={"100%"} direction={{base: "column"}} gap={"50px"}>
                    <Grid w={"full"}>
                        <GridItem colSpan={2}>
                            <Heading mb={4}>Active Work Orders: {siteId} Bay {Math.ceil(
                                selectedRow / 8)} Row {fromRawRowToDisplayRow(
                                selectedRow)}</Heading>
                            <Text fontSize='xl'>
                                Please select the work orders you will work on in this section:
                            </Text>
                            {otherWorkLinks(otherWorks)}
                            <Stack spacing={5} direction='column'>
                                {laborEntryToOption(laborDataOverviewByRow)}
                            </Stack>
                        </GridItem>
                    </Grid>
                </Flex>
            </Container>
        )
    } else {
        return (<>NO DATA</>)
    }
}
