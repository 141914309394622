import {Concern, ConcernReviewStatus} from "@zordi/zordi_object_schema";
import React, {useState} from "react";
import {
    Button,
    Divider,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    Portal,
    Radio,
    RadioGroup,
    Stack,
    Td,
    Text,
    Textarea,
    Tr
} from "@chakra-ui/react";
import {ImCheckmark, ImCross} from "react-icons/im";
import {post} from "../../api/baseRequests";
import {ZordiButtonSpinner} from "../common/ZordiButtonSpinner";
import {ZordiPopoverTrigger} from "../common/ZordiPopeverTrigger";
import {appendTimestamp} from "../../utils/site_report";

type ConcernRowProps = {
    date: string,
    idx: number,
    length: number,
    growerConcern: Concern | undefined,
    reviewerConcern: Concern | undefined
}
export const ConcernRow = ({date, idx, length, growerConcern, reviewerConcern}: ConcernRowProps) => {
    const [mainConcern, setMainConcern] = useState<Concern>(growerConcern ? growerConcern : reviewerConcern!);
    const [note, setNote] = useState("");
    const [updating, setUpdating] = useState<boolean>(false)

    const concernValid = mainConcern.concernValid

    let options: [string,][] = [
        ["Remote Grower Correct"],
        ["Remote Grower Incorrect",]
    ]
    const [value, setValue] = React.useState(concernValid ? "0" : "1")

    const save = async () => {
        setUpdating(true)
        const concernValid = (value === "0")

        // TODO: somehow incorporate this into redux
        try {
            await post('workorder/concern/update', {
                concernId: mainConcern.concernId,
                snapshotDate: mainConcern.snapshotDate,
                concernValid,
                matchReviewed: true,
                concernReviewStatus: ConcernReviewStatus.REVIEWED,
                reviewNotes: appendTimestamp(note)
            });

            // if both growerConcern and reviewerConcern exist, it means we need to update both.
            // We updated growerConcern with main concern, so need to update reviewerConcern
            if (growerConcern && reviewerConcern) {
                await post('workorder/concern/update', {
                    concernId: reviewerConcern.concernId,
                    snapshotDate: reviewerConcern.snapshotDate,
                    concernValid,
                    matchReviewed: true,
                    concernReviewStatus: ConcernReviewStatus.REVIEWED,
                    reviewNotes: appendTimestamp(note)
                });
            }
        } catch (e) {
            console.log("result", e)
        }


        setMainConcern({
            ...mainConcern,
            concernValid,
            matchReviewed: true,
            concernReviewStatus: ConcernReviewStatus.REVIEWED,
            reviewNotes: mainConcern.reviewNotes ? [...mainConcern.reviewNotes,
                appendTimestamp(note)] : [appendTimestamp(note)]
        })
        setUpdating(false)
    }

    const button = () => {
        if (mainConcern.concernReviewStatus === ConcernReviewStatus.REVIEWED) {
            return (<Button
                className={`badge-like-button green`}>
                Reviewed
            </Button>)
        } else if (mainConcern.concernReviewStatus === ConcernReviewStatus.AUTO_REVIEWED) {
            return (<Button
                className={`badge-like-button blue`}>
                Auto-Reviewed
            </Button>)
        } else {
            return (<Button
                className={`badge-like-button purple`}>
                Needs Review
            </Button>)
        }
    }

    const displayNotes = (reviewNotes: string[] | undefined) => {
        if (reviewNotes !== undefined) {
            const reversed = reviewNotes.slice().reverse()
            return (reversed.map((reviewNote, i) => {
                return <Text key={i}>{reviewNote}</Text>
            }))
        } else {
            return ("")
        }
    }

    const reviewModule = () => {
        return (
            <>
                <Popover>
                    <ZordiPopoverTrigger>
                        {button()}
                    </ZordiPopoverTrigger>
                    <Portal>
                        <PopoverContent>
                            <PopoverArrow/>
                            <PopoverHeader>Is the grower(remote) concern valid?</PopoverHeader>
                            <PopoverCloseButton/>
                            <PopoverBody>
                                <RadioGroup
                                    value={value}
                                    onChange={setValue}
                                >
                                    <Stack direction='column'>
                                        {
                                            options.map((option, i) => {
                                                return (<Radio key={i} value={i.toString()}>
                                                    {option[0]}
                                                </Radio>)
                                            })
                                        }
                                    </Stack>
                                </RadioGroup>
                                <Divider/>
                                <Text>Notes: </Text>
                                <Textarea placeholder="Optional" value={note}
                                          onChange={(e) => (setNote(e.target.value))}/>
                                {
                                    updating ?
                                        (<ZordiButtonSpinner/>) : (
                                            <Button variant='outline' onClick={save}>
                                                Save
                                            </Button>
                                        )
                                }
                                <Divider/>
                                {displayNotes(mainConcern.reviewNotes)}
                            </PopoverBody>
                        </PopoverContent>
                    </Portal>
                </Popover>
            </>
        )
    }

    return (
        <Tr key={`row-${date}-${mainConcern.concernId}`} className={"border-bottom"}>
            {idx === 0 ? <th rowSpan={length} className="chakra-th"><Text>{date}</Text></th> : null}
            <Td className={`concern-validity ${concernValid ? "text-green" : "text-red"}`}><span>{concernValid === null ? "" : concernValid ? "Remote Grower Correct" : "Remote Grower Incorrect"}</span></Td>
            <Td className={mainConcern.matchKey ? "text-green" : "text-red"}>{mainConcern.matchKey ? "Match" : "No match"}</Td>
            <Td className="align-center">
                {reviewModule()}
            </Td>
            <Td className={"concern-type"}>{mainConcern.concernType}: {mainConcern.concernElement}</Td>
            <Td className="align-center">{growerConcern ? growerConcern.concernRegions.map(
                    (region, i) => (
                        <Text key={i}>{region}</Text>
                    )) :
                <ImCross style={{margin: "auto"}}/>}</Td>
            <Td className="align-center">{reviewerConcern ? reviewerConcern.concernRegions.map(
                    (region, i) => (
                        <Text key={i}>{region}</Text>
                    )) :
                <ImCross style={{margin: "auto"}}/>}</Td>
            <Td>{reviewerConcern?.canBeObservedRemotely !== undefined && reviewerConcern.canBeObservedRemotely ?
                <ImCheckmark style={{margin: "auto"}}/> :
                <ImCross style={{margin: "auto"}}/>
            }</Td>
        </Tr>
    )
}
