import React, {useCallback, useEffect, useState} from 'react';
import {MDCreateWorkOrderRoutineRequest, MDUpdateWorkOrderRoutineRequest} from "@zordi/zordi_object_schema";
import {Button, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../utils/store";
import {resetRoutine, setIdle} from "../redux/workOrderRoutineSlice";
import {getWorkOrderOptions} from "../redux/asyncThunk/workOrder";
import {
    createWorkOrderRoutine,
    deleteWorkOrderRoutine,
    getWorkOrderRoutinesByStatus,
    updateWorkOrderRoutine
} from "../redux/asyncThunk/workOrderRoutine";
import {convertEnum} from "../utils/transferObject";
import {Alert} from "../components/common/Alert";
import {WorkOrderRoutinesTable} from "../components/workorder/WorkOrderRoutinesTable";
import {NewWorkOrderRoutineSidebar} from "../components/workorder/NewWorkOrderRoutineSidebar";

export const WorkOrderRoutines = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [isNewRoutineOpen, setIsNewRoutineOpen] = useState<boolean>(false)
    const [editingWORoutine, setEditingWORoutine] = useState<any>(null)

    const [fetchRequested, setFetchRequested] = useState<boolean>(false)
    const fetchStatus = useSelector((state: RootState) => state.workOrderRoutine.status)
    const error = useSelector((state: RootState) => state.workOrderRoutine.error)
    const newRoutine = useSelector((state: RootState) => state.workOrderRoutine.newRoutine)
    const updatedRoutine = useSelector((state: RootState) => state.workOrderRoutine.updatedRoutine)
    const deletedRoutine = useSelector((state: RootState) => state.workOrderRoutine.deletedRoutine)
    const {isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose} = useDisclosure()
    const cancelRef = React.useRef()

    const [resetForm, setResetForm] = useState<boolean>(false)


    const getRoutines = useCallback(() => {
        dispatch(getWorkOrderRoutinesByStatus())
    }, [dispatch])

    const getOptions = useCallback(() => {
        dispatch(getWorkOrderOptions())
    }, [dispatch])

    useEffect(() => {
        getRoutines()
        getOptions()
    }, [getOptions, getRoutines])

    useEffect(() => {
        if (((fetchStatus === "succeeded" || fetchStatus === "failed") && fetchRequested && (newRoutine !== null || updatedRoutine !== null || deletedRoutine !== null || error !== null))) {
            onAlertOpen()
        } else if (fetchStatus === "loading" && fetchRequested) {
            // loading mark on alert
        }
    }, [fetchStatus, newRoutine, onAlertOpen, fetchRequested, deletedRoutine, error, updatedRoutine])

    const handleOpenEditing = (routine: any) => {
        setEditingWORoutine(routine)
        setIsNewRoutineOpen(true)
    }

    const handleCreateSubmit = (routine: MDCreateWorkOrderRoutineRequest) => {
        dispatch(createWorkOrderRoutine(routine))
        setFetchRequested(true)
    }
    const handleUpdateSubmit = (routine: MDUpdateWorkOrderRoutineRequest) => {
        dispatch(updateWorkOrderRoutine(routine))
        setFetchRequested(true)
    }
    const handleDeleteSubmit = (id: string) => {
        dispatch(deleteWorkOrderRoutine(id))
        setFetchRequested(true)
    }

    const handleSidebarClose = () => {
        setIsNewRoutineOpen(false)
        setEditingWORoutine(null)
    }

    const handleAlertCloseAll = () => {
        onAlertClose()
        dispatch(resetRoutine())
        dispatch(setIdle())
        setFetchRequested(false)

        setEditingWORoutine(null)
        setResetForm(!resetForm)
        handleSidebarClose()
    }

    const handleAlertClose = () => {
        onAlertClose()
        dispatch(resetRoutine())
        dispatch(setIdle())
        setFetchRequested(false)
    }

    const alertBody = () => {
        if (newRoutine) {
            return (
                <p>
                    <br/>
                    <span className={"label"}>Routine ID</span><br/>
                    {newRoutine.routineId}
                    <br/> <br/>
                    <span className={"label"}>Summary</span><br/>
                    Category: {convertEnum(newRoutine.category.label)} <br/>
                    Action: {convertEnum(newRoutine.action.label)} <br/>
                    Assignee: {convertEnum(newRoutine.assignee.name)} <br/>
                </p>
            )
        }
    }

    return (
        <div style={{maxWidth: "98%", marginLeft: "8px"}}>
            <Alert status={fetchStatus} isOpen={isAlertOpen} onCloseAll={handleAlertCloseAll} onClose={handleAlertClose}
                   body={newRoutine ? alertBody() : null} cancelRef={cancelRef}
                   header={"Work Order Routine"}/>
            <NewWorkOrderRoutineSidebar isOpen={isNewRoutineOpen} onClose={handleSidebarClose}
                                        wORoutine={editingWORoutine}
                                        submitCreate={handleCreateSubmit}
                                        submitUpdate={handleUpdateSubmit}
                                        resetForm={resetForm}/>
            <div style={{display: "flex", alignItems: "center", justifyContent: "start", marginBottom: "1rem"}}>
                <Button variant={"outline"} colorScheme={"cyan"} onClick={() => setIsNewRoutineOpen(true)}>New
                    Routine</Button>
            </div>
            <div>
                <Tabs>
                    <TabList>
                        <Tab>Routines</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <WorkOrderRoutinesTable setEditOpen={handleOpenEditing} submitDelete={handleDeleteSubmit}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        </div>
    )
}
