import React, {useRef, useState} from "react";
import {Image as ChakraImage, Spinner} from "@chakra-ui/react";

const getSize = (imgSize: { x: number, y: number }) => {
    const {x, y} = imgSize
    const addPx = (num: number, ratio: number) => (num * ratio).toString() + 'px'
    const getStyle = (ratio: number) => ({
        width: addPx(x, ratio),
        maxWidth: addPx(x, ratio),
        height: addPx(y, ratio),
        maxHeight: addPx(y, ratio)
    })
    return {
        0: {maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto"},
        1: getStyle(1),
        2: getStyle(2),
        3: getStyle(3),
    }
};

type ImgSizeInfo = {
    [ratioType: number]: { [direction: string]: string }
}

export const Magnifier = (props: any) => {
    const {src} = props;
    const imgRef = useRef<any>(null)
    const [magnified, setMagnified] = useState<boolean>(false);
    const [imgStyles, setImgStyles] = useState<ImgSizeInfo>(getSize({x: 0, y: 0}))
    const [ratio, setRatio] = useState<number>(0);

    const handleReduce = () => {
        setMagnified(false)
        setRatio(0)
    }

    const handleMagnify1 = () => {
        setMagnified(true)
        setRatio(1)
    }

    const handleMagnify2 = () => {
        setMagnified(true)
        setRatio(2)
    }

    const handleMagnify3 = () => {
        setMagnified(true)
        setRatio(3)
    }

    const handleOnLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (imgRef.current !== null) {
            const x = imgRef.current.naturalWidth
            const y = imgRef.current.naturalHeight
            const styles = getSize({x, y})
            setImgStyles(styles)
        }
    }

    return (
        <div className={"magnify-container"}>
            <div className={"magnify-btn-container"}>
                <button className={`magnify-btn ${ratio === 0 ? "highlight" : ""}`}
                        onClick={handleReduce}>Contained
                </button>
                <button className={`magnify-btn ${ratio === 1 ? "highlight" : ""}`}
                        onClick={handleMagnify1}>Full
                </button>
                <button className={`magnify-btn ${ratio === 2 ? "highlight" : ""}`}
                        onClick={handleMagnify2}>Fullx2
                </button>
                <button className={`magnify-btn ${ratio === 3 ? "highlight" : ""}`}
                        onClick={handleMagnify3}>Fullx3
                </button>
            </div>

            <div className={`${magnified ? 'magnified' : 'box-box'}`}>
                <ChakraImage
                    ref={imgRef}
                    onLoad={handleOnLoad}
                    borderColor={"red"}
                    objectFit='cover'
                    fallback={<Spinner marginLeft={"25px"} size='xl'></Spinner>}
                    src={src}
                    className={`thumbnail-image}`}
                    style={imgStyles[ratio]}/>
            </div>
        </div>
    )
}
