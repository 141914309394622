import React from "react"; // we need this to make JSX compile
import firebase from "firebase/compat/app";
import "firebase/compat/auth";


type RecentOrdersPageProps = {
    auth: firebase.auth.Auth;
};

export const RecentOrdersPage = ({auth}: RecentOrdersPageProps) => {
    if (auth.currentUser == null) {
        return <></>;
    }


    return (
        <>
            <h1>Recent Orders Placeholder</h1>
        </>
    );
};