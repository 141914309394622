export class HeatmapLayout {
    constructor(public metricName: string, public aggregationMethod: string, public clusters: HeatmapCluster[]) {
    }

    // getDisplayForPoints = (): PointDisplay[] => {
    //     return this.dataPoints.map((dataPoint: HeatmapSide) => {
    //         return new PointDisplay(dataPoint.side.x, dataPoint.side.y, "green", "orange")
    //     })
    // }
}

// to be deprecated
export type GreenhouseSide = {
    bagId: number
    gutterId: number
    sideId: number
    greenhouseId: number
    zordiId: string
    values: {
        [key: string]: number
    }
}

// to be deprecated
export type GreenhouseCluster = {
    x: number
    y: number
    clusterId: string
    sides: GreenhouseSide[]
}

// to be deprecated
export type HeatmapSide = {
    side: GreenhouseSide,
    value: number
}

// to be deprecated
export type HeatmapCluster = {
    x: number
    y: number
    value: number
    clusterId: string
    sides: HeatmapSide[]
}

// contains information on how to display a single point
export class PointDisplay {
    constructor(public x: number, public y: number, public color: string, public hoverColor: string) {
    }

}