import React, {useEffect} from 'react';
import {RowOption} from "@zordi/zordi_object_schema";
import {Button, Container, Divider, Flex, Grid, GridItem, Heading, HStack, Text} from "@chakra-ui/react";
import {getRowOptions} from "../../redux/asyncThunk/laborData";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../utils/store";
import {fromRawRowToDisplayRow} from "../../utils/url";

type LaborLogOverviewProp = {
    siteId: string | null
    rowClicked: (selectedRow: number | null, generalClicked: boolean) => void
    siteSelected: (siteCode: string) => void
}

export const LaborLogOverview = ({siteId, rowClicked, siteSelected}: LaborLogOverviewProp) => {
    const rowOptions = useSelector((state: RootState) => state.laborData.rowOptions)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (siteId !== null) {
            dispatch(getRowOptions(siteId))
        }
    }, [dispatch, siteId])

    const rowOptionsToTable = (rowOptions: RowOption[]) => {
        const result = []
        let bayResult = []

        let previousBayNumber = -1
        for (const rowOption of rowOptions) {
            if (rowOption.bayNumber > previousBayNumber) {
                if (previousBayNumber > -1) {
                    result.push(<HStack key={previousBayNumber}>
                        <Text>Bay{previousBayNumber}</Text>
                        {
                            bayResult.map(rowNumber => (
                                <Button key={rowNumber}
                                        colorScheme={"blue"}
                                        onClick={() => rowClicked(rowNumber, false)}
                                >{fromRawRowToDisplayRow(rowNumber)}</Button>
                            ))
                        }
                    </HStack>)
                    result.push(<Divider key={`divider${previousBayNumber}`}></Divider>)
                }

                bayResult = []
                bayResult.push(rowOption.rowNumber)
                previousBayNumber = rowOption.bayNumber
            } else {
                bayResult.push(rowOption.rowNumber)
            }
        }

        if (bayResult.length > 0) {
            result.push(<HStack key={previousBayNumber}>
                <Text>Bay{previousBayNumber}</Text>
                {
                    bayResult.map(rowNumber => (
                        <Button key={rowNumber}
                                onClick={() => rowClicked(rowNumber, false)}
                                colorScheme={"blue"}>{fromRawRowToDisplayRow(rowNumber)}</Button>
                    ))
                }
            </HStack>)
        }
        return result
    }

    if (siteId == null) {
        return (<Container maxW={"container.3xl"} paddingLeft={"25px"} paddingRight={"25px"} m={0}>
                <Flex h={"100vh"} w={"100%"} direction={{base: "column"}} gap={"50px"}>
                    <Grid w={"full"}>
                        <GridItem colSpan={2}>
                            <Heading mb={4}>Select Site</Heading>
                            <Button
                                onClick={() => siteSelected("OAK1")}
                                colorScheme={"blue"}>{"OAK1"}</Button>
                        </GridItem>
                    </Grid>
                </Flex>
            </Container>
        )
    } else {
        return (<Container maxW={"container.3xl"} paddingLeft={"25px"} paddingRight={"25px"} m={0}>
                <Flex h={"100vh"} w={"100%"} direction={{base: "column"}} gap={"50px"}>
                    <Grid w={"full"}>
                        <GridItem colSpan={2}>
                            <Heading mb={4}>Select Rows to work on: {siteId.toUpperCase()}</Heading>

                        </GridItem>
                        <GridItem colSpan={2}>
                            <Button colorScheme={"blue"}
                                    onClick={() => rowClicked(null, true)}
                            >General</Button>
                        </GridItem>
                        <GridItem colSpan={2}>
                            {rowOptionsToTable(rowOptions)}
                        </GridItem>
                    </Grid>
                </Flex>
            </Container>
        )
    }
}
