import React, {useEffect, useState} from "react"; // we need this to make JSX compile
import "firebase/compat/auth";
import {Box, Container, Divider, Flex, Grid, GridItem, Link,} from "@chakra-ui/react";
import {HeatmapModule} from "../common/heatmap/HeatmapModule";
import {SingleDatePicker} from "../common/SingleDatePicker";
import HighLevelMetric from "../components/overview/metric-graphs/HighLevelMetric";
import moment from "moment";
import {WorkOrderTable} from "../common/workorder/WorkOrderTable";
import {AdminActions} from "../common/admin/AdminActions";
import {useParams} from 'react-router';
import NotFoundPage from "./NotFound";
import {useSearchParams} from "react-router-dom";
import {dateToSnapshot, localDateFromSnapshot} from "../utils/date";
import {setUrlParam} from "../utils/url";
import {Site} from "@zordi/zordi_object_schema";
import {useDispatch} from "react-redux";
import {updateDate} from "../redux/siteOverviewSlice";

export type MetricSensor = {
    metric: string,
    sensorId: string,
}

export const SiteOverviewPage = () => {
    const heatmapRef = React.createRef<HTMLDivElement>()
    const [searchParams, setSearchParams] = useSearchParams();
    const [snapshotDate, setSnapshotDate] = useState<Date>(
        searchParams.has("date") ? localDateFromSnapshot(searchParams.get("date") as string) : new Date())
    const [scrollToMetricSensor, setScrollToMetricSensor] = useState<MetricSensor | undefined>(undefined);
    let {siteId} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateDate(dateToSnapshot(snapshotDate)));
    }, [snapshotDate, dispatch])

    const setDate = (newDate: Date) => {
        dispatch(updateDate(dateToSnapshot(newDate)));
        setSnapshotDate(newDate)
        setUrlParam("date", dateToSnapshot(newDate), searchParams, setSearchParams)
    }

    const getOneWeekAgo = (today: Date) => {
        return moment(today).subtract(7, 'day').toDate()
    }

    const getSensorSelectedFunction = (metric: string) => {
        return (sensorId: string) => {
            if ((heatmapRef.current)) {
                heatmapRef.current.scrollIntoView({behavior: 'smooth'})
            }

            setScrollToMetricSensor({
                metric,
                sensorId
            })
        }
    }

    const monnitEsLink = {
        [Site.BOS1]: "https://my-deployment-4c5d40.kb.us-east-1.aws.found.io:9243/app/dashboards#/view/bf84b060-3eaa-11ed-b744-6f3e8dc6594a?_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-24h%2Fh%2Cto%3Anow))",
        [Site.OAK1]: "https://my-deployment-4c5d40.kb.us-east-1.aws.found.io:9243/app/dashboards#/view/022bab90-3a04-11ed-b744-6f3e8dc6594a?_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-24h%2Fh%2Cto%3Anow))"
    }

    if (siteId) {
        siteId = siteId.toUpperCase()
    } else {
        return (<NotFoundPage/>)
    }

    return (
        <Container maxW={"container.3xl"} paddingLeft={"25px"} paddingRight={"25px"} m={0}>
            <Flex h={"100vh"} w={"100%"} direction={{base: "column"}} gap={"50px"}>
                <Grid w={"full"}
                      templateColumns='repeat(2, 1fr)' gap={"20px"}>
                    <GridItem colSpan={2}>
                        <SingleDatePicker date={snapshotDate} setDate={setDate}/>
                        <Divider borderColor={"zordiGreen"} borderWidth={"1px"}/>
                        <WorkOrderTable siteId={siteId} endTime={snapshotDate}
                                        startTime={snapshotDate}/>
                    </GridItem>
                    <GridItem colSpan={2}>
                        <Box w={"100%"} p={4} color={"white"} maxW={'sm'} borderWidth={'1px'} borderRadius={'lg'}
                             overflow={'hidden'} margin={'auto'}>
                            <Link
                                color={"teal.500"}
                                href={monnitEsLink[siteId as Site]}
                                isExternal>Detailed Graphs for Plant Management
                            </Link>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={2}>
                        <HighLevelMetric startTime={getOneWeekAgo(snapshotDate)} endTime={snapshotDate}
                                         siteId={siteId}
                                         sensorSelected={getSensorSelectedFunction}
                        />
                    </GridItem>
                </Grid>
                <HeatmapModule heatmapRef={heatmapRef} snapshotDate={dateToSnapshot(snapshotDate)}
                               scrollToMetricSensor={scrollToMetricSensor}
                               siteId={siteId}
                ></HeatmapModule>

                <AdminActions siteId={siteId} snapshotDate={dateToSnapshot(snapshotDate)}></AdminActions>
            </Flex>
        </Container>
    );
};

