import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Button,
    ChakraProvider,
    Checkbox,
    CheckboxGroup,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    extendTheme,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Heading,
    HStack,
    Input,
    Text,
    Textarea,
} from '@chakra-ui/react'
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    MDCreateWorkOrderRoutineRequest,
    MDReadWorkOrderRoutineResponse,
    MDUpdateWorkOrderRoutineRequest,
} from "@zordi/zordi_object_schema";
import {Weekdays} from "@zordi/zordi_object_schema/src/to/References";
import DatePicker from "react-datepicker";
import '../../css/work_order_sidebar.css';

import {FormEntries} from "../../lib/labels/WorkOrder";
import {localDateFromSnapshot} from "../../utils/date";
import {RootState} from "../../utils/store";
import {FloatingSelect} from "../common/FloatingSelect";

const {
    CATEGORY,
    PROBLEM,
    DATA_USED,
    PRIORITY,
    REGION,
    ASSIGNEE,
    ACTION,
    VERIFICATION_METHOD,
    NOTES
} = FormEntries

type NewWorkOrderRoutineSidebarProps = {
    isOpen: boolean
    onClose: any
    wORoutine: MDReadWorkOrderRoutineResponse
    submitCreate: (workOrder: MDCreateWorkOrderRoutineRequest) => void
    submitUpdate: (workOrder: MDUpdateWorkOrderRoutineRequest) => void
    resetForm: boolean
}

export const NewWorkOrderRoutineSidebar = ({
                                               isOpen,
                                               onClose,
                                               wORoutine,
                                               submitCreate,
                                               submitUpdate,
                                               resetForm,
                                           }: NewWorkOrderRoutineSidebarProps) => {
    const [searchParams] = useSearchParams()
    const [warning, setWarning] = useState<boolean>(false)
    const updatedOptions = useSelector<any>((state: RootState) => state.workOrder.options)
    const [options, setOptions] = useState<any>(updatedOptions);

    // work order states
    const [routineId, setRoutineId] = useState<string | null>(null)
    const [category, setCategory] = useState<string | null>(null)
    const [problem, setProblem] = useState<any>(null)
    const [problemParams, setProblemParams] = useState<any>("")
    const [dataUsed, setDataUsed] = useState<any>(null)
    const [priority, setPriority] = useState<any>(null)
    const [region, setRegion] = useState<any>(null)
    const [site, setSite] = useState<any>(null)
    const [assignee, setAssignee] = useState<any>(null)
    const [action, setAction] = useState<any>(null)
    const [actionParams, setActionParams] = useState<any>("")
    const [verificationMethod, setVerificationMethod] = useState<any>(null)

    //routine states
    const [weekdays, setWeekdays] = useState<any>({...initialWeekdays})
    const [frequency, setFrequency] = useState<number>(1)
    const [endsFormat, setEndsFormat] = useState<"date" | "count" | "never">("never")
    const [date, setDate] = useState<Date>(
        searchParams.has("date") ? localDateFromSnapshot(searchParams.get("date") as string) : new Date())
    const [count, setCount] = useState<number>(1)
    const [notes, setNotes] = useState<any>("")
    const [events, setEvents] = useState<string[]>([])


    useEffect(() => {
        setOptions(updatedOptions)
    }, [updatedOptions])

    useEffect(() => {
            if (wORoutine !== null) {
                const {
                    routineId,
                    category,
                    problem,
                    problemParameter,
                    dataUsed,
                    priority,
                    region,
                    assignee,
                    action,
                    actionParameter,
                    verificationMethod,
                    frequency,
                    weeklyCondition,
                    endNever,
                    endDate,
                    endCount,
                    site,
                    events,
                } = wORoutine

                setWarning(false)

                //work order
                setRoutineId(routineId)
                setCategory(category.id)
                setProblem(problem.id)
                setProblemParams(problemParameter)
                setDataUsed(dataUsed)
                setPriority(priority)
                setRegion(region.id)
                setAssignee(assignee ? assignee.id : null)
                setAction(action.id)
                setActionParams(actionParameter)
                setVerificationMethod(verificationMethod)
                setSite(site.id)
                setRegion(region.id)
                setEvents(events)

                //routine
                setWeekdays(weeklyCondition)
                setFrequency(frequency)
                if (endNever) {
                    setEndsFormat("never")
                } else if (!!endCount) {
                    setEndsFormat("count")
                    setCount(endCount)
                } else if (!!endDate) {
                    setEndsFormat("date")
                    setDate(new Date(endDate))
                }
            }
        }, [wORoutine, searchParams]
    )

    const handleSubmit = () => {
        const required = [category, problem, priority, region, assignee, action, verificationMethod]
        if (required.includes(null) || !Object.values(weekdays).includes(true)) {
            setWarning(true)
        } else {
            if (category) {
                setWarning(false)
                const routineData: { [key: string]: any } = {
                    frequency,
                    weeklyCondition: Object.entries(weekdays).filter(([key, val]) => (val === true))
                        .map(([key]) => (Weekdays[key.toUpperCase() as keyof typeof Weekdays])),
                    startDate: new Date(),
                    endNever: endsFormat === "never",
                    priority,
                    regionId: region,
                    assigneeId: assignee,
                    categoryId: category,
                    actionId: action,
                    actionParameter: actionParams,
                    problemId: problem,
                    problemParameter: problemParams,
                    verificationMethod,
                    dataUsed,
                    events: notes.length > 0 ? [...events, notes] : [...events],
                }
                if (endsFormat === "count") {
                    routineData.endCount = count
                } else if (endsFormat === "date") {
                    routineData.endDate = date
                }

                if (wORoutine !== null) {
                    submitUpdate(routineData as MDUpdateWorkOrderRoutineRequest)
                } else {
                    submitCreate(routineData as MDCreateWorkOrderRoutineRequest)
                }
            }
        }
    }

    const handleReset = useCallback(() => {
        setWarning(false)
        setRoutineId(null)

        setCategory(null)
        setProblem(null)
        setProblemParams("")
        setDataUsed(null)
        setPriority(null)
        setSite(null)
        setRegion(null)
        setAssignee(null)
        setAction(null)
        setActionParams("")
        setVerificationMethod(null)
        setNotes("")

        setWeekdays({...initialWeekdays})
        setFrequency(1)
        setEndsFormat("never")
        setDate(searchParams.has("date") ? localDateFromSnapshot(searchParams.get("date") as string) : new Date())
        setCount(1)
    }, [searchParams])

    useEffect(() => {
        handleReset()
    }, [handleReset, resetForm])

    const renderWarning = (placeholder: string) => {
        return (
            <FormErrorMessage>{placeholder} is required for a new work order.</FormErrorMessage>
        )
    }

    const renderEndsInput = (format: string) => {
        switch (format) {
            case "date":
                return (
                    <DatePicker
                        selected={date}
                        onChange={(date: Date) => {
                            setDate(date);
                        }} //only when value has changed
                        dateFormat="P"
                    />
                )
            case "count":
                return (
                    <Flex gap={"5px"}>
                        <FloatingSelect selectLabel={null} onChange={(val) => setCount(parseInt(val))}
                                        state={count.toString()}
                                        options={[...new Array(100)].map((b, i) => ({
                                            label: (i + 1).toString(),
                                            value: (i + 1)
                                        }))}/>
                        <Text style={{alignSelf: "center", marginRight: "2rem", flexGrow: 3, width: "100%"}}>&nbsp;Work
                            Orders</Text>
                    </Flex>
                )
            case "never":
                return
            default:
                return
        }
    }

    const workOrderForm = () => {
        return (
            <ChakraProvider theme={theme}>
                <Flex direction={"column"} gap={"22px"} width={"100%"}>
                    <Heading as={"h2"} size={"md"} style={{marginBottom: "8px"}}>Work Order</Heading>
                    <FormControl variant="floating" isRequired isInvalid={warning && !site}>
                        <FloatingSelect selectLabel={"Site"} onChange={setSite} options={options.site}
                                        state={site}/>
                        <FormHelperText>Selecting site will generate region options.</FormHelperText>
                        {warning && !region ? renderWarning(REGION.label) : null}
                    </FormControl>
                    <FormControl variant="floating" isRequired isInvalid={warning && !site}>
                        <FloatingSelect selectLabel={"Regions"} onChange={setRegion}
                                        options={!!site ? options.region[site] : []}
                                        state={region}/>
                        {warning && !region ? renderWarning(REGION.label) : null}
                    </FormControl>
                    <FormControl variant="floating" isRequired isInvalid={warning && !category}>
                        <FloatingSelect selectLabel={"Category"} onChange={setCategory} state={category}
                                        options={options.category}/>
                        <FormHelperText>Selecting category will generate action and problem
                            options.</FormHelperText>
                        {warning && !category ? renderWarning(CATEGORY.label) : null}
                    </FormControl>
                    <FormControl variant="floating" isRequired isInvalid={warning && !problem}>
                        <FloatingSelect selectLabel={"Problem"} onChange={setProblem}
                                        options={!!category ? options.problem[category] : []}
                                        state={problem}/>
                        {warning && !problem ? renderWarning(PROBLEM.label) : null}
                    </FormControl>
                    {/*<FormControl variant="floating">*/}
                    {/*    <Input placeholder={"Problem Parameters"}*/}
                    {/*           onChange={(e) => setProblemParams(e.target.value)} value={problemParams}/>*/}
                    {/*    <FormLabel>{"Problem Parameters"}</FormLabel>*/}
                    {/*</FormControl>*/}
                    <FormControl variant="floating" isRequired isInvalid={warning && !action}>
                        <FloatingSelect selectLabel={"Actions"} onChange={setAction}
                                        options={!!category ? options.action[category] : []}
                                        state={action}/>
                        {warning && !action ? renderWarning(ACTION.label) : null}
                    </FormControl>
                    <FormControl variant="floating">
                        <Input placeholder={"Action Parameters"}
                               onChange={(e) => setActionParams(e.target.value)} value={actionParams}/>
                        <FormLabel>{"Action Parameters"}</FormLabel>
                    </FormControl>
                    <FormControl variant="floating" isRequired isInvalid={warning && !dataUsed}>
                        <FloatingSelect selectLabel={DATA_USED.label} onChange={setDataUsed}
                                        options={options.dataUsed} state={dataUsed}/>
                        {warning && !dataUsed ? renderWarning(DATA_USED.label) : null}
                    </FormControl>
                    <FormControl variant="floating" isRequired isInvalid={warning && !priority}>
                        <FloatingSelect selectLabel={PRIORITY.label} onChange={setPriority}
                                        options={PRIORITY.options} state={priority}/>
                        {warning && !priority ? renderWarning(PRIORITY.label) : null}
                    </FormControl>

                    <FormControl variant="floating" isRequired isInvalid={warning && !assignee}>
                        <FloatingSelect selectLabel={"Workers"} onChange={setAssignee}
                                        options={options.worker} state={assignee}/>
                        {warning && !assignee ? renderWarning(ASSIGNEE.label) : null}
                    </FormControl>
                    <FormControl variant="floating" isRequired isInvalid={warning && !verificationMethod}>
                        <FloatingSelect selectLabel={VERIFICATION_METHOD.label} onChange={setVerificationMethod}
                                        options={options.verificationMethod} state={verificationMethod}/>
                        {warning && !verificationMethod ? renderWarning(VERIFICATION_METHOD.label) : null}
                    </FormControl>
                </Flex>
            </ChakraProvider>
        )
    }

    const routineForm = () => {
        return (
            <ChakraProvider theme={theme}>
                <Flex direction={"column"} gap={"22px"} width={"100%"}>
                    <Heading as={"h2"} size={"md"} style={{marginBottom: "8px"}}>Routine</Heading>

                    <Flex direction={"column"} gap={"48px"} width={"100%"}>
                        <FormControl isRequired isInvalid={warning && !Object.values(weekdays).includes(true)}>
                            <Box>
                                <CheckboxGroup>
                                    <HStack>
                                        {Object.keys(weekdays).map((key) => (
                                            <Checkbox isChecked={weekdays[key]} colorScheme='green' key={key}
                                                      onChange={() => setWeekdays({
                                                          ...weekdays,
                                                          [key]: !weekdays[key]
                                                      })}>
                                                {key[0].toUpperCase() + key.slice(1)}
                                            </Checkbox>
                                        ))}
                                    </HStack>
                                </CheckboxGroup>
                            </Box>
                            <FormLabel style={{
                                top: 0,
                                left: 0,
                                zIndex: 2,
                                position: "absolute",
                                backgroundColor: "white",
                                pointerEvents: "none",
                                transformOrigin: "left top",
                                transform: "scale(0.85) translateY(-24px)",
                            }}>Weekdays</FormLabel>
                            {warning && !action ?
                                <FormErrorMessage>At least one weekday needs to be checked</FormErrorMessage> : null}
                        </FormControl>
                        <FormControl variant={"floating"}>
                            <FormLabel>Frequency</FormLabel>
                            <Flex gap={"5px"}>
                                <Text
                                    style={{alignSelf: "center", marginRight: "5px", width: "fit-content"}}>Every</Text>
                                <FloatingSelect selectLabel={null} onChange={(val) => setFrequency(parseInt(val))}
                                                state={frequency.toString()}
                                                options={[...new Array(53)].map((b, i) => ({
                                                    label: (i + 1).toString(),
                                                    value: (i + 1)
                                                }))}/>
                                <Text style={{
                                    alignSelf: "center",
                                    marginRight: "2rem",
                                    flexGrow: 3,
                                    width: "100%"
                                }}>&nbsp;Weeks</Text>
                            </Flex>
                        </FormControl>
                        <FormControl variant={"floating"} style={{height: "70px"}}>
                            <FormLabel>Ends</FormLabel>
                            <Flex gap={"0"} style={{paddingBottom: "2px"}}>
                                <Button className={`nivo-button ${endsFormat === "date" ? "highlight" : null}`}
                                        onClick={() => setEndsFormat("date")}>Date</Button>
                                <Button className={`nivo-button ${endsFormat === "count" ? "highlight" : null}`}
                                        onClick={() => setEndsFormat("count")}>Count</Button>
                                <Button className={`nivo-button ${endsFormat === "never" ? "highlight" : null}`}
                                        onClick={() => setEndsFormat("never")}>Never</Button>
                            </Flex>
                            {endsFormat === "date" ? renderEndsInput("date") : endsFormat === "count" ? renderEndsInput(
                                "count") : renderEndsInput("never")}
                        </FormControl>
                        <FormControl variant="floating">
                            <Textarea onChange={(e) => setNotes(e.target.value)} value={notes}/>
                            <FormLabel>{NOTES.label}</FormLabel>
                        </FormControl>
                    </Flex>
                </Flex>
            </ChakraProvider>
        )
    }

    useEffect(() => {
        if (!isOpen) {
            handleReset()
        }
    }, [isOpen, handleReset])


    return (
        <Drawer
            isOpen={isOpen}
            placement='left'
            onClose={onClose}
            size={"xl"}
        >
            <DrawerOverlay/>
            <DrawerContent>
                <DrawerCloseButton/>
                <DrawerHeader>
                    <Heading as={"h1"} fontSize={"1.6rem"} color={"blackAlpha.700"}>
                        {routineId === null ? "New Work Order Routine" : "Edit Work Order Routine"}
                    </Heading>
                    {routineId !== null ? <Text fontWeight={"bold"} color={"blackAlpha.800"}>Work Order Routine
                        Id: {routineId}</Text> : null}
                </DrawerHeader>

                <DrawerBody>
                    <Flex direction={"row"} gap={"2rem"}>
                        {workOrderForm()}
                        {routineForm()}
                    </Flex>
                </DrawerBody>
                <DrawerFooter>
                    <Button variant='outline' mr={3} onClick={handleReset}>
                        Reset
                    </Button>
                    <Button colorScheme='blue' onClick={handleSubmit}>Save</Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export const theme = extendTheme({
    components: {
        Form: {
            variants: {
                floating: {
                    container: {
                        label: {
                            top: 0,
                            left: 0,
                            zIndex: 2,
                            position: "absolute",
                            backgroundColor: "white",
                            pointerEvents: "none",
                            transformOrigin: "left top",
                            transform: "scale(0.85) translateY(-24px)",
                        }
                    }
                }
            }
        }
    }
});

const initialWeekdays = {sun: false, mon: false, tue: false, wed: false, thur: false, fri: false, sat: false}
