import React, {useState} from "react";
import {
    Button,
    Flex,
    Grid,
    GridItem,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    RadioGroup,
    Select,
    SimpleGrid,
    Text,
    Textarea,
    useRadioGroup,
    VStack
} from "@chakra-ui/react";
import {GreenhouseGrid} from "../../components/overview/greenhouse/GreenhouseGrid";
import {HeatmapLayout} from "../../types/legacy/HeatmapLayout";
import {RadioCard} from "../RadioCard";

type EditOrderModalProps = {
    isOpen: boolean,
    onClose: () => void,
    onSave: (newOrder: any) => void
}

export const EditOrderModal = ({isOpen, onClose, onSave}: EditOrderModalProps) => {
    const [order, setOrder] = useState({
        description: "",
        priority: "",
        dueDate: new Date(),
        assignee: "",
        createdBy: "",
        verificationMethod: "",
        orderTypeId: 0,
    });

    const orderOptions = [
        [1, "Beneficial/Pesticide", "Release beneficials or spray pesticides"],
        [2, "Optimize Irregation Freq", "optimize the irrigation frequency (increase or decrease based on tensiometer)"],
        [3, "Sweep", "Sweep the floor"]
    ]

    const priorityOptions = ['P1', 'P2', 'P3', 'P4']

    const assigneeOptions = [
        [1, "SCV"],
        [2, "Probe"],
        [3, "Drone"],
    ]

    const verificationMethodOptions = [
        [1, "photo"],
        [2, "sensor"]
    ]

    const {getRadioProps} = useRadioGroup({
        name: 'framework',
        defaultValue: 'react',
        onChange: console.log,
    })

    const heatmapLayout: HeatmapLayout = new HeatmapLayout("placeholder", "placeholder", [])

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"6xl"} blockScrollOnMount={true}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Create a new order</ModalHeader>
                <ModalCloseButton/>

                <ModalBody justifyContent={"top"}>
                    <HStack w={"full"}>
                        <GreenhouseGrid width={"800"} height={"200"} heatmapLayout={heatmapLayout}></GreenhouseGrid>
                    </HStack>
                    <Grid
                        w={"full"}
                        templateColumns='repeat(6, 1fr)'
                    >
                        <GridItem colSpan={2}>
                            <Text w='full' align="left"
                                  fontWeight={400}
                                  fontFamily={'Open Sans, sans-serif'}>
                                {"Order type"}</Text>
                            <Select
                                placeholder={"Select an order type"}
                                value={order.orderTypeId}
                                onChange={e => setOrder({...order, orderTypeId: Number(e.target.value)})}
                            >
                                {
                                    orderOptions.map(option => {
                                        return (
                                            <option key={"ordertype-" + option[0]}
                                                    value={option[0]}>{option[1]}</option>
                                        )
                                    })
                                }

                            </Select>
                        </GridItem>
                        <GridItem colSpan={1}>
                            <VStack>
                                <Text w='full' align="left"
                                      fontWeight={400}
                                      fontFamily={'Open Sans, sans-serif'}>
                                    {"Due date"}</Text>
                                <Flex minHeight={50}>Date Picker Placeholder</Flex>
                                <Text w='full' align="left"
                                      fontWeight={400}
                                      fontFamily={'Open Sans, sans-serif'}>
                                    {"Priority"}</Text>

                                <RadioGroup
                                    value={order.priority}
                                    onChange={e => {
                                        setOrder({...order, priority: e})
                                    }}>
                                    <SimpleGrid width={"full"} columns={2}>
                                        {priorityOptions.map((value) => {
                                            const radio = getRadioProps({value})
                                            return (
                                                <RadioCard key={value} {...radio}>
                                                    {value}
                                                </RadioCard>
                                            )
                                        })}
                                    </SimpleGrid>
                                </RadioGroup>

                            </VStack>
                        </GridItem>
                        <GridItem colSpan={1}>
                            <Text w='full' align="left"
                                  fontWeight={400}
                                  fontFamily={'Open Sans, sans-serif'}>
                                {"Assignee"}</Text>
                            <Select
                                placeholder={"Select an assignee"}
                                value={order.assignee}
                                onChange={e => setOrder({...order, assignee: e.target.value})}
                            >
                                {
                                    assigneeOptions.map(option => {
                                        return (
                                            <option key={"assignee-" + option[0]} value={option[0]}>{option[1]}</option>
                                        )
                                    })
                                }
                            </Select>
                            <Select
                                placeholder={"Select a verification method"}
                                value={order.verificationMethod}
                                onChange={e => setOrder({...order, verificationMethod: e.target.value})}
                            >
                                {
                                    verificationMethodOptions.map(option => {
                                        return (
                                            <option key={"verificationMethod-" + option[0]}
                                                    value={option[0]}>{option[1]}</option>
                                        )
                                    })
                                }
                            </Select>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <Text w='full' align="left"
                                  fontWeight={400}
                                  fontFamily={'Open Sans, sans-serif'}>
                                {"Description"}</Text>
                            <Textarea
                                value={order.description}
                                onChange={e => setOrder({...order, description: e.target.value})}
                                size='lg'
                                placeholder='Here is a sample placeholder'/>
                        </GridItem>
                    </Grid>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='gray' mr={3} onClick={() => onSave(order)}>
                        {"Create ->"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>)
}


export default EditOrderModal;