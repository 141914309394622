import {extendTheme, StyleProps} from "@chakra-ui/react"

import {Global} from "@emotion/react"

export const zordiTheme = extendTheme({
    colors: {
        zordiGreen: "#70AD47",
        zordiLightGreen: "#C5E0B4",
        zordiLightGray: "#CDD0D9",
        zordiDarkGray: "#262626",
    },
    components: {
        colors: {
            zordiGreen: "#70AD47",
            zordiLightGreen: "#C5E0B4",
            zordiLightGray: "#CDD0D9",
            zordiDarkGray: "#262626",
            green: {
                30: "#70AD47"
            }
        },
        fonts: {
            button: "Poppins",
            "3xl": "Poppins",
            title: "Poppins",
            table: "Poppins",
            td: "Poppins",
            tr: "Poppins"
        },
        Button: {
            variants: {
                'zordi-basic': {
                    height: "35px",
                    width: "109px",
                    borderRadius: "1000px",
                    bg: '#262626',
                    color: '#FFFFFF',
                    _hover: {
                        bg: '#262626',
                        _disabled: {
                            bg: '#262626',
                            opacity: 0.4
                        }
                    },
                },
                'time-quick-pick': {
                    height: "36px",
                    width: "100%",
                    borderRadius: "5px",
                    bg: '#EDEDED',
                    color: '#000000',
                },
            },
        },
    },
})

export const titleStyle: StyleProps = {
    minW: "200px",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "27px",
    lineHeight: "41px",
    color: "#000000"
}


export const Fonts = () => (
    <Global
        styles={`
      /* Copied from https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap */
      @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format('woff2');
        unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      `}
    />
)
