import {Box, Flex, Heading, Image, Stack, Text, useColorModeValue,} from "@chakra-ui/react";
import {FC} from "react";

interface Props {
    message: {
        header: string;
        body: string;
        imgSrc?: string;
    };
}

const Unavailable: FC<Props> = ({message}) => {
    const { header, body, imgSrc } = message;

    return (
        <Flex
            align={"center"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <Stack spacing={8} mx={"auto"} maxW={"xl"} py={12} px={6}>
                <Stack align={"center"}>
                    <Heading fontSize={"4xl"}>{header}</Heading>
                    <Text fontSize={"lg"} color={"gray.600"}>
                        {body}
                    </Text>
                </Stack>
                    <Box
                        rounded={"xl"}
                        bg={useColorModeValue("white", "gray.700")}
                        boxShadow={"lg"}
                        p={8}
                    >
                    <Image src={imgSrc}></Image>
                </Box>
            </Stack>            
        </Flex>
    );
};

export default Unavailable;
