import React, {Dispatch, SetStateAction} from "react";
import {FormLabel, Select} from "@chakra-ui/react";

type SelectProps = {
    selectLabel: string | null,
    onChange: Dispatch<SetStateAction<any>>,
    options: { label: string, value: any }[] | [],
    state: string | null,
    style?: object,
}

export const FloatingSelect = ({selectLabel, onChange, options, state, style}: SelectProps) => {
    return (
        <>
            <Select
                value={state == null ? "default_option" : state}
                style={{...style}} variant={"flushed"} onChange={(e) => onChange(e.target.value)}>
                <option disabled value={"default_option"}></option>
                {options !== undefined && options.length > 0 ? options.map(({
                                                                                label,
                                                                                value
                                                                            }: { [key: string]: string },
                                                                            i: number) => {
                    return <option key={`${label}-${i}`} value={value}>{label}</option>
                }) : null}
            </Select>
            {selectLabel !== null ? <FormLabel>{selectLabel}</FormLabel> : null}
        </>
    )
}
