import {MDReadWorkOrderResponse, MDReadWorkOrderRoutineResponse} from "@zordi/zordi_object_schema";
import {dateToSnapshot} from "./date";
import {
    Region,
    Routine,
    Site,
    Worker,
    WorkOrderAction,
    WorkOrderCategory,
    WorkOrderProblem,
} from "@zordi/zordi_object_schema/src/to/References";

export const convertWorkOrder = (workOrder: any): MDReadWorkOrderResponse => {
    const {
        action,
        actionParameter,
        assignee,
        category,
        startDate,
        problem,
        problemParameter,
        region,
        events,
        status,
        priority,
        completeRows,
        incompleteRows,
    } = workOrder
    return ({
        workOrderId: workOrder.id,
        date: dateToSnapshot(new Date(startDate)),
        status,
        category: {
            label: category.category,
            id: category.id,
        },
        problem: {
            label: problem.problem,
            id: problem.id,
        },
        problemParameter,
        action: {
            label: action.action,
            id: action.id,
        },
        actionParameter,
        priority,
        region: {
            label: region.name,
            id: region.id
        },
        site: {
            label: region.site.code,
            id: region.siteId,
        },
        notes: events,
        assignee: assignee[0],
        completeRows: completeRows ? completeRows : [],
        incompleteRows: incompleteRows ? incompleteRows : [],
    })
}

export const convertWorkOrderRoutine = (routine: Routine): MDReadWorkOrderRoutineResponse => {
    const {
        id,
        status,
        frequency,
        weeklyCondition,
        lastCreated,
        workOrders,
        startDate,
        endNever,
        endDate,
        endCount,
        events,
        priority,
        region,
        regionId,
        assignee,
        category,
        categoryId,
        action,
        actionId,
        actionParameter,
        problem,
        problemId,
        problemParameter,
        verificationMethod,
        dataUsed,
    } = routine
    const weekdays = {
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thur: false,
        fri: false,
        sat: false,
    }
    weeklyCondition.map(day => weekdays[day as keyof typeof weekdays] = true)
    return {
        routineId: id,
        status,
        frequency,
        weeklyCondition: weekdays,
        lastCreated: lastCreated ? dateToSnapshot(new Date(lastCreated)) : "",
        workOrders: workOrders && workOrders.length > 0 ? workOrders.map(workorder => convertWorkOrder(workorder)) : [],
        startDate: dateToSnapshot(new Date(startDate)),
        endNever,
        endDate: endDate ? dateToSnapshot(new Date(endDate)) : undefined,
        endCount,
        events,
        priority,
        region: {
            label: region.name,
            id: regionId,
        },
        site: {
            label: region.site.code,
            id: region.siteId,
        },
        assignee,
        category: {
            label: category.category,
            id: categoryId,
        },
        action: {
            label: action.action,
            id: actionId,
        },
        actionParameter: actionParameter ? actionParameter : "",
        problem: {
            label: problem.problem,
            id: problemId,
        },
        problemParameter: problemParameter ? problemParameter : "",
        verificationMethod,
        dataUsed,
    }
}

export const convertEnum = (data: string = "") => {
    return data.split("_").map(word => (word.charAt(0).toUpperCase() + word.slice(1))).join(" ")
}

export const convertEnumToOptions = (data: object) => {
    const options: { label: string, value: string }[] = []
    Object.values(data).forEach((val: string) => {
        options.push({label: convertEnum(val), value: val})
    })
    return options
}

export const convertWorkOrderOptions = (options: { [key: string]: any }): {
    category: { label: string, value: string }[]
    problem: { [key: string]: { label: string, value: string } [] }
    action: { [key: string]: { label: string, value: string } [] }
    site: { label: string, value: string }[]
    region: { [key: string]: { label: string, value: string }[] }
    worker: { label: string, value: string }[]
} => {
    const {category, action, problem, region, site, worker} = options
    const actionOptions: { [key: string]: { label: string, value: string }[] } = {}
    const problemOptions: { [key: string]: { label: string, value: string }[] } = {}
    const regionOptions: { [key: string]: { label: string, value: string }[] } = {}
    action.forEach((item: WorkOrderAction) => {
        if (!!actionOptions[item.categoryId]) {
            actionOptions[item.categoryId].push({label: item.action, value: item.id})
        } else {
            actionOptions[item.categoryId] = [{label: item.action, value: item.id}]
        }
    })
    problem.forEach((item: WorkOrderProblem) => {
        if (!!problemOptions[item.categoryId]) {
            problemOptions[item.categoryId].push({label: item.problem, value: item.id})
        } else {
            problemOptions[item.categoryId] = [{label: item.problem, value: item.id}]
        }
    })
    region.forEach((item: Region) => {
        if (!!regionOptions[item.siteId]) {
            regionOptions[item.siteId].push({label: item.name, value: item.id})
        } else {
            regionOptions[item.siteId] = [{label: item.name, value: item.id}]
        }
    })

    return ({
        category: category.map((category: WorkOrderCategory) => ({label: category.category, value: category.id})),
        action: actionOptions,
        problem: problemOptions,
        site: site.map((site: Site) => ({label: site.code, value: site.id})),
        region: regionOptions,
        worker: worker.map((worker: Worker) => ({label: worker.name, value: worker.id}))
    })
}
