export enum Experiments {
  system_vs_grower = "system_vs_grower",
  remote_vs_inperson = "remote_vs_inperson",
}

const pages = {
  SITE_OVERVIEW: {
    pageType: "site_overview", // 'site_overview' | 'report',
    pageTitle: "Site Overview",
    menuLabel: "Site Overview",
  },
  SYSTEM_VS_GROWER: {
    pageType: "report",
    pageTitle: "System vs Grower [Bay5] Report",
    menuLabel: "System vs Grower",
    page: Experiments.system_vs_grower,
  },
  REMOTE_VS_INPERSON: {
    pageType: "report",
    pageTitle: "Remote vs In-Person [Bay2] Report",
    menuLabel: "Remote vs In-Person",
    page: Experiments.remote_vs_inperson,
  },
  LABOR_REPORT: {
    pageType: "report",
    pageTitle: "Labor Report",
    menuLabel: "Labor Report",
    page: "labor_report",
  },
  SAMPLE: {
    pageType: "overview",
    pageTitle: "Overview",
    menuLabel: "Overview",
  },

};


export default pages;