import React, {createContext, useContext} from "react";
import {Order} from "../types/Order";
import {
    fetchAllWorkOrders,
    fetchOrders,
    fetchWorkOrdersByDateSource,
    postWorkOrder,
    WorkOrdersBySource
} from "../api/order";
import {OrderQuery} from "../types/OrderQuery";
import {WorkOrder, WorkOrderSource} from "@zordi/zordi_object_schema";

export interface OrderProviderState {
    getOrders: (orderQuery: OrderQuery) => Promise<Order[]>;
    getWorkOrders: (workOrderQuery: GetWorkOrdersQuery) => Promise<WorkOrder[]>;
    getAllWorkOrders: (workOrderQuery: GetWorkOrdersQuery) => Promise<WorkOrdersBySource>;
    updateWorkOrder: (workOrderId: string, targetDate: string, fieldsToUpdate: { [key: string]: any }) => Promise<void>;
}

const OrderContext = createContext<OrderProviderState | null>(null);

type OrderProviderProps = {
    children?: React.ReactNode;
}

const OrderProvider: React.FC<OrderProviderProps> = ({children}) => {
    // TODO: Deprecate eventually
    const getOrders = (orderQuery: OrderQuery): Promise<Order[]> => {
        return fetchOrders(orderQuery);
    }

    const getWorkOrders = (workOrderQuery: GetWorkOrdersQuery): Promise<WorkOrder[]> => {
        return fetchWorkOrdersByDateSource(workOrderQuery, WorkOrderSource.System);
    }

    const getAllWorkOrders = (workOrderQuery: GetWorkOrdersQuery): Promise<WorkOrdersBySource> => {
        return fetchAllWorkOrders(workOrderQuery);
    }

    const updateWorkOrder = (workOrderId: string, targetDate: string,
                             fieldsToUpdate: { [key: string]: any }): Promise<void> => {
        return postWorkOrder(workOrderId, targetDate, fieldsToUpdate);
    }

    return (
        <OrderContext.Provider
            value={{getOrders, getWorkOrders, getAllWorkOrders, updateWorkOrder}}>{children}</OrderContext.Provider>
    );
};

const useOrderContext = () => {
    const context = useContext(OrderContext);

    if (!context) {
        throw new Error("Unable to use useOrderContext without OrderProvider");
    }

    return context;
};

export type GetWorkOrdersQuery = {
    siteId: string
    startTime?: Date
    endTime?: Date
}

export {useOrderContext, OrderProvider};
