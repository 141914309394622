const metricTolabelMap: { [key: string]: any } = {
    "Celsius_TemperatureData": "°C",
    "PAR_PAR_DLI": "DLI",
    "Humidity_Percentage": "Humidity",
    "num_baby_fruit": "Young Fruits",
    "num_mature_flowers": "Mature Flowers",
    "num_white_fruit": "White Fruits",
    "num_pink_fruit": "Pink Fruits",
    "num_red_fruit": "Red Fruits",
    "num_runners": "Runners",
    "num_aphids_adult_side_a": "aphids",
    "num_thrips_adult_side_a": "thrips",
    "num_fungus_gnats_adult_side_a": "fungus gnats",
    "num_aphids_adult_side_a_diff": "Aphids",
    "num_thrips_adult_side_a_diff": "Thrips",
    "num_fungus_gnats_adult_side_a_diff": "Fungus Gnats",
}

export const getLabelOrDefault = (key: string, defaultValue: any) => {
    return metricTolabelMap[key] || defaultValue
}

export const plantIdToGrowerId = (plantId: string) => {
    const arr = plantId.split("-");
    const unit: string = parseInt(arr[2]).toString().padStart(3, '0');
    const direction: string = parseInt(arr[1]) % 2 === 0 ? "L" : "R";
    const row: number = Math.ceil(parseInt(arr[1]) / 2)
    const bay: string = Math.ceil(row / 4).toString().padStart(2, '0');
    const rowS = row.toString().padStart(3, "0");

    return `${arr[0]}-${bay}-${rowS}${direction}-${unit}`
}